import { useContext, useEffect, useState } from "react"
import { cartContext, context, expireCart } from "../../App"
import { collection, doc, onSnapshot, updateDoc, query, where, getDocs, getDoc } from "firebase/firestore"
import { db } from "../../firebase"
import { expireCartTimeConfig } from "../../config"
import Lottie from "lottie-react"
import correct from '../../Screens/correct.json'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { useNavigate } from "react-router-dom"

const ListLottery = ({ status, setStatus, find, selection, getLottery }) => {

    let { cart, setCart } = useContext(cartContext)
    let { playtwoAlert } = useContext(context)
    let { expire, setExpire, ResetCartTime, clearTimeCart } = useContext(expireCart)
    let navigate = useNavigate()

    let [AllLottery, setAllLottery] = useState([])
    let [sold, setSold] = useState([])
    let [user, setUser] = useState([])
    let [lotteryCheck, setLotteryCheck] = useState(false)
    let [announce, setAnnounce] = useState('')

    let tu = localStorage.getItem('tu') == 'true'

    let left = []
    let taken = []

    var search = []

    // console.log("Cart : ", cart)

    if (AllLottery && AllLottery.length > 0) {
        let data = AllLottery.filter((item) => {
            return item ? selection == 'one' ? item.quantity == 1 ? item.number.includes(find) : null : selection == 'duo' ? item.quantity > 1 ? item.number.includes(find) : null : item.number.includes(find) : null
            // return 1 == 1 ? item.number.includes('') : null
        })

        search = data
    }

    // console.log(search)



    const lockLottery = (lotteryId) => {

        let endTime = expireCartTimeConfig
        let timestampEnd = new Date().getTime() + (endTime * 60 * 1000) // Plus with millisec
        let userData = localStorage.getItem('userDetail')
        let userJson = JSON.parse(userData)

        updateDoc(doc(db, 'lottery', lotteryId), {
            lock: true,
        })

        updateDoc(doc(db, 'users', userJson.docId), {
            lockEnd: timestampEnd
        })
    }

    const RemoveCart = (lottryId) => {



        let updatedCart = []

        updatedCart = cart.filter((item) => {
            return item.lotteryId != lottryId
        })

        if (cart.length == 1) {
            clearTimeCart()
        }

        setCart(updatedCart)
        localStorage.setItem('cart', JSON.stringify(updatedCart))
    }

    const AddToCart = (item, quantity) => {

        let minutes = expireCartTimeConfig
        let expiresCart = new Date().getTime() + (minutes * 60 * 1000)

        ResetCartTime(expiresCart)
        localStorage.setItem('expireCart', expiresCart)

        let findTheSame = cart.filter((lottery) => {
            return lottery.lotteryId == item.lotteryId
        })

        if (findTheSame.length > 0) {
            let updatedObject = []
            updatedObject = cart.map((item) => {
                return {
                    ...item,
                    quantity: item.lotteryId == findTheSame[0].lotteryId ? item.quantity + 1 : item.quantity
                }
            })

            localStorage.setItem('cart', JSON.stringify(updatedObject))
            setCart(updatedObject)
        } else {
            let payload = {
                ...item,
                quantity: quantity
            }

            let updatedObject = [...cart, payload]

            localStorage.setItem('cart', JSON.stringify(updatedObject))
            // if what lock Lottery open this tag
            // lockLottery(item.lotteryId)
            setCart(updatedObject)
        }

        // if(!findTheSame && findTheSame.length <= 0){
        //     setCart([...cart, item])
        // }

        // console.log("Find : ", findTheSame)


        // cart.map((item_cart) => {
        //     console.log(item_cart.number)

        //     if (item_cart.number == item.number) {
        //         console.log("OLD")
        //         // Old Product
        //         let updatedCart = {
        //             ...item_cart,
        //             quantity: item_cart.number == item.number ? item_cart.quantity + 1 : item_cart.quantity
        //         }

        //         setCart([...item_cart, updatedCart])
        //     }else{

        //         // New Product
        //         let payload = {
        //             number: item.number,
        //             img: item.img,
        //             price: item.price,
        //             quantity: 1,
        //         }

        //         setCart([...cart, payload])
        //     }
        // })
    }

    // console.log(SearchLottery)


    if (AllLottery && AllLottery.length > 0) {

        let left_arr = []

        left_arr = AllLottery.filter((item) => {
            return item.lock == false
        })

        left = left_arr.reduce((total, current) => {
            return total = total + current.quantity
        }, 0)

    }

    if (sold && sold.length > 0) {

        let taken_arr = []

        taken_arr = sold.filter((item) => {
            return item.lock == true
        })

        taken = taken_arr.reduce((total, current) => {
            return total = total + current.quantity
        }, 0)
    }


    const init = async () => {

        let userJSON = JSON.parse(localStorage.getItem('userDetail'))

        await onSnapshot(doc(db, 'admin_panel', 'tbW2GFM9UV0UKUCA6StW'), (snapshot) => {

            setLotteryCheck(snapshot.data().turnon_lottery)
            setAnnounce(snapshot.data().announce)
        })


        if (userJSON) {
            await getDoc(doc(db, 'users', userJSON.docId)).then((res) => {
                let payload = {
                    ...res.data(),
                    userId: res.id
                }

                setUser(payload)
            })
        }

        await onSnapshot(query(collection(db, 'lottery'), where('lock', '==', false)), (snapshot) => {
            let data = snapshot.docs.map((item) => {
                return {
                    ...item.data(),
                    lotteryId: item.id
                }
            })

            getLottery(data)
            setAllLottery(data)

        })

        // await onSnapshot(query(collection(db, 'lottery'), where('lock', '==', true)), (snapshot) => {
        //     let data = snapshot.docs.map((item) => {
        //         return {
        //             ...item.data(),
        //             lotteryId: item.id
        //         }
        //     })

        //     // Add property to lottery when payment successfully then sold is increase

        //     setSold(data)
        // })
    }

    let months = ["มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน", "พฤษภาคม", "มิถุนายน", "กรกฎาคม", "สิงหาคม", "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม"]


    useEffect(() => {
        init()
    }, [])

    // console.log(user)


    return (
        <div>


            {/* <table className="w-[100%]">
                <thead className="border-b-[1px] border-black">
                    <th className="pb-[20px] font-[medium]">ลอตเตอรี่</th>
                    <th></th>
                </thead>
                <tbody>
                    {AllLottery && AllLottery.length > 0 ? AllLottery.map((item) => {
                        return (
                            <tr className="flex gap-[10px] justify-center items-center border-b-[1px] border-black pt-[20px] pb-[20px]">
                                <td className="w-[70%]">
                                    <img src={`./imgs/lottery_real.png`}></img>
                                </td>
                                <td className="w-[30%]">
                                    <p>{item.number}</p>
                                    <div className="w-[100%] h-[100%] flex flex-col justify-center items-center gap-[10px]">
                                        {!item.lock ?                                         <button onClick={() => {
                                            playtwoAlert.fire({ open: true, confirmText: 'ยืนยัน', cancelText: 'ยกเลิก', dialog: true, title: 'นำเข้าตะกร้าสินค้า?' }, () => {
                                                setStatus(true)
                                                AddToCart(item)
                                                setTimeout(() => {
                                                    setStatus(false)
                                                }, 1000)
                                            })
                                        }} className="bg-transparent text-white font-[apple-medium] w-[80px] rounded-[8px]"><img className="w-[100%] h-[100%]" src="./svgs/addbasket.svg"></img></button> : null}
                                    </div>
                                </td>
                            </tr>
                        )
                    }) : null}
                </tbody>
            </table> */}

            <div className="sm:w-[100%] sm:flex sm:justify-center w-[100%]">
                <div className="p-[10px] bg-[#55BCE5] mt-[30px] rounded-[8px] w-[260px] h-[60px] flex gap-[10px] items-center justify-center shadow-[0px_2px_4px_0px_rgba(0,0,0,0.15)]">
                    {announce ? <p className="font-medium text-[18px] text-white">ลอตเตอรี่ งวด {Number(announce.split('-')[0])} {months[Number(announce.split('-')[1] - 1)]} {announce.split('-')[2]}</p> : null}
                    <div className="w-[30px] h-[30px] bg-white rounded-full flex justify-center items-center">
                        <img className="w-[25px]" src="./svgs/lottery.svg"></img>
                    </div>
                </div>
            </div>

            {/* 
            {user && user.role ? user.role == 'superadmin' ? <div className="flex gap-[20px] sm:w-[100%] sm:justify-center">
                <div className="p-[10px] w-[100px] rounded-[12px] bg-green-600 text-white font-light flex flex-col justify-center items-center mt-[20px]">
                    <p>หวยในระบบ</p>
                    <p className="font-medium text-[18px]">{left} ใบ</p>
                </div>

                <div className="p-[10px] w-[100px] rounded-[12px] bg-red-600 text-white font-light flex flex-col justify-center items-center mt-[20px]">
                    <p>ถูกซื้อไป</p>
                    <p className="font-medium text-[18px]">{taken} ใบ</p>
                </div>
            </div> : null : null} */}

            {lotteryCheck ? <div className={`w-[100%] mt-[30px] flex flex-col ${search && search.length <= 0 ? 'lg:grid-cols-1' : 'lg:grid-cols-4'} sm:grid gap-[30px] 2xl:pl-[150px] 2xl:pr-[150px] lg:grid-cols-3 lg:gap-[20px] min-[500px]:grid min-[700px]:grid-cols-2 min-[1000px]:grid min-[1000px]:grid-cols-2 min-[1500px]:grid min-[1500px]:grid-cols-4 max-[1080px]:gap-[15px] `}>
                {search && search.length > 0 ? search.map((item) => {
                    let findSame = cart.filter((itemCart) => {
                        return itemCart.number == item.number ? itemCart : null
                    })


                    return (
                        <div>
                            <div onClick={() => {
                                if (item.lock == false && findSame.length <= 0) {
                                    if (user.orderId) {
                                        playtwoAlert.fire({ open: true, dialog: false, confirmText: 'เข้าใจแล้ว', title: 'กรุณาชำระเงินที่สั่งซื้อก่อน' }, () => {
                                            localStorage.setItem('state', 3)
                                            navigate('/cart')
                                        })
                                    } else {
                                        setStatus(true)
                                        AddToCart(item, typeof (item.img) == 'object' ? item.img.length : 1)
                                        setTimeout(() => {
                                            setStatus(false)
                                        }, 600)
                                    }
                                }
                            }} className={`w-[100%] 2xl:w-[100%] bg-white shadow-[0px_3px_4px_0px_rgba(0,0,0,0.2)] relative cursor-pointer ${tu ? 'p-[1px] rounded-[0px] ': 'p-[10px] rounded-[12px]'}`}>
                                {findSame && findSame.length > 0 ? <div onClick={() => {
                                    setStatus(true)
                                    setTimeout(() => {
                                        setStatus(false)
                                        RemoveCart(item.lotteryId)
                                    }, 600)
                                }} className="w-[100%] h-[100%] bg-[rgba(0,0,0,0.6)] absolute top-0 left-0 z-[2] rounded-[12px] flex justify-center items-center">
                                    <Lottie className="w-[100px]" animationData={correct} loop={false} ></Lottie>
                                </div> : null}
                                <div className="bg-blue-600 to-[#FEFFCE] border-[3px] border-t-[0px] w-[70px] h-[80px] absolute left-[20px] top-0 z-[1] rounded-b-[20px] flex justify-center items-center flex-col">
                                    <div className="flex gap-[5px] justify-center items-center">
                                        <p className="font-bold text-white text-[25px]">{typeof (item.img) == 'object' ? item.img.length : 1}</p>
                                        <p className="font-light text-white text-[15px]">ใบ</p>
                                    </div>

                                    <div className="flex gap-[5px] justify-center items-center">
                                        <p className="font-bold text-white text-[25px]">{typeof (item.img) == 'object' ? item.img.length * 6 : 6}</p>
                                        <p className="font-light text-white text-[15px]">ล้าน</p>
                                    </div>
                                </div>
                                <div className="w-[100%] relative">
                                    <div className="w-[100%]">
                                        {typeof (item.img) == "object" ? item.img.map((items, index) => {
                                            // console.log("Items : ", index * 20)
                                            return (
                                                <LazyLoadImage width={'100%'} height={'200px'} className={`${tu ? 'rounded-[0px]' : 'rounded-[12px]'} shadow-[0px_2px_4px_2px_rgba(0,0,0,0.2)] ${index > 0 ? 'mt-[-130px] min-[1080px]:mt-[-190px]' : 'mt-[0px]'}`} src={items}></LazyLoadImage>
                                            )
                                        }) : <LazyLoadImage width={'100%'} height={'160px'} className={` ${tu ? 'rounded-[0px]' : 'rounded-[12px]'} shadow-[0px_2px_4px_2px_rgba(0,0,0,0.2)]`} src={item.img}></LazyLoadImage>}
                                    </div>
                                    {/* <div className="w-[55px] h-[100%] absolute top-0 right-0 rounded-r-[12px] bg-gradient-to-b from-[#6FB3CE] to-[#DAF5FF]">
                                    <p className="rotate-[-90deg] w-[100px] font-medium absolute right-[-10px] top-[20px] text-[12px] text-white">เลขครบ ซื้อง่าย</p>
                                    <p className="rotate-[-90deg] w-[100px] font-medium absolute right-[-30px] top-[45px] text-[12px] text-white">เพราะทุกใบคือโอกาส</p>
                                    <img className="absolute bottom-[10px] w-[40px] left-1/2 translate-x-[-50%]" src="./imgs/qr-code.png"></img>
                                </div> */}
                                    {/* <img className="w-[130px] h-[100px] absolute right-[54px] bottom-[0px] rounded-l-[12px]" src="./imgs/logonew.png"></img> */}
                                </div>
                                {tu ? null : 
                                    item.lock == false ? <button onClick={() => {
                                        if (user.orderId) {
                                            playtwoAlert.fire({ open: true, dialog: false, confirmText: 'เข้าใจแล้ว', title: 'กรุณาชำระเงินที่สั่งซื้อก่อน' }, () => {
                                                localStorage.setItem('state', 3)
                                                navigate('/cart')
                                            })
                                        } else {
                                            setStatus(true)
                                            AddToCart(item)
                                            setStatus(false)
                                            setTimeout(() => {
                                                setStatus(false)
                                            }, 1200)
                                        }
                                    }} className="w-[100%] h-[40px] bg-black mt-[15px] rounded-[12px] bg-gradient-to-b from-[#2292BF] to-[#55BCE5] text-center text-white font-medium text-[20px]">นำเข้าตะกร้า {item.number}</button> : null
                                }
                            </div>
                        </div>
                    )
                }) : <p className="font-medium text-[20px] text-center">หวยถูกขายหมดเเล้ว</p>}
            </div> : <p className="font-medium flex justify-center items-center h-[100vh] text-[24px]">ระบบปิดการสั่งซื้อ</p>}

            <div className="h-[20vh]"></div>
        </div>
    )
}

export default ListLottery