import { useContext, useEffect, useState } from "react"
import { db } from "../firebase"
import { onSnapshot, query, collection, where, deleteField, doc, updateDoc, deleteDoc, getDoc, getDocs } from "firebase/firestore"
import { context } from "../App"
import Alert from "../Components/Alert"
import { maintenanceConfig } from '../config'

let min = 0
let all

const ConfirmOrder = () => {

    let [allOrders, setAllOrders] = useState([])
    let [buying, setbuying] = useState([])
    let [modal, setModal] = useState(false)
    let [count, setCount] = useState(0)
    let [count_lottery, setCountLottery] = useState(0)

    let { playtwoAlert } = useContext(context)


    const GetOrdersUsers = async () => {
        await onSnapshot(query(collection(db, 'orders'), where("confirm_slip", "==", false), where("payment_success", "==", true)), (snapshot) => {
            let converSnapshot = snapshot.docs.map((item) => {
                return {
                    ...item.data(),
                    orderId: item.id
                }
            })

            console.log(converSnapshot)

            setAllOrders(converSnapshot)
        })

        onSnapshot(query(collection(db, 'orders'), where("confirm_slip", '==', false), where("payment_success", "==", false)), (snapshot) => {
            let converSnapshot = snapshot.docs.map((item) => {
                return {
                    ...item.data(),
                    orderId: item.id
                }
            })

            setbuying(converSnapshot)
        })

        onSnapshot(doc(db, 'admin_panel', 'tbW2GFM9UV0UKUCA6StW'), (snapshot) => {
            setCount(snapshot.data().count)
            setCountLottery(snapshot.data().count_all_lottery)
        })
    }

    const init = () => {
        GetOrdersUsers()
    }

    useEffect(() => {
        init()
    }, [])


    // Methods

    const BadSlipOrder = (item) => {
        playtwoAlert.fire({ open: true, title: 'คำสั่งซื้อไม่ถูกต้อง', dialog: true, confirmText: 'ยืนยัน', cancelText: 'ยกเลิก' }, () => {

            updateDoc(doc(db, 'users', item.userId), {
                orderId: deleteField()
            }).then(() => {

                item.cart.map((cartItem) => {
                    updateDoc(doc(db, 'lottery', cartItem.lotteryId), {
                        lock: false
                    }).then(() => {

                        deleteDoc(doc(db, 'orders', item.orderId)).then(() => {
                            playtwoAlert.fire({ open: true, title: 'ยกเลิกคำสั่งซื้อเรียบร้อย', dialog: false, confirmText: 'ตกลง' }, () => {
                                console.log("success")
                            })
                        })

                    })
                })
            })
        })
    }

    const GoodSlipOrder = (item) => {

        playtwoAlert.fire({ open: true, title: 'ยืนยันคำสั่งซื้อ', dialog: true, confirmText: 'ยืนยัน', cancelText: 'ยกเลิก' }, async () => {

            getDoc(doc(db, 'orders', item.orderId)).then(async (res) => {
                if (res.data().confirm_slip) {
                    playtwoAlert.fire({ open: true, title: 'Admin อีกคนยืนยันแล้ว', dialog: false, confirmText: 'เข้าใจแล้ว' }, () => {
                        console.log("ok")
                    })
                } else {

                    let totalQuantity
                    let RefUser

                    totalQuantity = item.cart.reduce((amount, current) => {
                        return amount = amount + current.quantity
                    }, 0)

                    console.log(totalQuantity)

                    // Get Userid from Order
                    let data = await getDoc(doc(db, 'users', item.userId)).then((res) => {
                        let data = {
                            ...res.data(),
                            userId: res.id
                        }

                        return data
                    })

                    // If User has true

                    if (data) {

                        // Check If user has true and have got reference from others people
                        // Update Only data have owner_ref
                        if (data && data.owner_ref) {

                            // Reference Invite
                            let phone_regex = /^\d{10}$/
                            let bool_regex = phone_regex.test(data.owner_ref)

                            if(bool_regex){
                                RefUser = await getDocs(query(collection(db, 'users'), where('numberPhone', "==", data.owner_ref))).then((res) => {
                                    let data = res.docs.map((item)=>{
                                        return{
                                            ...item.data(),
                                            userId: item.id
                                        }
                                    })
    
                                    return data
                                })
                            }else{
                                RefUser = await getDoc(doc(db, 'users', data.owner_ref)).then((res) => {
                                    let data = {
                                        ...res.data(),
                                        userId: res.id
                                    }
    
                                    return data
                                })
                            }

                            console.log("RefUser : ", RefUser)

                            if (data.owner_ref) {

                                if (phone_regex.test(data.owner_ref)) {
                                    let user = await getDocs(query(collection(db, 'users'), where('numberPhone', '==', data.owner_ref))).then((snapshot) => {
                                        let data = snapshot.docs.map((item) => {
                                            return {
                                                ...item.data(),
                                                userId: item.id
                                            }
                                        })
                                        return data
                                    })

                                    console.log("Check User", user)

                                    updateDoc(doc(db, 'users', user[0].userId), {
                                        point: RefUser[0].point ? RefUser[0].point + (totalQuantity * 2) : totalQuantity * 2
                                    })
                                } else {
                                    updateDoc(doc(db, 'users', data.owner_ref), {
                                        point: RefUser.point ? RefUser.point + (totalQuantity * 2) : totalQuantity * 2
                                    })
                                }
                            }
                        }

                        await getDoc(doc(db, 'admin_panel', 'tbW2GFM9UV0UKUCA6StW')).then((res) => {
                            updateDoc(doc(db, 'admin_panel', 'tbW2GFM9UV0UKUCA6StW'), {
                                count: res.data().count + totalQuantity
                            })
                        })

                        await getDoc(doc(db, 'statistic', 'kD8uSLN2ns70qWSeP304')).then((res)=>{
                            updateDoc(doc(db, 'statistic', 'kD8uSLN2ns70qWSeP304'), {
                                all_buy: res.data().all_buy + totalQuantity
                            })
                        })

                        // No matter what when buy it will update mypoint always
                        updateDoc(doc(db, 'users', data.userId), {
                            point: data.point ? data.point + (totalQuantity * 3) : totalQuantity * 3
                        })

                        updateDoc(doc(db, 'orders', item.orderId), {
                            confirm_slip: true
                        }).then(() => {

                            updateDoc(doc(db, 'users', item.userId), {
                                orderId: deleteField(),
                            }).then(() => {

                                playtwoAlert.fire({ open: true, title: 'ยืนยันเรียบร้อย', dialog: false, confirmText: 'เข้าใจแล้ว' }, () => {
                                    console.log("success")
                                })

                            })
                        })


                    }

                }
            })
        })
    }

    // let [diff, setDiff] = useState(0)
    let [intervalExpire, setIntervalExpire] = useState(null)

    return (
        <div>

            {modal ? <div onClick={(e) => {
                if (e.target == e.currentTarget) {
                    setModal(false)
                }
            }} className="w-[100%] h-[100%] fixed flex justify-center items-center bg-[rgba(0,0,0,0.2)] z-[1]">
                <div>
                    {buying && buying.length > 0 ? buying.map((item) => {

                        let totalQuantity = item.cart.reduce((amount, item) => {
                            return amount = amount + item.quantity
                        }, 0)

                        let end = item.timestampEnd

                        let now

                        now = new Date()
                        let diff = end - now

                        let init_min = 60

                        let expireDate = new Date(diff)

                        min = expireDate.getMinutes()
                        let sec = expireDate.getSeconds()

                        return (
                            <div className="mt-[10px] bg-slate-300 rounded-[8px] p-[10px]">
                                <p className="font-medium text-[20px]">{item.name} {diff > 0 ? `เหลือ : ${(min)} นาที ${sec} วินาที` : 'หมดเวลาแล้ว'} ({totalQuantity} ใบ)</p>
                                <div className={`p-[10px] ${diff <= 0 ? 'bg-red-600' : 'bg-slate-400'} text-white font-medium text-center mt-[10px] rounded-[8px] ${diff < 0 ? 'pointer-events-auto' : 'pointer-events-none'}`} onClick={() => {

                                    playtwoAlert.fire({ open: true, title: 'คืนลอตเตอรี่?', dialog: true, confirmText: 'เข้าใจแล้ว', cancelText: 'ยกเลิก' }, async () => {

                                        console.log("Buying Test : ", buying)

                                        await item.cart.map((item) => {
                                            updateDoc(doc(db, 'lottery', item.lotteryId), {
                                                lock: false
                                            })

                                            console.log("Return Lottery : ", item.lotteryId)
                                        })

                                        // await updateDoc(doc(db, 'users', item.userId), {
                                        //     orderId: deleteField(),
                                        // })

                                        // // Delete Order that Created
                                        await deleteDoc(doc(db, 'orders', item.orderId))


                                    })

                                }}>คืนลอตเตอรี่</div>
                            </div>
                        )
                    }) : <p className="font-medium text-[24px]">ไม่มีออเดอร์ที่กำลังซื้อ</p>}
                </div>
            </div> : null}


            <Alert />
            <div className="p-[10px] ">
                <p className="font-medium text-[24px]">ยืนยันคำสั่งซื้อ</p>

                <div className="flex gap-[10px]">
                    <div className="mt-[20px] w-[120px] h-[50px] rounded-[8px] flex justify-center items-center bg-green-600">
                        <p className="font-medium text-white">หวยในระบบ {count_lottery ? count_lottery - count : 0}</p>
                    </div>
                    <div className="mt-[20px] w-[100px] h-[50px] rounded-[8px] flex justify-center items-center bg-red-600">
                        <p className="font-medium text-white">ถูกซื้อไป {count ? count : 0} </p>
                    </div>
                </div>

                <div className="p-[10px] bg-white w-[120px] rounded-[8px] mt-[10px] flex items-center gap-[10px] absolute right-5 top-10" onClick={() => {
                    setModal(true)
                }}>
                    <p className="font-medium">เกินเวลา</p>
                    <img className="w-[30px]" src="./imgs/timer.png"></img>
                </div>

                <div className="flex gap-[20px]">
                    {allOrders && allOrders.length > 0 ? allOrders.map((item) => {

                        let totalQuantity = item.cart.reduce((amount, item) => {
                            return amount = amount + item.quantity
                        }, 0)

                        let totalMoney = item.cart.reduce((amount, item) => {
                            return amount = amount + (item.price * item.quantity) + (maintenanceConfig * item.quantity)
                        }, 0)

                        return (
                            <div className="bg-white p-[10px] rounded-[8px] mt-[20px]">
                                <p className="font-medium">{item.name ? item.name : item.numberPhone}</p>
                                {item.cart.map((cartItem) => {
                                    return (
                                        <div className="flex justify-between items-center">
                                            <p className="font-medium">{cartItem.number} {cartItem.quantity > 1 ? '(หวยชุด)' : ''}</p>
                                            {/* <p className="font-medium text-green-600 text-[18px]">{cartItem.price}฿</p> */}
                                        </div>
                                    )
                                })}
                                <p className="font-medium">{item.way == 'others' ? 'ช่องทาง : วาฬฟ้าดูแล' : 'ช่องทาง : EMS+50'}</p>
                                <p className="font-medium">ซื้อสลาก : ({totalQuantity}) ใบ</p>
                                <div className="flex flex-col">
                                    <div onClick={() => {
                                        GoodSlipOrder(item)
                                    }} className="p-[5px] mt-[10px] h-[60px] bg-green-600 text-white text-center font-medium rounded-[8px] flex justify-center items-center text-[20px]"><p>ยืนยัน {totalMoney}฿</p></div>
                                    <div onClick={() => {
                                        BadSlipOrder(item)
                                    }} className="p-[5px] mt-[10px] bg-red-600 text-white text-center font-medium rounded-[8px]"><p>คำสั่งซื้อไม่ถูกต้อง</p></div>
                                </div>
                            </div>
                        )
                    }) : <p className="font-medium mt-[20px]">ไม่มีออเดอร์ที่ซื้อ</p>}
                </div>
            </div>
        </div>
    )
}

export default ConfirmOrder