import { useContext, useEffect, useState } from "react"
import { getDocs, doc, query, collection, where, onSnapshot, updateDoc, getDoc, deleteDoc, deleteField, addDoc, } from 'firebase/firestore'
import { db, storage } from "../firebase"
import Alert from "../Components/Alert"
import { context } from "../App"
import { BsFillSave2Fill } from "react-icons/bs";
import axios from "axios"
import { TbReplaceFilled } from "react-icons/tb";
import { PiArrowFatRightFill } from "react-icons/pi";
import { CgDanger } from "react-icons/cg";
import { deleteObject, getStorage, list, ref } from "firebase/storage"
import Loading from './loading-page.json'
import Lottie from "lottie-react"
import { FcRefresh } from "react-icons/fc";
import {motion} from 'framer-motion'

/**
 * @param {string} collectionPath
 * @returns {JSX.Element}
 */


const AdminConsole = () => {

    let [usersWin, setUsersWin] = useState([])
    let [announce, setAnnounce] = useState('')
    let [panel, setPanel] = useState(null)
    let [date_end, setDate_End] = useState('')

    let [passwordModal, setPasswordModal] = useState(false)
    let [password, setPassword] = useState('')
    let [modalReset, setModalReset] = useState(false)
    let [passwordReset, setPasswordReset] = useState('')

    let [loading, setloading] = useState(false)
    let [modalDiscount, setModalDiscount] = useState(false)

    let [code, setCode] = useState('')
    let [limit, setLimit] = useState(0)
    let [reduce, setReduce] = useState(0)

    let { playtwoAlert } = useContext(context)

    const init = () => {
        onSnapshot(query(collection(db, 'users_win'), where('transaction', '==', false)), (snapshot) => {
            let data = snapshot.docs.map((item) => {
                return {
                    ...item.data(),
                    winId: item.id
                }
            })

            setUsersWin(data)
        })

        onSnapshot(doc(db, 'admin_panel', 'tbW2GFM9UV0UKUCA6StW'), ((snapshot) => {
            setPanel(snapshot.data())
            setDate_End(snapshot.data().date_end)
            setAnnounce(snapshot.data().announce)
        }))

    }


    const findUsersWin = () => {
        playtwoAlert.fire({ open: true, confirmText: 'ยืนยัน', dialog: true, cancelText: 'ยกเลิก', title: 'ต้องการดึงข้อมูลคนถูกหวย?' }, () => {
            try {
                axios.get(`${process.env.REACT_APP_URI}/api/check_users_win`).then((res) => {
                    if (res.data.status == 200) {
                        console.log(res.data)
                        playtwoAlert.fire({ open: true, confirmText: 'ตกลง', dialog: false, title: 'ดึงข้อมูลคนถูกหวยสำเร็จ' }, () => {
                            console.log("ok")
                        })
                    }
                })
            } catch (err) {
                playtwoAlert.fire({ open: true, confirmText: 'เข้าใจแล้ว', dialog: false, title: 'status 404 not found' }, () => {
                    console.log("ok")
                })
            }
        })
    }


    const StoreHistory = () => {

        try {
            axios.get(`${process.env.REACT_APP_URI}/api/store_history`).then((res) => {
                if (res.data.status == 200) {
                    playtwoAlert.fire({ open: true, confirmText: 'เสร็จสิ้น', dialog: false, title: 'เก็บข้อมูลทุกคนไว้ในประวัติเรียบร้อยแล้ว' }, () => {
                        console.log("ok")
                    })
                } else {
                    playtwoAlert.fire({ open: true, confirmText: 'เข้าใจแล้ว', dialog: false, title: 'บางอย่างผิดพลาด' }, () => {
                        console.log("ok")
                    })
                }
            })
        } catch (err) {
            playtwoAlert.fire({ open: true, confirmText: 'เข้าใจแล้ว', dialog: false, title: 'status 404 not found' }, () => {
                console.log("ok")
            })
        }
    }

    const deleteCollection = async (collectionPath) => {

        return new Promise(async (resolve, reject) => {
            let all_collection = await getDocs(collection(db, collectionPath)).then((res) => {
                let data = res.docs.map((item) => {
                    return {
                        ...item.data(),
                        id: item.id
                    }
                })

                return data
            })

            if (all_collection.length > 0) {
                all_collection.map(async (item, index) => {
                    await deleteDoc(doc(db, collectionPath, item.id))

                    if ((all_collection.length - 1) == index) {
                        resolve({ deleted: true })
                    }
                })

                resolve({ deleted: true })
            } else {
                resolve({ deleted: false })
            }

        })
    }

    const deleteFolder = async (folderPath) => {
        return new Promise(async (resolve, reject) => {
            let folderRef = ref(storage, folderPath)

            let items = await list(folderRef);

            if (items.items.length > 0) {
                await items.items.map(async (item) => {
                    await deleteObject(item)
                })

                resolve({ deleted: true })

                console.log(`folder ${folderPath} deleted successfully!`)
            } else {
                resolve({ deleted: false })
            }
        })
    }

    // const deleteUsersWin = async () => {
    //     return new Promise(async (resolve, reject) => {
    //         let data = await getDocs(doc(db, 'users_win')).then((res) => {
    //             let payload = res.docs.map((item) => {
    //                 return {
    //                     ...item.data(),
    //                     userId: item.id
    //                 }
    //             })

    //             return payload
    //         })

    //         if (data.length > 0) {
    //             data.map((item, index) => {
    //                 deleteDoc(doc(db, 'users_win', item.userId))

    //                 if (index == (data.length - 1)) {
    //                     resolve({ deleted: true })
    //                 }

    //             })
    //         }else{
    //             resolve({ deleted: true })
    //         }

    //     })
    // }

    const updatePanel = async () => {

        return new Promise(async (resolve, reject) => {
            await updateDoc(doc(db, 'admin_panel', 'tbW2GFM9UV0UKUCA6StW'), {
                count: 0,
                count_all_lottery: 0
            })

            let data = await getDocs(collection(db, 'users_win')).then((res) => {
                let payload = res.docs.map((item) => {
                    return {
                        ...item.data(),
                    }
                })

                return payload
            })

            if (data && data.length > 0) {
                data.map((item, index) => {
                    updateDoc(doc(db, 'users', item.userId), {
                        transaction: deleteField()
                    })

                    if (index == (data.length - 1)) {
                        resolve({ updated: true })
                    }
                })
            } else {
                resolve({ updated: true })
            }

        })
    }


    const ResetEverything = async () => {
        await updatePanel()
        await deleteCollection('orders')
        await deleteCollection('lottery')
        await deleteCollection('users_win')
        await deleteFolder('lottery')
        await deleteFolder('slip_payment')

        setloading(false)
        playtwoAlert.fire({ open: true, confirmText: 'ตกลง', dialog: false, title: 'รีเซ็ตเรียบร้อยแล้ว' }, () => {
            console.log("ok")
        })
    }

    const AddCode = () => {
        addDoc(collection(db, 'discounts'), {
            code: code,
            current: 0,
            limit: Number(limit),
            reduce: Number(reduce),
            users_use: []
        }).then(() => {
            setModalDiscount(false)
            playtwoAlert.fire({ open: true, confirmText: 'ตกลง', dialog: false, title: 'เพิ่มบัตรส่วนลดสำเร็จ!' }, () => {
                console.log("OK Reduce")
            })
        })
    }


    useEffect(() => {
        init()
    }, [])

    return (
        <div>

            <Alert />


            {loading ? <div className="w-[100%] h-[100vh] fixed flex justify-center items-center z-[2]">
                <Lottie animationData={Loading}></Lottie>
            </div> : null}

            <div className="flex justify-center flex-col items-center">
                <p className="mt-[20px] mb-[20px] font-medium text-[24px]">คอนโซล แอดมิน</p>
                <div className="flex gap-[20px] flex-col">
                    <div className="flex justify-center flex-col items-center gap-[20px]">
                        <div className="bg-white p-[10px] rounded-[8px]">
                            <label onClick={() => {
                                if (panel && panel.turnon_lottery) {
                                    updateDoc(doc(db, 'admin_panel', 'tbW2GFM9UV0UKUCA6StW'), {
                                        turnon_lottery: false
                                    })
                                } else {
                                    updateDoc(doc(db, 'admin_panel', 'tbW2GFM9UV0UKUCA6StW'), {
                                        turnon_lottery: true
                                    })
                                }
                            }} class="relative inline-flex items-center me-5 cursor-pointer w-[220px] justify-between">
                                <input type="checkbox" value="" class="sr-only peer" checked={panel && panel.turnon_lottery ? true : false}></input>
                                <div class="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-green-300 dark:peer-focus:ring-green-800 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-600"></div>
                                <span class="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">เปิดปิดการซื้อขายลอตเตอรี่</span>
                            </label>
                        </div>

                        <div className="bg-white p-[10px] rounded-[8px]">
                            <label onClick={() => {
                                if (panel && panel.turnon_check_lottery) {
                                    updateDoc(doc(db, 'admin_panel', 'tbW2GFM9UV0UKUCA6StW'), {
                                        turnon_check_lottery: false
                                    })
                                } else {
                                    updateDoc(doc(db, 'admin_panel', 'tbW2GFM9UV0UKUCA6StW'), {
                                        turnon_check_lottery: true
                                    })
                                }
                            }} class="relative inline-flex items-center me-5 cursor-pointer w-[220px] justify-between">
                                <input type="checkbox" value="" class="sr-only peer" checked={panel && panel.turnon_check_lottery ? true : false}></input>
                                <div class="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-green-300 dark:peer-focus:ring-green-800 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-600"></div>
                                <span class="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">เปิดให้ USER ตรวจหวยได้</span>
                            </label>
                        </div>

                        <div className="bg-white p-[10px] rounded-[8px]">
                            <label onClick={() => {
                                if (panel && panel.time_status_show) {
                                    updateDoc(doc(db, 'admin_panel', 'tbW2GFM9UV0UKUCA6StW'), {
                                        time_status_show: false
                                    })
                                } else {
                                    updateDoc(doc(db, 'admin_panel', 'tbW2GFM9UV0UKUCA6StW'), {
                                        time_status_show: true
                                    })
                                }
                            }} class="relative inline-flex items-center me-5 cursor-pointer w-[220px] justify-between">
                                <input type="checkbox" value="" class="sr-only peer" checked={panel && panel.time_status_show ? true : false}></input>
                                <div class="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-green-300 dark:peer-focus:ring-green-800 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-600"></div>
                                <span class="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">เปิดนับเวลาถอยหลัง</span>
                            </label>
                        </div>

                        {/* <div className="flex gap-[20px]">
                            <button onClick={() => {

                                playtwoAlert.fire({ open: true, confirmText: 'เปิด', dialog: true, cancelText: 'ยกเลิก', title: 'ต้องการเปิด?' }, () => {
                                    updateDoc(doc(db, 'admin_panel', 'tbW2GFM9UV0UKUCA6StW'), {
                                        turnon_lottery: true
                                    })
                                })
                                
                            }} className="font-medium p-[10px] bg-green-600 rounded-[12px] text-white">เปิด</button>

                            <button onClick={() => {
                                playtwoAlert.fire({ open: true, confirmText: 'ปิด', dialog: true, cancelText: 'ยกเลิก', title: 'ต้องการปิด?' }, () => {
                                    updateDoc(doc(db, 'admin_panel', 'tbW2GFM9UV0UKUCA6StW'), {
                                        turnon_lottery: false
                                    })
                                })
                            }} className="font-medium p-[10px] bg-red-700 rounded-[12px] text-white">ปิด</button>
                        </div> */}



                    </div>

                    <div className="flex justify-center items-center flex-col gap-[10px] bg-white p-[10px] rounded-[8px]">
                        <div className="flex justify-center items-center flex-col">
                            <p className="font-medium text-[18px]">เลื่อนเวลาตรวจหวย</p>
                            <p className="font-light text-[14px]">-แจ้งกำหนดการตรวจหวย-</p>
                            <p className="font-light">format : D-M-Y-T.M</p>
                        </div>
                        <input value={announce} onChange={(e) => {
                            setAnnounce(e.target.value)
                        }} placeholder={panel ? panel.announce : null} className="rounded-[8px] p-[10px] text-center font-medium border-[1px] border-gray-200"></input>
                        <button onClick={() => {

                            playtwoAlert.fire({ open: true, confirmText: 'เปลี่ยน', dialog: true, cancelText: 'ยกเลิก', title: 'เปลี่ยนเวลาประกาศ?' }, () => {
                                updateDoc(doc(db, 'admin_panel', 'tbW2GFM9UV0UKUCA6StW'), {
                                    announce: announce
                                }).then(() => {
                                    playtwoAlert.fire({ open: true, confirmText: 'ตกลง', dialog: false, title: 'สำเร็จ' }, () => {
                                        console.log('ok')
                                    })
                                })
                            })

                        }} className="font-light p-[10px] bg-green-600 rounded-[12px] text-white">เปลี่ยนเวลาประกาศ</button>
                    </div>

                    <div className="flex justify-center items-center flex-col gap-[10px] bg-white p-[10px] rounded-[8px]">
                        <div className="flex justify-center items-center flex-col">
                            <p className="font-medium text-[18px]">กำหนดเวลาวางขายสลาก</p>
                            <p className="font-light text-[14px]">-นับเวลาถอยหลัง-</p>
                            <p className="font-light text-[14px]">format : Y-M-D T-M-S</p>
                            <p className="font-light text-[14px]">format : {panel ? panel.date_end : null}</p>
                        </div>
                        <input value={panel ? date_end : null} onChange={(e) => {
                            setDate_End(e.target.value)
                        }} placeholder="กรอกตาม format ด้านบน" className="rounded-[8px] p-[10px] text-center font-medium border-[1px] border-gray-200"></input>
                        <button onClick={() => {

                            playtwoAlert.fire({ open: true, confirmText: 'เปลี่ยน', dialog: true, cancelText: 'ยกเลิก', title: 'เปลี่ยนเวลาวางขาย?' }, () => {
                                updateDoc(doc(db, 'admin_panel', 'tbW2GFM9UV0UKUCA6StW'), {
                                    date_end: date_end
                                }).then(() => {
                                    playtwoAlert.fire({ open: true, confirmText: 'ตกลง', dialog: false, title: 'สำเร็จ' }, () => {
                                        console.log('ok')
                                    })
                                })
                            })

                        }} className="font-light p-[10px] bg-green-600 rounded-[12px] text-white">เปลี่ยนเวลาวางขาย</button>
                    </div>

                    {/* <div className="flex justify-center items-center flex-col gap-[10px]">
                        <p className="font-light">เปิดให้ผู้ซื้อสามารถตรวจหวยได้</p>
                        <div className="flex gap-[20px]">
                            <button onClick={() => {

                                playtwoAlert.fire({ open: true, confirmText: 'เปิด', dialog: true, cancelText: 'ยกเลิก', title: 'ต้องการเปิด?' }, () => {
                                    updateDoc(doc(db, 'admin_panel', 'tbW2GFM9UV0UKUCA6StW'), {
                                        turnon_check_lottery: true
                                    })
                                })
                            }} className="font-light p-[10px] bg-green-600 rounded-[12px] text-white">เปิด</button>

                            <button onClick={() => {
                                playtwoAlert.fire({ open: true, confirmText: 'ปิด', dialog: true, cancelText: 'ยกเลิก', title: 'ต้องการปิด?' }, () => {
                                    updateDoc(doc(db, 'admin_panel', 'tbW2GFM9UV0UKUCA6StW'), {
                                        turnon_check_lottery: false
                                    })
                                })
                            }} className="font-light p-[10px] bg-red-700 rounded-[12px] text-white">ปิด</button>
                        </div>
                    </div> */}
                </div>


                {passwordModal ? <div className="w-[100%] h-[100%] fixed bg-[rgba(0,0,0,0.5)] top-0 flex justify-center items-center">
                    <div className="w-[300px] h-[200px] bg-white rounded-[30px] relative flex justify-center items-center">

                        <div className="flex justify-center items-center flex-col">
                            <p className="font-[apple-bold] text-[23px] text-center">กรุณาใส่รหัสผ่าน</p>

                            <input onChange={(e) => {
                                setPassword(e.target.value)
                            }} className="w-[100%] h-[50px] border-[1px] border-gray-600 mt-[10px] rounded-[12px] text-center font-medium"></input>

                            <div className="bottom-[30px] flex gap-[20px] mt-[20px]">
                                <button onClick={() => {
                                    if (password == 'history') {
                                        StoreHistory()
                                        setPasswordModal(false)
                                    } else {
                                        setPasswordModal(false)
                                        alert("รหัสผ่านไม่ถูกต้อง")
                                    }
                                }} className="font-[apple-bold] w-[100px] h-[40px] bg-green-600 text-white rounded-[13px]">ตกลง</button>
                                <button onClick={() => {
                                    setPasswordModal(false)
                                }} className="font-[apple-bold] w-[100px] h-[40px] bg-red-800 text-white rounded-[13px]">ยกเลิก</button>
                            </div>
                        </div>
                    </div>
                </div> : null}

                {modalReset ? <div className="w-[100%] h-[100%] fixed bg-[rgba(0,0,0,0.5)] top-0 flex justify-center items-center">
                    <div className=" bg-white rounded-[30px] relative flex justify-center items-center p-[10px]">

                        <div className="flex justify-center items-center flex-col">
                            <p className="font-[apple-bold] text-[23px] text-center">กรุณาใส่รหัสผ่าน</p>
                            <p className="font-[apple-bold] text-[20px] w-[100%] mt-[20px] mb-[5px] underline">ระบบจะทำการ</p>
                            <p className="font-[apple-bold] text-[16px] w-[100%]">- รีเซ็ตกระเป๋า (orders Collection)</p>
                            <p className="font-[apple-bold] text-[16px] w-[100%]">- ลบหวย (lottery Collection)</p>
                            <p className="font-[apple-bold] text-[16px] w-[100%]">- ลบรูปภาพหวยและสลิป (lottery_img, slip)</p>
                            <p className="font-[apple-bold] text-[16px] w-[100%]">- รีเซ็ตถูกซื้อเป็น 0 (count=0)</p>
                            <p className="font-[apple-bold] text-[16px] w-[100%] mb-[10px]">- หวยทั้งหมดเป็น 0 (count_all_lottery=0)</p>

                            <input placeholder="รหัสผ่าน" onChange={(e) => {
                                setPasswordReset(e.target.value)
                            }} className="w-[100%] h-[50px] border-[1px] border-gray-600 mt-[10px] rounded-[12px] text-center font-medium"></input>

                            <div className="bottom-[30px] flex gap-[20px] mt-[20px]">
                                <button onClick={() => {
                                    if (passwordReset == 'reset') {
                                        ResetEverything()
                                        setloading(true)
                                        setModalReset(false)
                                    } else {
                                        setModalReset(false)
                                        alert("รหัสผ่านไม่ถูกต้อง")
                                    }
                                }} className="font-[apple-bold] w-[100px] h-[40px] bg-green-600 text-white rounded-[13px]">ตกลง</button>
                                <button onClick={() => {
                                    setModalReset(false)
                                }} className="font-[apple-bold] w-[100px] h-[40px] bg-red-800 text-white rounded-[13px]">ยกเลิก</button>
                            </div>
                        </div>
                    </div>
                </div> : null}

                <motion.div onClick={(e)=>{
                    if(e.target == e.currentTarget){
                        setModalDiscount(false)
                    }
                }} initial={{opacity: 0}} animate={modalDiscount ? {opacity: 1} : {opacity: 0}} className={`${modalDiscount ? 'pointer-events-auto' : 'pointer-events-none'} w-full h-full fixed left-0 top-0 bg-black/60 flex justify-center items-center`}>
                    <div className="w-[300px] bg-white rounded-[8px] p-[20px]">
                        <input onChange={(e) => {
                            setCode(e.target.value)
                        }} className="w-full h-[40px] border-[1px] border-gray-400 mb-[10px] rounded-[8px] font-[medium] text-center" placeholder="โค้ด"></input>
                        <input type="number" onChange={(e) => {
                            setLimit(e.target.value)
                        }} className="w-full h-[40px] border-[1px] border-gray-400 mb-[10px] rounded-[8px] font-[medium] text-center" placeholder="จำกัด(อัน)"></input>
                        <input type="number" onChange={(e) => {
                            setReduce(e.target.value)
                        }} className="w-full h-[40px] border-[1px] border-gray-400 mb-[10px] rounded-[8px] font-[medium] text-center" placeholder="ลด(บาท)"></input>
                        <div onClick={() => {
                            AddCode()
                        }} className="w-full h-[50px] bg-blue-500 rounded-[8px] flex justify-center items-center">
                            <p className="font-[medium] text-[24px] text-white">เพิ่มโค้ดลดราคา</p>
                        </div>
                    </div>
                </motion.div>

                <div onClick={()=>{
                    setModalDiscount(true)
                }} className="flex justify-center items-center bg-white w-[270px] mt-[20px] rounded-[8px] h-[40px]">
                    <p className="font-[medium]">เพิ่มโค้ดส่วนลด +</p>
                </div>

                <div className="flex gap-[10px] items-center mt-[20px]">
                    <p className="font-medium text-[24px]">รายชื่อคนที่ถูกรางวัล</p>
                    <div className="bg-white p-[10px] rounded-[12px]">
                        <FcRefresh onClick={() => {
                            findUsersWin()
                        }} size={24} />
                    </div>
                </div>

                <div className="flex flex-col gap-[20px] mt-[20px]">
                    {usersWin && usersWin.length > 0 ? usersWin.map((item) => {

                        return (
                            <div className="w-[350px] bg-white rounded-[12px] flex justify-center flex-col p-[20px]">
                                <p className="font-medium text-[24px] text-center">{item.text}</p>
                                <p className="mb-[10px] text-center font-medium text-[20px]">💸เงินรางวัล {(item.price * item.quantity).toLocaleString('TH-th')}💸</p>
                                <p className="font-light">ชื่อ : {item.name} {item.lastName}</p>
                                <p className="font-light">เบอร์โทรศัพท์ : {item.numberPhone ? item.numberPhone : 'รองวดหน้า'}</p>
                                <p className="font-light">เลขที่ถูก : {item.userNumber} {item.quantity > 1 ? '(หวยชุด)' : null}</p>
                                {/* <p className="font-light">หวยที่ถูกรางวัล : {combineText}</p> */}
                                {/* <div className="w-[100%] bg-slate-200 rounded-[12px] mt-[10px] flex p-[10px] items-center justify-center gap-[20px]">
                                    {item.banking_name ? <img className="w-[70px] rounded-[12px]" src={item.banking_name.img}></img> : null}
                                    <div>
                                        <p className="font-medium">{item.banking_name ? item.banking_name.name : null}</p>
                                        <p className="font-light">{item.banking_username}</p>
                                        <p className="font-light">{item.banking_number}</p>
                                    </div>
                                </div> */}

                                <button onClick={() => {
                                    playtwoAlert.fire({ open: true, confirmText: 'ตกลง', dialog: true, cancelText: 'ยกเลิก', title: 'ต้องการยืนยัน?' }, () => {

                                        updateDoc(doc(db, 'users_win', item.winId), {
                                            transaction: true
                                        })

                                        updateDoc(doc(db, 'users', item.userId), {
                                            transaction: true
                                        }).then(() => {
                                            playtwoAlert.fire({ open: true, confirmText: 'ตกลง', dialog: false, cancelText: 'ยกเลิก', title: 'ยืนยันสำเสร็จ' }, () => {
                                                console.log("Ok")
                                            })
                                        })
                                    })
                                }} className="mt-[20px] bg-green-600 text-white p-[10px] rounded-[12px] text-[20px] font-medium">โอนเงิน {(item.price * item.quantity).toLocaleString('TH-th')} เรียบร้อย</button>
                            </div>
                        )
                    }) : <p className="font-light">ยังไม่มีคนถูกรางวัล</p>}
                </div>


                <div className="mt-[30px] flex gap-[20px] items-center flex-col bg-white p-[10px] rounded-[8px] mb-[20px]">

                    <div className="w-[100%]">
                        <div className="flex gap-[10px] items-center">
                            <div className="flex gap-[5px] items-center">
                                <p className="text-red-600 font-medium">คำเตือน</p>
                                <CgDanger color="red" />
                            </div>
                            <p className="font-medium">เฉพาะผู้พัฒนาระบบ (Developer)</p>
                        </div>
                        <p className="font-light w-[270px]">- จะกดได้ก็ต่อเมื่อ api หวยงวดใหม่ออก</p>
                        <p className="font-light w-[270px]">- จะไม่มีการเก็บประวัติซ้ำในงวดนั้นๆ</p>
                    </div>

                    <div className="flex gap-[20px] items-center">
                        <button onClick={() => {
                            setPasswordModal(true)
                        }} className="bg-blue-500 p-[10px] rounded-[12px] flex gap-[10px] items-center focus:ring-4">
                            <p className="font-light text-white">เก็บประวัติให้ USER</p>
                            <BsFillSave2Fill color="white" />
                        </button>

                        <PiArrowFatRightFill />

                        <button onClick={() => {
                            setModalReset(true)
                        }} className="bg-blue-500 p-[10px] rounded-[12px] flex gap-[10px] items-center focus:ring-4">
                            <p className="font-light text-white">รีเซ็ต</p>
                            <TbReplaceFilled color="white" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminConsole