import { useRef, useState } from "react"

const usePlaytwoAlert = () => {
    let tempPromise = useRef(null)

    let [playtwoAlert, setPlaytwoAlert] = useState({
        open: false,
        title: '',
        confirmText: '',
        cancelText: '',
    })

    const ResetObjectAlert = () => {
        setPlaytwoAlert({
            open: false,
            confirmText: '',
            cancelText: '',
            title: ''
        })
    }

    playtwoAlert.fire = (data, cb) => {
        console.log(data)
        setPlaytwoAlert(data)
        // tempPromise.current = resolve
        tempPromise.current = cb
    }

    playtwoAlert.handleConfirm = () => {
        tempPromise.current()
        ResetObjectAlert()
    }

    playtwoAlert.handleCancel = () => {
        setPlaytwoAlert({ ...playtwoAlert, open: false })
    }

    return playtwoAlert
}

export default usePlaytwoAlert