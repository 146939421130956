import { useContext, useRef, useState } from "react"
import { context } from "../App"
import { motion } from 'framer-motion'

const Alert = () => {

    let { playtwoAlert } = useContext(context)

    // console.log(playtwoAlert)

    let variants = {
        'open': {
            opacity: 1,
        },
        'close': {
            opacity: 0,
        }
    }


    return (
        < motion.div variants={variants} animate={playtwoAlert.open ? 'open' : 'close'} initial={'close'} className={`w-[100%] h-[100%] fixed bg-[rgba(0,0,0,0.6)] z-[9999999] flex justify-center items-center ${playtwoAlert.open ? 'pointer-events-auto' : 'pointer-events-none'}`} >
            <div className="w-[300px] h-[200px] bg-white rounded-[30px] relative flex justify-center items-center p-[5px]">

                <div className="flex justify-center items-center flex-col">
                    <p className="font-[apple-bold] text-[23px] text-center">{playtwoAlert.title}</p>

                    <div className="bottom-[30px] flex gap-[20px] mt-[20px]">
                        <button onClick={() => {
                            // Logout()
                            // setAlert(false)
                            // console.log(playtwoAlert)
                            playtwoAlert.handleConfirm()
                        }} className="font-[apple-bold] w-[100px] h-[40px] bg-green-600 text-white rounded-[13px]">{playtwoAlert.confirmText}</button>
                        {playtwoAlert.dialog ? <button onClick={() => {
                            playtwoAlert.handleCancel()
                        }} className="font-[apple-bold] w-[100px] h-[40px] bg-red-800 text-white rounded-[13px]">{playtwoAlert.cancelText}</button> : null}
                    </div>
                </div>
            </div>
        </motion.div >
    )
}

export default Alert