import { collection, doc, getDoc, getDocs, query, where } from 'firebase/firestore'
import { useEffect, useState } from 'react'
import { VscTriangleDown } from 'react-icons/vsc'
import { db } from '../firebase'
import Loading from './loading-page.json'
import Lottie from 'lottie-react'
import { GrHistory } from "react-icons/gr";
import { useNavigate } from 'react-router-dom'

const History = () => {

    let navigate = useNavigate()

    let [dateModal, setDateModal] = useState(false)
    let [date, setDate] = useState([])
    let [select, setSelect] = useState([])
    let [histories, setHistories] = useState([])
    let [loading, setLoading] = useState(false)

    // console.log(select.value)


    let totalQuantity = histories.reduce((total, current) => {
        return total = total + current.quantity
    }, 0)


    const init = async () => {

        setLoading(true)

        let storage_user = JSON.parse(localStorage.getItem('userDetail'))

        let dateReturn = await getDoc(doc(db, 'date', '9Fkk3N5yG3EIrVGZYK72')).then((res) => {
            return res.data().dateall
        })

        setDate(dateReturn)
        setLoading(false)

        if (select && select.length > 0) {
            await getDocs(query(collection(db, 'history'), where('userId', '==', storage_user.docId), where('date', '==', select[0].value))).then((res) => {
                let payload = res.docs.map((item) => {
                    let data = {
                        ...item.data(),
                        historyId: item.id
                    }

                    return data
                })

                setHistories(payload)
            })
        }
        // console.log(storage_user)
    }


    useEffect(() => {
        init()
        // getDocs(query(collection(db, 'history'), where('')))
    }, [select])

    console.log(select)

    return (
        <div>

            <img onClick={() => {
                navigate(-1)
            }} className="rotate-[180deg] w-[50px] absolute top-[20px] left-[20px] z-[1]" src="./svgs/arrow_right.svg"></img>

            <div className={`w-[100%] h-[100%] fixed flex justify-center items-center z-[10] ${loading ? 'pointer-events-auto' : 'pointer-events-none'}`}>
                {loading ? <Lottie animationData={Loading} /> : null}
            </div>

            <div className="p-[10px]">

                <div className="flex bg-white p-[20px] rounded-[12px] flex-col items-center relative">

                    <div className="flex justify-center mb-[10px] items-center gap-[10px]">
                        <p className="font-medium text-[24px]">ประวัติการสั่งซื้อ</p>
                        <GrHistory />
                    </div>

                    <div onClick={() => {
                        setDateModal(!dateModal)
                    }} className="border-[1px] border-gray-300 w-[250px] rounded-[8px] h-[50px] flex justify-center items-center gap-[20px]" data-dropdown-toggle="dropdown">
                        <p className="font-medium text-gray-400">{select && select.length > 0 ? 'งวดที่ ' + select[0].display : '-เลือกวันที่จะดูประวัติ-'}</p>
                        {select && select.length > 0 ? <VscTriangleDown /> : null}
                    </div>

                    {dateModal ? <div id='dropdown' className='mt-[20px] absolute top-[60px] bg-slate-200 rounded-[12px] shadow-[0px_5px_5px_0px_rgba(0,0,0,0.15)]'>
                        <ul>
                            {date && date.length > 0 ? date.map((item, index) => {
                                return (
                                    <li onClick={() => {
                                        setSelect([{ display: item.display, value: item.value }])
                                        setDateModal(false)
                                    }} className={`w-[250px] h-[50px] mb-[10px] mt-[10px] flex justify-center items-center font-medium text-[18px] ${select && select.length > 0 ? item.value == select[0].value ? 'bg-slate-400 rounded-[12px] text-white' : null : null}`}>{item.display}</li>
                                )
                            }) : null}
                        </ul>
                    </div> : null}


                    {totalQuantity ? <div className='mt-[30px] w-[100%]'>
                        <p className='font-medium text-[20px] rounded-[12px]'>รวม : {totalQuantity} ใบ</p>
                    </div> : null}

                    <div className='mt-[0px] w-[100%]'>
                        {histories && histories.length > 0 ? histories.map((item, index) => {

                            return (
                                <div className='mt-[10px] bg-slate-200 p-[10px] rounded-[12px]'>
                                    <p className='font-medium text-[20px] '>{index + 1}. {item.userNumber} {item.quantity > 1 ? `(หวยชุด) ${item.quantity} ใบ` : null}</p>
                                    <div className='flex justify-between'>
                                        <p className='font-medium text-yellow-500'>{item.reward ? `${item.text} 🥳` : null}</p>
                                        <p className='font-medium text-green-600'>{item.reward ? `${Number(item.price).toFixed(0) * item.quantity} ฿` : null}</p>
                                    </div>
                                </div>
                            )
                        }) : <div className='justify-center items-center flex mt-[20px] flex-col'>
                            <p className='font-medium'>ไม่พบการสั่งซื้อ !</p>
                            <p className='font-medium'>หรือกรุณารอให้จบงวด</p>
                        </div>}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default History