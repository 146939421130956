import { useContext, useState, useEffect, useRef } from "react"
import { cartContext, context, expireCart } from "../App"
import { GetGoogleUserProfile } from "../methods/Authentication"
import { getLiff } from "../methods/Authentication"
import { useNavigate } from "react-router-dom"
import Alert from "../Components/Alert"
import { motion } from "framer-motion"
import { addDoc, collection, deleteDoc, deleteField, doc, getDoc, getDocs, query, updateDoc, where } from "firebase/firestore"
import { db, storage } from "../firebase"
import generatePayload from 'promptpay-qr'
import QrcodeGen from 'qrcode.react'
import qrcodeURL from 'qrcode'
import { saveAs } from 'file-saver'
import Lottie from "lottie-react";
import correct from './correct.json'
import wait from './wait.json'
import LotteryFooter from "../lottery/LotteryComponents/LotteryFooter"
import { LineNotifyConfig, expireCartTimeConfig, expirePaymentTimeConfig, maintenanceConfig } from "../config"
import axios from "axios"
import { uploadBytes, getDownloadURL, ref } from 'firebase/storage'
import { v1 as uuidv1 } from 'uuid'
import LotteryHeader from "../lottery/LotteryComponents/LotteryHeader"
import LoadingPage from './loading-page.json'
import { isMobile, BrowserView, MobileView, osName, engineName, useDeviceData } from 'react-device-detect'
import { GoTriangleRight, GoTriangleDown } from "react-icons/go";
import { LiaCameraSolid } from "react-icons/lia";
import { FaStar } from "react-icons/fa6"
import { IoMdImages } from "react-icons/io"
import { BiSolidDiscount } from "react-icons/bi";

const url_endpoint = "https://wanfah-server-production.up.railway.app"

// const url_endpoint = "http://127.0.0.1:3001"

// true จะเปิดการแจ้งเตือนไลน์
const line_notify = LineNotifyConfig
const maintenace = maintenanceConfig


const Header = ({ state }) => {

    let varients = {
        'skyblue': {
            borderColor: '#55BCE5'
        },
        'green': {
            borderColor: 'rgb(34 197 94)'
        }
    }


    return (
        <div>
            <div className="w-[80px] h-[80px]">

            </div>
            <div className="w-[100%] h-[100px] bg-gray-600">
                <div className="w-[100%] h-[100%] flex justify-center items-center gap-[30px]">
                    <div className="flex justify-center items-center flex-col gap-[5px]">
                        <motion.div variants={varients} animate={state >= 1 ? 'green' : 'skyblue'} className={`w-[50px] h-[50px] bg-white rounded-full border-4 border-[#55BCE5] flex justify-center items-center after:w-[34px] after:h-[3px] after:bg-white after:absolute after:right-[-37px] relative`}>
                            <img className="w-[25px]" src="./svgs/bag.svg"></img>
                        </motion.div>
                        <p className="text-white text-[12px] font-light">ตะกร้า</p>
                    </div>
                    <div className="flex justify-center items-center flex-col gap-[5px]">
                        <motion.div variants={varients} animate={state >= 2 ? 'green' : 'skyblue'} className="w-[50px] h-[50px] bg-white rounded-full border-4 border-[#55BCE5] flex justify-center items-center after:w-[33px] after:h-[3px] after:bg-white after:absolute after:right-[-37px] relative">
                            <img className="w-[25px]" src="./svgs/information.svg"></img>
                        </motion.div>
                        <p className="text-white text-[12px] font-light">กรอกข้อมูล</p>
                    </div>
                    <div className="flex justify-center items-center flex-col gap-[5px]">
                        <motion.div variants={varients} animate={state >= 3 ? 'green' : 'skyblue'} className="w-[50px] h-[50px] bg-white rounded-full border-4 border-[#55BCE5] flex justify-center items-center after:w-[30px] after:h-[3px] after:bg-white after:absolute after:right-[-34px] relative">
                            <img className="w-[25px]" src="./svgs/payment.svg"></img>
                        </motion.div>
                        <p className="text-white text-[12px] font-light">ชำระเงิน</p>
                    </div>
                    <div className="flex justify-center items-center flex-col gap-[5px]">
                        <motion.div variants={varients} animate={state >= 4 ? 'green' : 'skyblue'} className="w-[50px] h-[50px] bg-white rounded-full border-4 border-[#55BCE5] flex justify-center items-center">
                            <img className="w-[25px]" src="./svgs/check.svg"></img>
                        </motion.div>
                        <p className="text-white text-[12px] font-light">สำเร็จ</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

const StateChoice = ({ setState }) => {

    let { playtwoAlert } = useContext(context)
    let { cart } = useContext(cartContext)
    let navigate = useNavigate()

    let [selected, setSelected] = useState('whale')
    let RadioRef3 = useRef(null)
    let RadioRef2 = useRef(null)
    let RadioRef1 = useRef(null)
    let amountItem

    if (cart && cart.length > 0) {
        amountItem = cart.reduce((amount, item) => {
            return amount = amount + item.quantity
        }, 0)
    }

    const GoToState = (toState) => {
        setState(toState)
        localStorage.setItem('state', toState)
    }

    const variants = {
        selectedWay: {
            backgroundColor: '#0047AB',
            color: '#FFFFFF'
        },
        unSelect: {
            color: '#d1d5db'
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])


    return (
        <div className="p-[20px] max-[390px]:p-[10px] flex justify-center">
            <div className="w-[100%] p-[20px] bg-white rounded-[12px] flex flex-col justify-center items-center mb-[150px] border-b-[3px] border-b-blue-400 min-[550px]:w-[520px] min-[1080px]:w-[80%]">
                <p className="font-medium text-[20px]">กดทางเลือกที่ต้องการใช้บริการ</p>
                {selected ? <p className="font-light text-center">เลือก : {selected} ({selected == "whale" ? "ฝากไว้กับวาฬฟ้า" : selected == "ems" ? "จัดส่ง" : selected == "pickup" ? "มารับที่ร้าน" : null})</p> : <p className="font-light">วาฬฟ้ายินดีต้อนรับ</p>}

                <div className="border-[1px] mt-[10px] mb-[10px] w-[100%] border-slate-300"></div>

                <div className="mt-[20px] flex gap-[20px]">
                    <div>
                        <input ref={RadioRef1} onClick={(e) => {
                            if (e.target.value == selected) {
                                RadioRef1.current.checked = false
                                setSelected(null)
                            } else {
                                setSelected(e.target.value)
                            }
                        }} value="whale" id="choice-1" name="choice" type="radio" className="hidden peer"></input>
                        <label for="choice-1" className={`duration-[0.5s] pr-[100px] pb-[50px] border-[1px] rounded-[18px] border-gray-300 relative shadow-[0px_5px_5px_0px_rgba(0,0,0,0.15)] peer-checked:bg-blue-300 ${selected == 'whale' ? 'bg-blue-300' : ''}`}>
                            <img className="w-[80px] absolute left-1/2 top-1/2 translate-x-[-50%] translate-y-[-50%]" src="./imgs/logo.png"></img>
                        </label>
                    </div>
                    <div className="flex w-[200px] h-[100px] gap-[5px] flex-col">
                        <p className="text-left w-[150px] font-medium text-[15px]">ฝากลอตเตอรี่ที่ซื้อไว้กับวาฬฟ้า</p>
                        <p className="font-light text-[15px]">(ฟรีค่าบริการ)</p>
                    </div>
                </div>


                <div className="mt-[0px] flex gap-[20px]">
                    <div>
                        <input ref={RadioRef2} onClick={(e) => {
                            if (e.target.value == selected) {
                                RadioRef2.current.checked = false
                                setSelected(null)
                            } else {
                                setSelected(e.target.value)
                            }
                        }} value="ems" id="choice-2" name="choice" type="radio" className="hidden peer"></input>
                        <label for="choice-2" className="duration-[0.5s] pr-[100px] pb-[50px] border-[1px] rounded-[18px] border-gray-300 relative shadow-[0px_5px_5px_0px_rgba(0,0,0,0.15)] peer-checked:bg-blue-300">
                            <img className="w-[80px] absolute left-1/2 top-1/2 translate-x-[-50%] translate-y-[-50%]" src="./imgs/ems.png"></img>
                        </label>
                    </div>
                    <div className="flex w-[200px] h-[100px] gap-[5px] flex-col">
                        <p className="w-[150px] text-left font-medium text-[15px]">จัดส่งลอตเตอรี่ที่ซื้อไว้ด้วยบริการขนส่ง</p>
                        <p className="font-light text-[15px]">(มีค่าส่ง)</p>
                    </div>
                </div>


                {/* <div className="mt-[0px] flex gap-[20px]">
                    <div>
                        <input ref={RadioRef3} onClick={(e) => {
                            if (e.target.value == selected) {
                                RadioRef3.current.checked = false
                                setSelected(null)
                            } else {
                                setSelected(e.target.value)
                            }
                        }} value="pickup" id="choice-3" name="choice" type="radio" className="hidden peer"></input>
                        <label for="choice-3" className="duration-[0.5s] pr-[100px] pb-[50px] border-[1px] rounded-[18px] border-gray-300 relative shadow-[0px_5px_5px_0px_rgba(0,0,0,0.15)] peer-checked:bg-blue-300">
                            <img className="w-[60px] absolute left-1/2 top-1/2 translate-x-[-50%] translate-y-[-50%]" src="./svgs/store.svg"></img>
                        </label>
                    </div>
                    <div className="flex w-[200px] h-[100px] gap-[5px] flex-col">
                        <p className="text-left w-[150px] font-medium text-[15px]">มารับลอตเตอรี่ที่ซื้อไว้ที่สำนักงานวาฬฟ้า</p>
                        <p><p className="font-light text-[15px]">(ฟรีค่าบริการ)</p></p>
                    </div>
                </div> */}

                <div>
                    <p className="font-light text-center">{cart && cart.length > 0 ? `มีสินค้าอยู่ในตะกร้า ${amountItem} ชิ้น` : `ไม่พบสินค้าในตะกร้า`}</p>
                </div>

                <motion.div onClick={() => {
                    if (cart && cart.length > 0) {
                        if (selected && selected != null) {
                            GoToState(1)
                        } else {
                            playtwoAlert.fire({ open: true, confirmText: 'เข้าใจแล้ว', dialog: false, title: 'กรุณาเลือกบริการที่ต้องการ' }, () => {
                                console.log("success")
                            })
                        }
                    } else {
                        playtwoAlert.fire({ open: true, confirmText: 'เข้าใจแล้ว', dialog: false, title: 'กรุณาหยิบสินค้าใส่ตะกร้า' }, () => {
                            console.log("success")
                            navigate("/")
                            localStorage.setItem('bottom', 'home')
                        })
                    }

                    if (selected && cart.length > 0) {
                        localStorage.setItem('way', selected)
                    }
                }} className="w-[150px] h-[50px] flex justify-center items-center rounded-[12px] mt-[20px] bg-blue-400 cursor-pointer">
                    <p className="font-medium text-white">ดำเนินการต่อ</p>
                </motion.div>

            </div>
        </div>
    )
}

const RecapCart = ({ setState, state }) => {

    let navigate = useNavigate()

    let { cart, setCart } = useContext(cartContext)
    let { clearTimeCart, setMin, setSec } = useContext(expireCart)
    let { playtwoAlert } = useContext(context)

    let [confirm, setConfirm] = useState(false)
    let [code, setCode] = useState('')
    let [hasDiscount, setHasDiscount] = useState(null)
    let [useCode, setUseCode] = useState(false)
    let [storageCode, setStorageCode] = useState(null)

    let [useAlready, setUseAlready] = useState(null)

    let [codeLoading, setCodeLoading] = useState(false)

    let [donate, setDonate] = useState(0)
    let total
    let amount
    let realDonate
    let totalQuantity

    let way = localStorage.getItem('way')
    let user_storage = JSON.parse(localStorage.getItem('userDetail'))

    if (cart && cart.length > 0) {
        total = cart.reduce((total_temp, current) => {
            total_temp = total_temp + (current.quantity * current.price)
            return total_temp
        }, 0)

        amount = cart.reduce((amount_total, current) => {
            return amount_total = amount_total + current.quantity
        }, 0)

        totalQuantity = cart.reduce((qauntityTotal, item) => {
            return qauntityTotal = qauntityTotal + item.quantity
        }, 0)
    }

    const DeleteProductOnCart = async (lotteryId) => {

        let userStorage = localStorage.getItem('userDetail')
        let userJson = JSON.parse(userStorage)

        const updatedCart = cart.filter((item) => {
            return item.lotteryId != lotteryId
        })

        // Make Lottery Lock be false
        await updateDoc(doc(db, 'lottery', lotteryId), {
            lock: false
        })

        if (cart.length == 1 || cart.length == 0) {
            await clearTimeCart()
        }

        localStorage.setItem('cart', JSON.stringify(updatedCart))
        setCart(updatedCart)
    }

    const GoToState = (toState) => {
        setState(toState)
        localStorage.setItem('state', toState)
    }

    let [dataDiscount, setDataDiscount] = useState(null)

    const CheckDiscount = async () => {
        let discounts = await getDocs(query(collection(db, 'discounts'), where("code", '==', code))).then((res) => {
            let data = res.docs.map((item) => {
                return {
                    ...item.data(),
                    codeId: item.id
                }
            })

            return data
        })

        setDataDiscount(discounts)


        if (user_storage) {
            if (discounts && discounts.length > 0) {
                let data = discounts.map((item) => {
                    let found = item.users_use.filter((items) => {
                        return items.userId == user_storage.docId
                    })

                    return found
                    // return item.userId == user_storage.docId
                })

                if (data[0][0]) {
                    setUseAlready(data[0][0])
                } else {

                    clearInterval(intervalId)
                    setUseAlready(null)
                    setShowTime(false)

                    if (discounts && discounts.length > 0 && discounts[0].current < discounts[0].limit) {
                        localStorage.setItem("discount", JSON.stringify(discounts))
                    }


                    setHasDiscount(discounts)
                }
            }
        } else {
            if (discounts && discounts.length > 0 && discounts[0].current < discounts[0].limit) {
                localStorage.setItem("discount", JSON.stringify(discounts))
            }


            setHasDiscount(discounts)
        }

    }

    const variants = {
        confirm: {
            backgroundColor: '#1f2937'
        },
        openImg: {
            opacity: 1
        },
        closeImg: {
            opacity: 0
        },
        changeBg: {
            backgroundColor: '#60a5fa',
            color: 'white'
        }
    }

    let [codeHours, setCodeHours] = useState(null)
    let [codeMin, setCodeMin] = useState(null)
    let [codeSec, setCodeSec] = useState(null)
    let [showTime, setShowTime] = useState(false)

    let [intervalId, setIntervalId] = useState(null)


    useEffect(() => {
        // window.scrollTo(-100, -100);

        let discount_storage = JSON.parse(localStorage.getItem('discount'))

        if (discount_storage && discount_storage.length > 0) {
            setUseCode(true)
            setHasDiscount(discount_storage)
            setCode(discount_storage[0].code)
        }


        if (useAlready) {
            setCodeLoading(true)

            clearInterval(intervalId)

            let IV = setInterval(() => {
                let current = new Date().getTime()
                let timestampDaily = useAlready.timestampDaily

                let diff = timestampDaily - current

                if (diff <= 0) {

                    let updated = dataDiscount[0].users_use.filter((item) => {
                        return item.userId != user_storage.docId
                    })

                    updateDoc(doc(db, 'discounts', dataDiscount[0].codeId), {
                        users_use: updated
                    })

                    playtwoAlert.fire({ open: true, title: `รีเซ็ตโค้ดเรียบร้อย กดใช้อีกครั้งเพื่อใช้งาน`, confirmText: 'เข้าใจแล้ว' }, () => {
                        console.log("Ok")
                    })

                    clearInterval(IV)
                    clearInterval(intervalId)
                    setIntervalId(null)

                } else {
                    let days = Math.floor(diff / (1000 * 60 * 60 * 24))
                    let hours = Math.floor(diff / (1000 * 60 * 60))
                    let minutes = Math.floor(diff / (1000 * 60)) % 60
                    let seconds = Math.floor(diff / (1000)) % 60

                    setCodeHours(hours)
                    setCodeMin(minutes)
                    setCodeSec(seconds)
                    setShowTime(true)
                    setCodeLoading(true)
                }

                setCodeLoading(false)

            }, 1000)

            setIntervalId(IV)
        }

    }, [useAlready])

    return (
        <div className="p-[10px]">
            {/* {cart && cart.length > 0 ? <table className="w-[100%] table-auto">
                <thead className="font-medium text-black">
                    <th>หมายเลข</th>
                    <th>จำนวน</th>
                    <th>ราคา</th>
                    <th>ค่าบริการ</th>
                    <th>รวม</th>
                </thead>
                <tbody className="font-light   text-black">
                    {cart && cart.length > 0 ? cart.map((item) => {
                        return (
                            <tr className="text-center">
                                <td onClick={() => {
                                    DeleteProductOnCart(item.lotteryId)
                                }}>{item.number}</td>
                                <td>{item.quantity}</td>
                                <td>{item.price * item.quantity}</td>
                                <td>18</td>
                                <td>{(item.quantity * item.price) + service_price}</td>
                            </tr>
                        )
                    }) : null}
                    {cart && cart.length > 0 ? <tr className="text-center">
                        <td>รวมทั้งหมด</td>
                        <td>{amount}</td>
                        <td></td>
                        <td></td>
                        <td>{total}</td>
                    </tr> : null}
                </tbody>
            </table> : null} */}

            <div className="flex justify-center">
                {cart && cart.length > 0 ? <div className="w-[100%] bg-white border-b-[4px] border-b-blue-400 p-[20px] rounded-[12px] sm:flex sm:flex-justify-center sm:flex-col sm:items-center min-[500px]:w-[500px] min-[1080px]:w-[70%]">
                    <div className="w-[100%] flex gap-[10px] flex-col border-[1px] border-[rgba(0,0,0,0.25)] p-[20px] rounded-[12px] shadow-[0px_5px_5px_0px_rgba(0,0,0,0.25)] sm:w-[30%] lg:w-[100%] lg:h-[400px] 2xl:w-[50%] min-[1080px]:h-[300px]">
                        <p className="text-center font-medium text-[20px]">ตะกร้าใส่ลอตเตอรี่</p>
                        <div className="flex overflow-y-scroll gap-[10px] shadow-[0px_2px_5px_0px_rgba(0,0,0,0.25)] rounded-[12px] lg:h-[100%] min-[1080px]:h-[300px]">
                            {cart && cart.length > 0 ? cart.map((item) => {
                                return (
                                    <img className="w-[400px] h-[150px] lg:w-[100%] lg:h-[100%] 2xl:w-[100%] min-[1080px]:h-[95%]" src={item.img}></img>
                                )
                            }) : null}
                        </div>
                    </div>
                    <div className="w-[100%] sm:w-[30%] border-[1px] border-[rgba(0,0,0,0.25)] rounded-[12px] p-[20px] mt-[20px] lg:w-[100%] 2xl:w-[50%]">
                        {/* <p className="font-light text-[25px] text-center">รางวัลสูงสุด X บาท</p> */}
                        <p className="font-medium mb-[10px] text-center text-[20px]">รายการลอตเตอรี่</p>
                        <div className="flex flex-col gap-[10px] lg:text-[25px]">
                            {cart && cart.length > 0 ? cart.map((item) => {

                                return (
                                    <div className="flex justify-between items-center">
                                        <div className="flex items-center gap-[10px]">
                                            <img onClick={() => {
                                                DeleteProductOnCart(item.lotteryId)
                                            }} src="./svgs/cross.svg" className="w-[35px]"></img>
                                            <div className="flex gap-[20px]">
                                                <p className="font-medium">{item.number}</p>
                                                <p className="font-light text-gray-400">x {item.quantity}</p>
                                            </div>
                                        </div>
                                        <p className="font-medium">{item.quantity * item.price} บาท</p>
                                    </div>
                                )
                            }) : null}
                        </div>
                        <div className="flex justify-between items-center mt-[20px]">
                            <p className="font-light">ค่าบริการ</p>
                            <p className="font-light">ฟรี</p>
                        </div>
                        {way == 'ems' ? <div className="flex justify-between items-center">
                            <p className="font-light">ค่าส่ง</p>
                            <p className="font-light">50 บาท</p>
                        </div> : null}
                        {/* {donate ? <div className="flex justify-between items-center">
                        <p className="font-light">บริจาค {donate} บาท (1 ใบ)</p>
                        <p className="font-light">{donate * totalQuantity} บาท</p>
                    </div> : null} */}
                        {/* <div className="flex justify-between mb-[20px]">
                        <p className="font-medium">ยอดรวมทั้งสิ้น</p>
                        <p>{totalQuantity}</p>
                        <p className="font-medium">{way == 'ems' ? total + (totalQuantity * 5) + 50 : total + (totalQuantity * 5)} บาท</p>
                    </div> */}

                        <div className="flex justify-between items-center mb-[20px]">
                            <p className="font-light">ค่าตู้เซฟ {maintenace} บาท (ต่อ 1 ใบ)</p>
                            {/* <p className="font-light text-gray-400 text-[14px]">{maintenace} x {totalQuantity}</p> */}
                            <p className="font-light">{maintenace * totalQuantity} บาท</p>
                        </div>

                        <div onClick={() => {
                            setConfirm(!confirm)
                        }} className="relative w-[100%] bg-green-600 h-[50px] rounded-[8px] text-white flex justify-center items-center gap-[10px]">
                            <motion.div variants={variants} animate={confirm ? 'confirm' : ''} className="w-[30px] h-[30px] bg-gray-100 rounded-[8px] flex justify-center items-center">
                                <motion.img variants={variants} animate={confirm ? 'openImg' : 'closeImg'} className="w-[20px]" src="./svgs/correct.svg"></motion.img>
                            </motion.div>
                            <p className="font-light">ข้าพเจ้ายินดีจ่ายค่าตู้เซฟ</p>

                            {confirm ? null : <FaStar className="w-[25px] h-[25px] absolute right-2" color="gold" />}
                        </div>

                        <div className="w-full h-[40px] border-[1px] border-gray-400 mt-[20px] relative">
                            <input onChange={(e) => {
                                setCode(e.target.value)
                                if (e.target.value.length == 0) {
                                    setUseCode(false)
                                    setHasDiscount([])
                                    localStorage.removeItem('discount')
                                }
                            }} className="w-full h-full font-[medium] text-center" value={code ? code : ''} placeholder="โค้ดส่วนลด"></input>
                            {code && hasDiscount && hasDiscount.length > 0 ? <p onClick={() => {
                                setUseCode(false)
                                setHasDiscount([])
                                localStorage.removeItem('discount')
                            }} className={`font-[medium] absolute right-[10px] top-[50%] translate-y-[-50%] ${code ? 'text-blue-400' : 'text-gray-400'}`}>ลบโค้ด</p> : <p onClick={() => {
                                CheckDiscount()
                                setUseCode(true)
                            }} className={`font-[medium] absolute right-[10px] top-[50%] translate-y-[-50%] ${code ? 'text-blue-400' : 'text-gray-400'}`}>ใช้โค้ด</p>}
                        </div>

                        {useCode ? hasDiscount && hasDiscount.length > 0 ? hasDiscount[0].current < hasDiscount[0].limit ? <div className="flex gap-[5px] items-center mt-[10px]">
                            <p className="font-[medium] text-green-600">ใช้โค้ดได้</p>
                            <BiSolidDiscount size={20} />
                        </div> : <p className="font-[medium] text-red-600 mt-[10px]">โค้ดหมดโควต้า</p> : codeLoading ? <p className="font-[light] mt-[10px]">กำลังโหลด...</p> : showTime ? null : <p className="font-[medium] text-red-600 mt-[10px]">ไม่พบโค้ด</p> : null}

                        {showTime ? <p className="font-[light] mt-[10px]">สามารถใช้โค้ดนี้ได้อีก : {codeHours}:{codeMin}:{codeSec.toString().length == 1 ? '0' + codeSec : codeSec}</p> : null}

                        <div className="mt-[20px]">
                            <div className="flex justify-between font-medium text-[20px]">
                                <p>ยอดรวมทั้งหมด</p>
                                <p className={`${hasDiscount && hasDiscount.length > 0 ? hasDiscount[0].current < hasDiscount[0].limit ? 'line-through text-red-600' : '' : ''}`}>{way == 'ems' ? ((Number(total) + (maintenace * totalQuantity)) + 50) : (Number(total) + (maintenace * totalQuantity))} บาท</p>
                            </div>


                            {hasDiscount && hasDiscount.length > 0 && hasDiscount[0].current < hasDiscount[0].limit ? <div className="flex justify-between font-medium text-[20px]">
                                <p></p>
                                <p>{way == 'ems' ? ((Number(total) + (maintenace * totalQuantity)) + 50) : (Number(total) + (maintenace * totalQuantity)) - hasDiscount[0].reduce} บาท</p>
                            </div> : null}

                            <motion.button disabled={confirm ? false : true} onClick={() => {
                                if (confirm) {
                                    // localStorage.setItem("donate", donate)
                                    GoToState(2)
                                } else {
                                    playtwoAlert.fire({ open: true, title: `กรุณากดยอมรับค่าตู้เซฟ`, confirmText: 'เข้าใจแล้ว' }, () => {
                                        console.log("Ok")
                                    })
                                }
                            }} className={`relative p-[10px] w-[100%] ${confirm ? 'bg-blue-400' : 'bg-gray-300'} duration-[0.2s] rounded-[8px] text-white font-medium mt-[10px] text-[18px]`}>
                                <p>ชำระเงิน</p>
                                {confirm ? <FaStar className="w-[25px] h-[25px] absolute right-2 top-2" color="gold" /> : null}
                            </motion.button>

                            {/* <div className="flex justify-center items-center mt-[20px] gap-[20px]">
                                <button className="p-[10px] bg-yellow-500 text-white rounded-[8px] font-medium" onClick={() => {
                                    navigate('/')
                                }}>ซื้อเพิ่ม</button>
                                <button className="p-[10px] bg-red-700 text-white rounded-[8px] font-medium" onClick={() => {

                                    GoToState(0)
                                }}>เปลี่ยนทางเลือก</button>
                            </div> */}
                        </div>
                    </div>
                </div> : null}
            </div>

            {cart && cart.length <= 0 ? <div className="w-[100%] h-[60vh] flex justify-center items-center">
                <p className="font-medium">ไม่มีสินค้าในตะกร้า</p>
            </div> : null}

            <div className="h-[20vh]">

            </div>
        </div>
    )
}

const InformationState = ({ setState }) => {

    let { cart, setCart } = useContext(cartContext)
    let { playtwoAlert } = useContext(context)
    let { clearTimeCart } = useContext(expireCart)
    let navigate = useNavigate()

    let [name, setName] = useState('')
    let [lastName, setLastName] = useState('')
    let [numberPhone, setNumberPhone] = useState('')
    let [loading, setLoading] = useState(false)

    let [modalNumpad, setModalNumpad] = useState(false)

    let [addRef, setAddRef] = useState(false)

    let tu_storage = localStorage.getItem('tu') == 'true'

    let user_cookie = JSON.parse(localStorage.getItem('userDetail'))
    let discount_storage = JSON.parse(localStorage.getItem("discount"))
    let [code, setCode] = useState('')

    let totalQuantity
    // let [editName, setEditName] = useState('')
    // let [editLastName, setEditLastName] = useState('')
    // let [editNumberPhone, setEditNumberPhone] = useState('')

    if (cart && cart.length > 0) {
        totalQuantity = cart.reduce((total, current) => {
            return total = total + current.quantity
        }, 0)
    }

    const init = async () => {
        let userStorage = await localStorage.getItem('userDetail')

        let userJson = JSON.parse(userStorage)

        if (userJson) {
            let userData = await getDoc(doc(db, 'users', userJson.docId))

            console.log(userData)

            if (userData.data()) {
                setName(userData.data().name)
                setLastName(userData.data().lastName)
                setNumberPhone(userData.data().numberPhone)
            }
        }

    }

    const GoToState = (toState) => {
        setState(toState)
        localStorage.setItem('state', toState)
    }

    const updateViewer = async () => {
        let viewer_count = await getDoc(doc(db, 'statistic', 'kD8uSLN2ns70qWSeP304')).then((res) => {
            let data = {
                ...res.data(),
                statisticId: res.id
            }

            return data.viewer_count
        })

        updateDoc(doc(db, 'statistic', 'kD8uSLN2ns70qWSeP304'), {
            viewer_count: viewer_count + 1
        })
    }

    const placeOrder = async (code) => {
        if (numberPhone.length == 10) {
            setLoading(true)
            setTimeout(() => {
                setLoading(false)
            }, 3000)
            if (cart && cart.length > 0) {
                let way = localStorage.getItem('way')
                let expireTimePayment = expirePaymentTimeConfig
                let timestampEnd = new Date().getTime() + (expireTimePayment * 60 * 1000)
                // let donate = Number(localStorage.getItem('donate'))

                let data = await getDocs(query(collection(db, 'users'), where("numberPhone", "==", numberPhone)))
                let userData = data.docs.map((item) => { return { ...item.data(), userId: item.id } })

                const AddUser = () => {
                    return new Promise(async (resolve, reject) => {
                        let payload = {
                            name: name,
                            lastName: lastName,
                            numberPhone: numberPhone,
                            owner_ref: code ? code : ''
                        }

                        await addDoc(collection(db, 'users'), payload).then((res) => {
                            if (res.id) {
                                localStorage.setItem('userDetail', JSON.stringify({ docId: res.id, name: name }))
                                resolve(res.id)
                            }
                        })
                    })
                }

                const getLotterySameCart = () => {
                    return new Promise(async (resolve, reject) => {
                        let something = []
                        await cart.map(async (item, index) => {
                            let dat = await getDoc(doc(db, 'lottery', item.lotteryId)).then((res) => {
                                return res.data()
                            })

                            something.push(dat)

                            if (something.length == cart.length) {
                                resolve(something)
                            }
                            // setCal_array(qwer)
                        })

                    })
                }


                // use Discount
                if (discount_storage && discount_storage.length > 0) {

                    let discountCode = await getDoc(doc(db, 'discounts', discount_storage[0].codeId)).then((res) => {
                        let data = {
                            ...res.data(),
                            codeId: res.id
                        }

                        return data
                    })

                    console.log(userData)

                    let found = discountCode.users_use.filter((item) => {
                        return userData && userData.length > 0 ? item.userId == userData[0].userId : null
                    })

                    console.log(found)


                    if (found && found.length > 0) {

                        console.log("763 : ", found)

                        let timeEnd = found[0].timestampDaily
                        let timeNow = new Date().getTime()

                        let diff = timeEnd - timeNow

                        if (diff <= 0) {
                            console.log("ลบ ละ กดใหม่")
                            let arrayDiscount = discountCode.users_use.filter((item) => {
                                return item.userId != found[0].userId
                            })

                            updateDoc(doc(db, 'discounts', discountCode.codeId), {
                                users_use: arrayDiscount
                            }).then(() => {
                                playtwoAlert.fire({ open: true, title: 'รีเซ็ตเรียบร้อย กรุณากดดำเนินการต่ออีกครั้ง', dialog: false, confirmText: 'เข้าใจแล้ว' }, () => {
                                    console.log("Ok")
                                })
                            })

                        } else {
                            localStorage.removeItem("discount")
                            GoToState(1)
                            playtwoAlert.fire({ open: true, title: 'คุณใช้โค้ดนี้ไปแล้ว', dialog: false, confirmText: 'เข้าใจแล้ว' }, () => {
                                console.log("Ok")
                            })
                        }
                    } else {
                        // if discount not reach limit
                        if (discountCode.current < discountCode.limit) {

                            // Have userId
                            if (userData && userData.length > 0) {

                                // Get Cart from database same User Cart

                                let cartLottery = await getLotterySameCart()

                                // Check Where lottery has been lock
                                let hasLocked = cartLottery.filter((item) => {
                                    return item.lock == true
                                })

                                if (hasLocked.length <= 0) {
                                    // PlaceOrder And Go State 3
                                    // PlaceOrder(userData)
                                    let totalMoneyPlusServices = cart.reduce((total, item) => {
                                        return total = total + (item.quantity * item.price)
                                    }, 0)

                                    let QRCodeReturn = await generatePayload('0997826654', { amount: way == 'ems' ? ((totalMoneyPlusServices + (totalQuantity * maintenace)) + 50) : discount_storage && discount_storage.length > 0 ? (totalMoneyPlusServices + (totalQuantity * maintenace)) - discount_storage[0].reduce : totalMoneyPlusServices + (totalQuantity * maintenace) })

                                    let QRCodeURLRETURN = await qrcodeURL.toDataURL(QRCodeReturn)

                                    console.log("uSERID : ", userData[0].userId)

                                    if (userData[0].name == '' && userData[0].lastName == '' && name && lastName) {
                                        await updateDoc(doc(db, 'users', userData[0].userId), {
                                            name: name,
                                            lastName: lastName
                                        })
                                    }

                                    let payload = {
                                        cart: cart,
                                        timestampEnd: timestampEnd,
                                        name: userData[0].name,
                                        userId: userData[0].userId,
                                        numberPhone: numberPhone,
                                        qrcode: QRCodeURLRETURN,
                                        confirm_slip: false,
                                        payment_success: false,
                                        discount: found && found.length > 0 ? {} : discountCode,
                                        // donate: donate,
                                        way: way == 'ems' ? 'ems' : 'others'
                                    }

                                    await addDoc(collection(db, 'orders'), payload).then((res) => {
                                        if (res.id) {
                                            localStorage.setItem('logged', 1)
                                            // Guide
                                            // MySelf
                                            updateDoc(doc(db, 'users', userData[0].userId), {
                                                orderId: res.id,
                                                // point: userData[0].point ? userData[0].point + (totalQuantity * 3) : totalQuantity * 3
                                            }).then(async () => {

                                                // Have Reference From Others people invited
                                                // if (userData && userData.length > 0 && userData[0].owner_ref) {

                                                //     let ref_own = await getDoc(doc(db, 'users', userData[0].owner_ref))

                                                //     let ref_data = ref_own.data()

                                                //     updateDoc(doc(db, 'users', userData[0].owner_ref), {
                                                //         point: ref_data.point ? ref_data.point + (totalQuantity * 2) : totalQuantity * 2
                                                //     })
                                                // }

                                                // make all lottery that have in cart be ture in database
                                                cart.map((item) => {
                                                    updateDoc(doc(db, 'lottery', item.lotteryId), {
                                                        lock: true
                                                    })
                                                })

                                                let updated = []

                                                let payload = {
                                                    userId: userData[0].userId,
                                                    name: userData[0].name,
                                                    timestampDaily: new Date().getTime() + (1000 * 60 * 60 * 24)
                                                }

                                                if (discountCode.users_use && discountCode.users_use.length > 0) {

                                                    let same = discountCode.users_use.filter((item) => {
                                                        return item.userId == userData[0].userId
                                                    })

                                                    console.log("SAME : ", same)

                                                    if (same && same.length > 0) {
                                                        let daily = same[0].timestampDaily
                                                        let current = new Date().getTime()

                                                        let diff = daily - current

                                                        if (diff <= 0) {
                                                            updated = discountCode.users_use.map((item) => {
                                                                return {
                                                                    ...item,
                                                                    timestampDaily: item.userId == userData[0].userId ? new Date().getTime() + (1000 * 60 * 60 * 24) : item.timestampDaily
                                                                }
                                                            })
                                                        } else {
                                                            console.log("ยังไม่ครบเวลา")
                                                            localStorage.removeItem("discount")
                                                        }
                                                    } else {
                                                        updated = [...discountCode.users_use, payload]

                                                        updateDoc(doc(db, 'discounts', discountCode.codeId), {
                                                            current: discountCode.current + 1,
                                                            users_use: updated
                                                        })
                                                    }

                                                    console.log("UPDATED : ", updated)

                                                } else {
                                                    updated = [payload]

                                                    console.log("UPDATED 2 : ", updated)

                                                    updateDoc(doc(db, 'discounts', discountCode.codeId), {
                                                        current: discountCode.current + 1,
                                                        users_use: updated
                                                    })

                                                }

                                                updateViewer()



                                                console.log("วางคำสั่งซื้อสำเร็จ")
                                                clearTimeCart()
                                                console.log(res.id)
                                                localStorage.setItem('userDetail', JSON.stringify({ docId: userData[0].userId, name: userData[0].name }))
                                                GoToState(3)
                                            })
                                        }
                                    })
                                } else {
                                    localStorage.setItem('cart', [])
                                    setCart([])
                                    playtwoAlert.fire({ open: true, title: `ลอตเตอรี่ถูกซื้อไปเเล้ว`, confirmText: 'เข้าใจแล้ว' }, () => {
                                        navigate("/")
                                        clearTimeCart()
                                        GoToState(0)
                                    })
                                }

                            } else {
                                console.log("nope")

                                // Get Cart from database same User Cart

                                let cartLottery = await getLotterySameCart()

                                // Check Where lottery has been lock
                                let hasLocked = cartLottery.filter((item) => {
                                    return item.lock == true
                                })

                                console.log(hasLocked)

                                // if lottery has been locking == 0 Can Order

                                if (hasLocked.length <= 0) {
                                    // Add User Id And Place Order also
                                    // console.log("1 == 2")

                                    let ReturnDocId = await AddUser() //Id Of User Have just Added

                                    let totalMoneyPlusServices = cart.reduce((total, item) => {
                                        return total = total + (item.quantity * item.price)
                                    }, 0)

                                    let QRCodeReturn = await generatePayload('0997826654', { amount: way == 'ems' ? ((totalMoneyPlusServices + (totalQuantity * maintenace)) + 50) : discount_storage && discount_storage.length > 0 ? (totalMoneyPlusServices + (totalQuantity * maintenace)) - discount_storage[0].reduce : totalMoneyPlusServices + (totalQuantity * maintenace) })

                                    let QRCodeURLRETURN = await qrcodeURL.toDataURL(QRCodeReturn)

                                    let payload = {
                                        cart: cart,
                                        timestampEnd: timestampEnd,
                                        name: name,
                                        userId: ReturnDocId,
                                        numberPhone: numberPhone,
                                        qrcode: QRCodeURLRETURN,
                                        confirm_slip: false,
                                        payment_success: false,
                                        discount: discountCode ? discountCode : {},
                                        // donate: donate,
                                        way: way == 'ems' ? 'ems' : 'others'
                                    }

                                    await addDoc(collection(db, 'orders'), payload).then(async (res) => {
                                        if (res.id) {
                                            localStorage.setItem('logged', 1)

                                            // Update User point
                                            await updateDoc(doc(db, 'users', ReturnDocId), {
                                                orderId: res.id,
                                                // point: userData[0].point ? userData[0].point + (totalQuantity * 3) : totalQuantity * 3
                                            }).then(async () => {


                                                // Update Point targetRef
                                                // if (userData && userData.length > 0 && userData[0].owner_ref) {

                                                //     let ref_own = await getDoc(doc(db, 'users', userData[0].owner_ref))

                                                //     let ref_data = ref_own.data()

                                                //     updateDoc(doc(db, 'users', userData[0].owner_ref), {
                                                //         point: ref_data.point ? ref_data.point + (totalQuantity * 2) : totalQuantity * 2
                                                //     })
                                                // }

                                                cart.map(async (item) => {
                                                    await updateDoc(doc(db, 'lottery', item.lotteryId), {
                                                        lock: true
                                                    })
                                                })


                                                let userDoc = await getDoc(doc(db, 'users', ReturnDocId)).then((res) => {
                                                    return {
                                                        ...res.data(),
                                                        userId: res.id
                                                    }
                                                })

                                                let updated = []

                                                console.log("NEW USER USE CODE")

                                                let payload = {
                                                    userId: ReturnDocId,
                                                    name: userDoc.userId,
                                                    timestampDaily: new Date().getTime() + (1000 * 60 * 60 * 24)
                                                }

                                                if (discountCode.users_use && discountCode.users_use.length > 0) {

                                                    let same = discountCode.users_use.filter((item) => {
                                                        return item.userId == userDoc.userId
                                                    })

                                                    console.log("SAME : ", same)

                                                    if (same && same.length > 0) {
                                                        updated = discountCode.users_use.map((item) => {
                                                            return {
                                                                ...item,
                                                                timestampDaily: new Date().getTime() + (1000 * 60 * 60 * 24)
                                                            }
                                                        })
                                                    } else {
                                                        updated = [...discountCode.users_use, payload]
                                                    }

                                                    console.log("UPDATED : ", updated)

                                                } else {
                                                    updated = [payload]

                                                    console.log("UPDATED 2 : ", updated)

                                                }

                                                updateDoc(doc(db, 'discounts', discountCode.codeId), {
                                                    current: discountCode.current + 1,
                                                    users_use: updated
                                                })

                                                updateViewer()

                                                console.log("วางคำสั่งซื้อสำเร็จ")
                                                clearTimeCart()
                                                GoToState(3)
                                            })
                                        }
                                    })

                                } else {
                                    localStorage.setItem('cart', [])
                                    setCart([])
                                    playtwoAlert.fire({ open: true, title: `ลอตเตอรี่ถูกซื้อไปเเล้ว`, confirmText: 'เข้าใจแล้ว' }, () => {
                                        navigate("/")
                                        clearTimeCart()
                                        GoToState(0)
                                    })
                                }
                            }

                        } else {
                            playtwoAlert.fire({ open: true, title: `โค้ดหมดแล้ว`, confirmText: 'เข้าใจแล้ว' }, () => {
                                console.log("code expire")
                            })
                            GoToState(1)
                            localStorage.removeItem('discount')
                        }
                    }

                } else {
                    // Have userId
                    if (userData && userData.length > 0) {

                        // Get Cart from database same User Cart

                        let cartLottery = await getLotterySameCart()

                        // Check Where lottery has been lock
                        let hasLocked = cartLottery.filter((item) => {
                            return item.lock == true
                        })

                        if (hasLocked.length <= 0) {
                            // PlaceOrder And Go State 3
                            // PlaceOrder(userData)
                            let totalMoneyPlusServices = cart.reduce((total, item) => {
                                return total = total + (item.quantity * item.price)
                            }, 0)

                            let QRCodeReturn = await generatePayload('0997826654', { amount: way == 'ems' ? ((totalMoneyPlusServices + (totalQuantity * maintenace)) + 50) : discount_storage && discount_storage.length > 0 ? (totalMoneyPlusServices + (totalQuantity * maintenace)) - discount_storage[0].reduce : totalMoneyPlusServices + (totalQuantity * maintenace) })

                            let QRCodeURLRETURN = await qrcodeURL.toDataURL(QRCodeReturn)

                            console.log("uSERID : ", userData[0].userId)

                            if (userData[0].name == '' && userData[0].lastName == '' && name && lastName) {
                                await updateDoc(doc(db, 'users', userData[0].userId), {
                                    name: name,
                                    lastName: lastName
                                })
                            }


                            let payload = {
                                cart: cart,
                                timestampEnd: timestampEnd,
                                name: userData[0].name,
                                userId: userData[0].userId,
                                numberPhone: numberPhone,
                                qrcode: QRCodeURLRETURN,
                                confirm_slip: false,
                                payment_success: false,
                                // donate: donate,
                                way: way == 'ems' ? 'ems' : 'others'
                            }

                            await addDoc(collection(db, 'orders'), payload).then((res) => {
                                if (res.id) {
                                    localStorage.setItem('logged', 1)
                                    // Guide
                                    // MySelf
                                    updateDoc(doc(db, 'users', userData[0].userId), {
                                        orderId: res.id,
                                        // point: userData[0].point ? userData[0].point + (totalQuantity * 3) : totalQuantity * 3
                                    }).then(async () => {

                                        // Have Reference From Others people invited
                                        // if (userData && userData.length > 0 && userData[0].owner_ref) {

                                        //     let ref_own = await getDoc(doc(db, 'users', userData[0].owner_ref))

                                        //     let ref_data = ref_own.data()

                                        //     updateDoc(doc(db, 'users', userData[0].owner_ref), {
                                        //         point: ref_data.point ? ref_data.point + (totalQuantity * 2) : totalQuantity * 2
                                        //     })
                                        // }

                                        // make all lottery that have in cart be ture in database
                                        cart.map((item) => {
                                            updateDoc(doc(db, 'lottery', item.lotteryId), {
                                                lock: true
                                            })
                                        })

                                        updateViewer()

                                        console.log("วางคำสั่งซื้อสำเร็จ")
                                        clearTimeCart()
                                        console.log(res.id)
                                        localStorage.setItem('userDetail', JSON.stringify({ docId: userData[0].userId, name: userData[0].name }))
                                        GoToState(3)
                                    })
                                }
                            })
                        } else {
                            localStorage.setItem('cart', [])
                            setCart([])
                            playtwoAlert.fire({ open: true, title: `ลอตเตอรี่ถูกซื้อไปเเล้ว`, confirmText: 'เข้าใจแล้ว' }, () => {
                                navigate("/")
                                clearTimeCart()
                                GoToState(0)
                            })
                        }

                    } else {
                        console.log("nope")

                        // Get Cart from database same User Cart

                        let cartLottery = await getLotterySameCart()

                        // Check Where lottery has been lock
                        let hasLocked = cartLottery.filter((item) => {
                            return item.lock == true
                        })

                        console.log(hasLocked)

                        // if lottery has been locking == 0 Can Order

                        if (hasLocked.length <= 0) {
                            // Add User Id And Place Order also
                            // console.log("1 == 2")

                            let ReturnDocId = await AddUser() //Id Of User Have just Added

                            let totalMoneyPlusServices = cart.reduce((total, item) => {
                                return total = total + (item.quantity * item.price)
                            }, 0)

                            let QRCodeReturn = await generatePayload('0997826654', { amount: way == 'ems' ? ((totalMoneyPlusServices + (totalQuantity * maintenace)) + 50) : discount_storage && discount_storage.length > 0 ? (totalMoneyPlusServices + (totalQuantity * maintenace)) - discount_storage[0].reduce : totalMoneyPlusServices + (totalQuantity * maintenace) })

                            let QRCodeURLRETURN = await qrcodeURL.toDataURL(QRCodeReturn)

                            let payload = {
                                cart: cart,
                                timestampEnd: timestampEnd,
                                name: name,
                                userId: ReturnDocId,
                                numberPhone: numberPhone,
                                qrcode: QRCodeURLRETURN,
                                confirm_slip: false,
                                payment_success: false,
                                // donate: donate,
                                way: way == 'ems' ? 'ems' : 'others'
                            }

                            await addDoc(collection(db, 'orders'), payload).then(async (res) => {
                                if (res.id) {
                                    localStorage.setItem('logged', 1)

                                    // Update User point
                                    await updateDoc(doc(db, 'users', ReturnDocId), {
                                        orderId: res.id,
                                        // point: userData[0].point ? userData[0].point + (totalQuantity * 3) : totalQuantity * 3
                                    }).then(async () => {


                                        // Update Point targetRef
                                        // if (userData && userData.length > 0 && userData[0].owner_ref) {

                                        //     let ref_own = await getDoc(doc(db, 'users', userData[0].owner_ref))

                                        //     let ref_data = ref_own.data()

                                        //     updateDoc(doc(db, 'users', userData[0].owner_ref), {
                                        //         point: ref_data.point ? ref_data.point + (totalQuantity * 2) : totalQuantity * 2
                                        //     })
                                        // }

                                        cart.map(async (item) => {
                                            await updateDoc(doc(db, 'lottery', item.lotteryId), {
                                                lock: true
                                            })
                                        })

                                        updateViewer()

                                        console.log("วางคำสั่งซื้อสำเร็จ")
                                        clearTimeCart()
                                        GoToState(3)
                                    })
                                }
                            })

                        } else {
                            localStorage.setItem('cart', [])
                            setCart([])
                            playtwoAlert.fire({ open: true, title: `ลอตเตอรี่ถูกซื้อไปเเล้ว`, confirmText: 'เข้าใจแล้ว' }, () => {
                                navigate("/")
                                clearTimeCart()
                                GoToState(0)
                            })
                        }
                    }
                }

            } else {
                playtwoAlert.fire({ open: true, confirmText: 'เข้าใจแล้ว', dialog: false, title: 'ลอตเตอรี่ไม่มีในตะกร้า' }, () => {
                    console.log("ok")
                })
            }

        } else {
            playtwoAlert.fire({ open: true, confirmText: 'เข้าใจแล้ว', dialog: false, title: 'กรุณาตรวจสอบเบอร์โทรศัพท์อีกครั้ง' }, () => {
                console.log("ok")
            })
        }
    }

    useEffect(() => {
        window.scrollTo(-100, -100)

        init()
    }, [])

    return (
        <div>
            {loading ? <div className="w-[100%] h-[60%] fixed flex justify-center items-center">
                <Lottie className="w-[50%]" animationData={LoadingPage} />
            </div> : null}

            <div onClick={(e) => {
                if (e.target == e.currentTarget) {
                    setModalNumpad(false)
                }
            }} className="p-[20px] flex flex-col justify-center items-center max-[390px]:p-[10px]">


                <div className="w-[100%] 2xl:w-[500px] sm:w-[500px] bg-white p-[20px] rounded-[20px] flex justify-center flex-col lg:text-[24px] min-[500px]:w-[450px] min-[1080px]:mt-[100px]">
                    <p className="font-medium text-[20px]">กรอกข้อมูลเพื่อยืนยันตัวตน</p>
                    <p className="font-light text-[14px]">เมื่อเสร็จสิ้นระบบจะทำการสร้างบัญชี และ เข้าสู่ระบบแบบ อัตโนมัติ</p>
                    <p className="font-light text-[14px] mt-[20px] text-red-500">* กรุณากรอกข้อมูลจริง *</p>
                    {tu_storage == true ? null : <div className="flex gap-[20px] mt-[20px] w-[100%]">
                        <div className="flex gap-[10px] flex-col">

                            <p className="font-light whitespace-nowrap">ชื่อจริง (ไม่มีคำนำหน้า)</p>
                            <input value={name} onChange={(e) => {
                                setName(e.target.value)
                            }} required className="w-[100%] h-[50px] rounded-[4px] border-b-[1.5px] outline-none border-blue-400 font-light indent-4" placeholder="กรอกชื่อ"></input>
                        </div>
                        <div className="flex gap-[10px] flex-col">
                            <p className="font-light">นามสกุล</p>
                            <input value={lastName} onChange={(e) => {
                                setLastName(e.target.value)
                            }} required className="w-[100%] h-[50px] rounded-[8px] border-b-[1.5px] outline-none border-blue-400 font-light indent-4" placeholder="กรอกนามสกุล"></input>
                        </div>
                    </div>}
                    <div className="flex gap-[10px] flex-col">
                        <div className="flex gap-[20px] items-center mt-[20px]">
                            <p className="font-light">เบอร์โทรศัพท์</p>
                            {numberPhone.length > 0 ? numberPhone.length == 10 ? <p className="font-light text-green-600 text-[14px]">(เบอร์โทรศัพท์ถูกต้อง)</p> : <p className="font-light text-red-600 text-[14px]">(เบอร์โทรศัพท์ยังไม่ถูกต้อง)</p> : null}
                        </div>
                        <input onClick={(e) => {
                            setModalNumpad(true)
                        }} type="number" max={10} value={numberPhone} onChange={(e) => {
                            let text = e.target.value.substring(0, 10)
                            setNumberPhone(text)
                        }} className="w-[100%] h-[50px] rounded-[8px] border-b-[1.5px] border-blue-400  font-light text-center text-[20px] outline-none" placeholder="กรอกเบอร์โทรศัพท์"></input>
                    </div>

                    {user_cookie ? null : <div className="flex flex-col mt-[10px]">
                        <div onClick={() => {
                            setAddRef(!addRef)
                        }} className="flex cursor-pointer">
                            {!addRef ? <GoTriangleRight size={25} /> : <GoTriangleDown size={25} />}
                            <p className="font-light">รหัสแนะนำเพื่อน (ไม่จำเป็นต้องใส่)</p>
                        </div>
                        {addRef ? <div className="gap-[10px] flex-col">
                            <div className="flex gap-[20px] items-center mt-[20px]">
                                <p className="font-light">รหัสเชิญเพื่อน หรือ เบอร์โทรศัพท์</p>
                            </div>
                            <input onClick={(e) => {
                                setModalNumpad(true)
                            }} max={10} value={code} onChange={(e) => {
                                setCode(e.target.value)
                            }} className="w-[100%] h-[50px] rounded-[8px] border-b-[1.5px] border-blue-400  font-light text-center text-[20px] outline-none" placeholder="กรอกโค้ด"></input>
                        </div> : null}
                    </div>}


                </div>

                <div className="flex gap-[20px] mt-[20px]">


                    <button className="p-[10px] bg-orange-400 text-white font-medium rounded-[8px]" onClick={() => {
                        GoToState(1)
                    }}>ย้อนกลับ</button>


                    <div className="relative w-[150px]">
                        <FaStar className="w-[25px] h-[25px] absolute right-2 top-[50%] translate-y-[-50%]" color="gold" />

                        <button className="w-[100%] p-[10px] bg-blue-400 text-white font-medium rounded-[8px] text-start indent-3" onClick={async () => {


                            if (code) {
                                let numberRef = /^\d{10}$/
                                if (numberRef.test(code)) {
                                    // Find Numberphone document
                                    let ReturnUser = await getDocs(query(collection(db, 'users'), where('numberPhone', '==', code))).then((res) => {
                                        let data = res.docs.map((item) => {
                                            return {
                                                ...item.data(),
                                                userId: item.id
                                            }
                                        })


                                        return data
                                    })


                                    if (ReturnUser && ReturnUser.length > 0) {
                                        if (numberPhone.length == 10) {
                                            placeOrder(code)
                                        } else {
                                            playtwoAlert.fire({ open: true, title: 'กรุณาตรวจสอบเบอร์โทร', dialog: false, confirmText: 'เข้าใจแล้ว' }, () => {
                                                console.log("Ok")
                                            })
                                        }
                                    } else {
                                        playtwoAlert.fire({ open: true, title: 'ไม่พบเบอร์ที่แนะนำ', dialog: false, confirmText: 'เข้าใจแล้ว' }, () => {
                                            console.log("Ok")
                                        })
                                    }
                                } else {
                                    // Find with userId document


                                    let userReferral = await getDoc(doc(db, 'users', code)).then((snapshot) => {
                                        let data = {
                                            ...snapshot.data(),
                                            userId: snapshot.id
                                        }

                                        return data
                                    })

                                    if (userReferral && userReferral.numberPhone) {
                                        placeOrder(code)
                                    } else {
                                        playtwoAlert.fire({ open: true, title: 'ไม่พบโทเคนที่แนะนำ', dialog: false, confirmText: 'เข้าใจแล้ว' }, () => {
                                            console.log("Ok")
                                        })
                                    }
                                }
                            } else {
                                placeOrder()
                            }


                        }}>{loading ? 'กำลังดำเนินการ....' : 'ดำเนินการต่อ'}</button>
                    </div>

                </div>

                {/* {modalNumpad && tu_storage ? <Numpad setNumberPhone={setNumberPhone} numberPhone={numberPhone}/> : null} */}

                <div className="h-[20vh]">

                </div>
            </div>
        </div>
    )
}

const PaymentState = ({ setState }) => {

    let navigate = useNavigate()

    let { playtwoAlert } = useContext(context)

    let { cart, setCart } = useContext(cartContext)
    let [userData, setUserData] = useState([])

    let [timeDiff, setTimeDiff] = useState(null)
    let [qrcode, setQrcode] = useState(null)

    let [intervalId, setIntervalId] = useState(null)

    let [promptpay, setPromptpay] = useState('')

    let way = localStorage.getItem('way')


    let donate = Number(localStorage.getItem('donate'))
    let discount = JSON.parse(localStorage.getItem('discount'))
    let total
    let amount

    const GoToState = (toState) => {
        setState(toState)
        localStorage.setItem('state', toState)
    }

    if (cart && cart.length > 0) {

        amount = cart.reduce((amountTotal, current) => {
            return amountTotal = (amountTotal + current.quantity)
        }, 0)

        total = cart.reduce((total_temp, current) => {
            total_temp = total_temp + (current.quantity * current.price)
            return total_temp
        }, 0)
    }


    const DeleteProductOnCart = (docId) => {
        const updatedCart = cart.filter((item) => {
            return item.docId != docId
        })

        localStorage.setItem('cart', updatedCart)
        setCart(updatedCart)
    }

    const StopCountDown = () => {
        clearInterval(intervalId)
        clearInterval(intervalId - 1)
        setIntervalId(null)
    }

    let [orders, setOrders] = useState([])
    let [loading, setLoading] = useState(false)


    const init = async () => {

        // let totalMoney = cart.reduce((total, item) => {
        //     return total = total + (item.quantity * item.price)
        // }, 0)

        // let nameStorage = await localStorage.getItem('name')


        let userStorage = await localStorage.getItem('userDetail')
        let convert = JSON.parse(userStorage)
        console.log(convert)

        let userOrdering = await getDoc(doc(db, 'users', convert.docId)).then((res) => {
            let payload = {
                ...res.data(),
                userId: res.id
            }

            getDoc(doc(db, 'orders', payload.orderId)).then((snap) => {
                if (snap.data()) {



                    setUserData(payload)
                    setOrders({ ...snap.data(), orderId: snap.id })
                } else {
                    updateDoc(doc(db, 'users', payload.userId), {
                        orderId: deleteField()
                    }).then(() => {
                        playtwoAlert.fire({ open: true, confirmText: 'เข้าใจแล้ว', dialog: false, title: 'หมดเวลาคำสั่งซื้อ' }, async () => {
                            console.log({ status: 200 })
                        })
                        setCart([])
                        localStorage.setItem('cart', [])
                        GoToState(0)
                        localStorage.setItem('bottom', 'home')
                        localStorage.removeItem('discount')
                        navigate('/')
                    })
                }
            })

            return payload
        })

        // console.log(userOrdering)

        // if (!userOrdering.orderId) {
        //     setCart([])
        //     localStorage.setItem('cart', [])
        //     GoToState(0)
        //     window.location.href = '/'
        //     localStorage.setItem('bottom', 'home')
        // } else {

        // }

        if (userOrdering) {
            console.log("Come")
            console.log(userOrdering)
            await getDoc(doc(db, 'orders', userOrdering.orderId)).then((snapshot) => {
                let data = snapshot.data()

                if (data) {
                    console.log(data)

                    setQrcode(data.qrcode)

                    const CountDown = () => {

                        console.log("Before Interval : ", intervalId)

                        clearInterval(intervalId)

                        console.log("After Interval : ", intervalId)

                        let UpdateTime = setInterval(async () => {

                            console.log("working")

                            console.log("Time Update : ", UpdateTime)

                            if (data.payment_success) {
                                clearInterval(UpdateTime)
                            } else {
                                let timeStampNow = new Date().getTime()
                                let milisecDiff = data.timestampEnd - timeStampNow
                                let DateDestination = new Date(milisecDiff)
                                let secDiff = DateDestination.getSeconds().toFixed(0).toString()
                                let minDiff = DateDestination.getMinutes().toFixed(0).toString()

                                if (milisecDiff <= 0) {

                                    setLoading(true)


                                    // Unlock lottery to buy

                                    if (userOrdering) {

                                        await getDoc(doc(db, 'orders', userOrdering.orderId)).then(async (res) => {

                                            let payload = {
                                                ...res.data(),
                                                orderId: res.id
                                            }

                                            // Return all Lottery

                                            console.log("Payload : ", payload)

                                            if (payload && payload.cart) {
                                                await payload.cart.map((item) => {
                                                    updateDoc(doc(db, 'lottery', item.lotteryId), {
                                                        lock: false
                                                    })
                                                    console.log("Return Lottery : ", item.lotteryId)
                                                })
                                            }

                                        })
                                    }

                                    await updateDoc(doc(db, 'users', userOrdering.userId), {
                                        orderId: deleteField(),
                                    })

                                    // Delete Order that Created
                                    await deleteDoc(doc(db, 'orders', userOrdering.orderId))

                                    console.log("Intervaling")

                                    playtwoAlert.fire({ open: true, confirmText: 'เข้าใจแล้ว', dialog: false, title: 'หมดเวลาคำสั่งซื้อ' }, async () => {
                                        setLoading(false)
                                        console.log({ status: 200 })
                                    })

                                    setCart([])
                                    navigate('/')
                                    GoToState(0)
                                    localStorage.setItem('cart', [])
                                    localStorage.setItem('bottom', 'home')
                                    localStorage.removeItem("discount")

                                    clearInterval(UpdateTime)
                                } else {

                                    let objectTime = {
                                        sec: secDiff,
                                        min: minDiff
                                    }

                                    setTimeDiff(objectTime)
                                }
                            }
                        }, 1000)

                        setIntervalId(UpdateTime)

                        console.log("Interval : ", UpdateTime)
                    }
                    CountDown()
                } else {

                }
            })
        }
    }

    console.log(timeDiff)

    let [img, setImg] = useState(null)
    let [base64Show, setBase64Show] = useState('')

    let [isTu, setIsTu] = useState(false)

    let [paymentLoading, setPaymentLoading] = useState(false)
    let [banking, setBanking] = useState('')

    let [select, setSelect] = useState('pay-qr')

    let [showslip, setShowSlip] = useState(false)

    let discount_storage = JSON.parse(localStorage.getItem("discount"))


    const HandleExit = () => {
        setCart([])
        GoToState(0)
        localStorage.setItem('cart', [])
        localStorage.setItem('refresh', 0)
    }

    const rowRef = useRef(null)


    useEffect(() => {

        window.scrollTo(0, 0)

        setIsTu(localStorage.getItem('tu') == 'true')

        // let refresh = Number(localStorage.getItem('refresh'))

        // if (!refresh) {
        //     window.location.reload(true)
        //     localStorage.setItem('refresh', 1)
        // } else {

        // }

        init()


        // window.addEventListener('beforeunload', HandleExit)

        // return () => {
        //     window.addEventListener('beforeunload', HandleExit)
        // }
    }, [])

    return (
        <div className="overflow-hidden">

            {/* <Alert /> */}

            {paymentLoading ? <div className="w-[100%] h-[60%] fixed flex justify-center items-center z-[999]">
                <Lottie animationData={LoadingPage} />
            </div> : null}

            {loading ? <div className="w-[100%] h-[60%] fixed flex flex-col justify-center items-center z-[999]">
                <Lottie animationData={LoadingPage} />
                <p className="font-light">โปรดรอซักครู่...</p>
            </div> : null}

            <div className="flex justify-center items-center flex-col p-[10px]">
                <p className="font-medium text-[20px] mb-[10px] lg:text-[40px]">การชำระเงิน</p>
                <p className="font-medium">สวัสดี! คุณ {userData ? userData.name ? userData.name : userData.numberPhone : null}</p>
                <p className="text-center font-light">การสั่งซื้อจะเสร็จสมบูรณ์หลังจากที่ระบบได้รับการชำระเงินและอนุมัติคำสั่งซื้อแล้ว</p>
                <table className="w-[100%] mt-[20px] mb-[20px]">
                    <thead className="font-medium border-slate-300 border-b-[2px]">
                        <th className="p-[5px]">หมายเลข</th>
                        <th>จำนวน</th>
                        <th>ราคา</th>
                        <th>ค่าตู้เซฟ</th>
                        <th>รวม</th>
                    </thead>
                    <tbody className="font-light lg:text-[20px]">
                        {cart && cart.length > 0 ? cart.map((item) => {
                            return (
                                <tr className="text-center">
                                    <td>{item.number}</td>
                                    <td>{item.quantity}</td>
                                    <td>{item.price * item.quantity}</td>
                                    <td>{maintenace * item.quantity}</td>
                                    <td>{(item.quantity * item.price) + (maintenace * item.quantity)}</td>
                                </tr>
                            )
                        }) : null}

                        {way == 'ems' ? <tr className="text-center">
                            <td>ค่าส่ง</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>50</td>
                        </tr> : null}


                        {discount && discount.length > 0 ? <tr className="text-center">
                            <td className="pt-[8px]">ส่วนลด</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>{discount[0].reduce}</td>
                        </tr> : null}

                        {cart && cart.length > 0 ? <tr className="text-center">
                            <td className="pt-[8px]">รวมทั้งหมด</td>
                            <td>{amount}</td>
                            <td></td>
                            <td></td>
                            <td>{way == 'ems' ? (total + (amount * maintenace)) + 50 : discount && discount.length > 0 ? (total + (amount * maintenace) - discount[0].reduce) : total + (amount * maintenace)}</td>
                        </tr> : null}
                    </tbody>
                </table>

                <p className="text-center font-medium mb-[5px]">เพื่อยืนยันคำสั่งซื้อให้เสร็จสมบูรณ์กรุณาชำระเงินและยืนยันการชำระเงินภายใน</p>

                {userData && timeDiff ? <p className="text-[25px] font-[medium] mb-[10px]">{timeDiff.min.length <= 1 ? `0${timeDiff.min}` : `${timeDiff.min}`}:{timeDiff.sec.length <= 1 ? `0${timeDiff.sec}` : `${timeDiff.sec}`}  </p> : null}

                <p className="text-center text-red-500 font-light">หากไม่ยืนยันการชำระเงินภายใน 15 นาที ระบบจะยกเลิกคำสั่งซื้อ ลอตเตอรี่จะถูกนำไปจำหน่ายในเว็บไซต์ทันที</p>

                <div className="w-[100%] h-[50px] lg:h-[60px] bg-white mt-[20px] mb-[10px] grid grid-cols-2 justify-center items-center">
                    <div onClick={() => {
                        setSelect('pay-qr')
                    }} className={`flex justify-center items-center h-[100%] w-[100%] ${select == 'pay-qr' ? 'bg-blue-600 text-white' : ''}`}>
                        <p className="font-medium lg:text-[24px]">สแกนคิวอาร์โค้ด</p>
                    </div>
                    <div onClick={() => {
                        setSelect('pay-number')
                    }} className={`flex items-center h-[100%] w-[100%] justify-center ${select == 'pay-number' ? 'bg-blue-600 text-white' : ''}`}>
                        <p className="font-medium lg:text-[24px]">โอนเงินเลขบัญชี</p>
                    </div>
                </div>

                {select == 'pay-qr' ? <div className="w-[300px] bg-white h-[40px] rounded-[8px] flex justify-between pl-[10px] pr-[20px] items-center mt-[10px] min-[1080px]:w-[500px] min-[1080px]:text-[24px] min-[1080px]:h-[60px]">
                    <p className="font-medium text-gray-400">ชื่อบัญชี</p>
                    <p className="font-medium">กรรณิกา สมยานะ</p>
                </div> : null}

                {select == 'pay-qr' ? <div>
                    <div className="w-[300px] h-[400px] lg:w-[500px] lg:h-[600px] relative mt-[20px] lg:mt-[40px] min-[1080px]:h-[600px] min-[1080px]:w-[500px]">
                        <img className="w-[100%] h-[100%]" src={'./imgs/qrborder.png'}></img>
                        <img className="absolute left-1/2 top-1/2 translate-x-[-50%] translate-y-[-54%] w-[60%]" src={qrcode}></img>
                        <p className="absolute right-[23px] font-light  bottom-[25px]">{way == 'ems' ? (total + (maintenace * amount)) + 50 : discount && discount.length > 0 ? total + (maintenace * amount) - discount[0].reduce : total + (maintenace * amount)} บาท</p>
                    </div>

                    <MobileView>
                        <div className="flex justify-center w-[100%]">
                            <button className="mt-[20px] p-[10px] bg-[#55BCE5] text-white rounded-[8px] font-medium" onClick={() => {
                                saveAs(qrcode, 'qrcode-whale.jpg')
                            }}>บันทึก QR Code</button>
                        </div>
                    </MobileView>

                </div> : null}


                {select == 'pay-number' ? <div className="w-[350px] lg:w-[510px] lg:h-[200px] bg-white mt-[20px] lg:mt-[40px] rounded-[12px] p-[20px] min-[1080px]:w-[500px]">
                    <div className="flex flex-col gap-[20px]">
                        <div className="flex justify-between items-center ">
                            <p className="font-medium text-gray-500">ธนาคาร</p>
                            <div className="flex items-center gap-[10px]">
                                <img className="rounded-[12px] w-[50px]" src="./imgs/ktb.png"></img>
                                <p className="font-medium text-[14px]">ธนาคารกรุงไทย</p>
                            </div>
                        </div>

                        <div className="flex justify-between items-center ">
                            <p className="font-medium text-gray-500">ชื่อบัญชี</p>
                            <div className="flex items-center gap-[10px]">
                                <p className="font-medium text-[14px]">กรรณิกา สมยานะ</p>
                            </div>
                        </div>

                        <div className="flex justify-between items-center ">
                            <p className="font-medium text-gray-500">เลขที่บัญชี</p>
                            <div className="flex items-center gap-[10px]">
                                <p className="font-medium text-[14px]">502-0-00911-3</p>
                                <button onClick={() => {
                                    navigator.clipboard.writeText("5020009113")
                                    setBanking("5020009113")
                                }} className={`p-[8px] ${!banking ? 'bg-blue-400' : 'bg-white'} border-blue-400 border-[3px] rounded-[8px] ${!banking ? 'text-white' : 'text-blue-400'} font-light`}>คัดลอก</button>
                            </div>
                        </div>


                        {/* <div className="border-b-[2px] border-gray-300 border-dashed">

                        </div> */}

                        {/* <div className="flex justify-between items-center ">
                            <p className="font-medium text-gray-500">พร้อมเพย์</p>
                            <div className="flex items-center gap-[10px]">
                                <img className="rounded-[4px] w-[50px]" src="./imgs/promptpay.png"></img>
                                <p className="font-medium text-[14px]">099-782-6654</p>
                                <button onClick={() => {
                                    navigator.clipboard.writeText("0997826654")
                                    setPromptpay('0997826654')
                                }} className={`p-[8px] ${!promptpay ? 'bg-blue-400' : 'bg-white'} border-blue-400 border-[3px] rounded-[8px] ${!promptpay ? 'text-white' : 'text-blue-400'} font-light`}>คัดลอก</button>
                            </div>
                        </div> */}
                    </div>
                </div> : null}


                {isTu ? <div className="mt-[40px] mb-[30px] bg-white p-[20px] w-[510px] flex justify-center items-center rounded-[12px] flex-col ">
                    <p className="font-medium text-[27px]">ซื้อผ่านตู้</p>
                    <p className="font-light text-[20px] mt-[10px] mb-[10px]">โอนเงินสำเร็จ กรุณาส่งสลิปผ่านไลน์ @ , โชว์ให้พนักงาน</p>


                    <img src="./sticker/linead.jpg" className="w-[200px] mb-[20px] mt-[10px]"></img>

                    <div onClick={() => {
                        setShowSlip(!showslip)
                    }} className="flex items-center gap-[20px]">
                        <div className={`w-[30px] h-[30px] border-2 border-gray-400 rounded-[8px] ${showslip ? 'bg-black' : null}`}>
                            <img className="w-[30px] h-[30px]" src="./svgs/correct.svg"></img>
                        </div>

                        <p className="font-light mt-[10px]">ส่งสลิปเรียบร้อย</p>
                    </div>

                </div> : null}


                {!isTu ? <label className={`${img ? 'w-[300px]' : 'w-[300px] h-[150px]'} relative border-[2px] border-b-4 border-b-blue-400  bg-white  mt-[30px] flex justify-center items-center flex-col rounded-[8px] p-[10px]`}>
                    <input onChange={(e) => {
                        setImg(e.target.files[0])
                        let reader = new FileReader()

                        reader.onload = (e) => {
                            setBase64Show(e.target.result)
                        }

                        reader.readAsDataURL(e.target.files[0])
                    }} type="file" className="w-[100%] h-[100%] hidden"></input>
                    {img ? <img className="mt-[50px] w-[100%] h-[100%]" src={base64Show}></img> : <IoMdImages size={35} />}
                    {img ? <p>{img.name}</p> : <div>
                        <p className="font-light text-[14px] mt-[10px]">อัพโหลดสลิปการโอนเงิน</p>
                        <p className="font-light text-[12px] text-center">กดตรงนี้</p>
                    </div>}
                    {base64Show ? null : <FaStar className="w-[20px] h-[20px] absolute right-2 top-0" color={'gold'} />}
                </label> : null}

                <div className="flex gap-[20px] mt-[20px]">

                    <button className="p-[10px] w-[100px] text-white bg-red-600 font-medium rounded-[8px]" onClick={() => {
                        playtwoAlert.fire({ open: true, dialog: true, confirmText: 'ลบคำสั่งซื้อ', cancelText: 'ยกเลิก', title: 'ลบคำสั่งซื้อ?' }, async () => {
                            let userStorage = await localStorage.getItem("userDetail")
                            let discount_storage = await JSON.parse(localStorage.getItem('discount'))
                            let convert = JSON.parse(userStorage)

                            userData = await getDoc(doc(db, 'users', convert.docId))
                            console.log("DELETE : ", userData.data())

                            // DeleteOrders(orders), DeleteOrderID(users)
                            await deleteDoc(doc(db, 'orders', userData.data().orderId))
                            await updateDoc(doc(db, 'users', convert.docId), {
                                orderId: ''
                            })

                            // Unlock Lottery
                            cart.map((item) => {
                                updateDoc(doc(db, 'lottery', item.lotteryId), {
                                    lock: false
                                })
                            })

                            if (discount_storage && discount_storage.length > 0) {
                                let discountData = await getDoc(doc(db, 'discounts', discount_storage[0].codeId)).then((res) => {
                                    return {
                                        ...res.data(),
                                        discountId: res.id
                                    }
                                })

                                let updated = discountData.users_use.filter((item)=>{
                                    return item.userId != convert.docId
                                })

                                updateDoc(doc(db, 'discounts', discountData.discountId), {
                                    current: discountData.current - 1,
                                    users_use: updated
                                })
                            }

                            clearInterval(intervalId)
                            setCart([])
                            localStorage.setItem('cart', [])
                            localStorage.removeItem("discount")
                            navigate('/')
                            GoToState(0)
                        })
                    }}>ยกเลิก</button>

                    <button disabled={paymentLoading ? true : false} className={`p-[10px] text-white ${paymentLoading ? 'bg-gray-600' : 'bg-green-600'} font-medium rounded-[8px]`} onClick={async () => {
                        if (!showslip && isTu) {
                            playtwoAlert.fire({ open: true, title: 'กรุณาโชว์สลิปให้พนักงาน', dialog: false, confirmText: 'เข้าใจแล้ว' }, () => {
                                console.log("Ok")
                            })
                        } else {
                            if (isTu) {
                                setPaymentLoading(true)
                                setTimeout(async () => {

                                    // Add Order To History, Update User OrderId, Delete Orders

                                    let userStorage = localStorage.getItem('userDetail')
                                    let userJson = JSON.parse(userStorage)

                                    let userDb = await getDoc(doc(db, 'users', userJson.docId)).then((res) => {
                                        let payload = {
                                            ...res.data(),
                                            userId: res.id
                                        }

                                        return payload
                                    })

                                    await updateDoc(doc(db, 'orders', userDb.orderId), {
                                        payment_success: true,
                                        timestampEnd: deleteField(),
                                        qrcode: deleteField()
                                    }).then(async () => {
                                        updateDoc(doc(db, 'users', userDb.userId), {
                                            orderId: deleteField()
                                        }).then(async () => {
                                            StopCountDown()

                                            // Open This to Send Line Notification to group
                                            // LINE

                                            if (line_notify) {
                                                console.log("Before Line : ", userData)

                                                try {
                                                    axios.post(`${process.env.REACT_APP_URI}/notify`, {
                                                        name: userData.name,
                                                        lastName: userData.lastName,
                                                        numberPhone: userData.numberPhone,
                                                        total: total,
                                                        // donate: donate,
                                                        discount: discount_storage,
                                                        way: 'tu',
                                                        cart: JSON.stringify(cart)
                                                    })
                                                } catch (err) {
                                                    console.log("Line Notify Api หมดในเดือนนี้")
                                                }
                                            } else {
                                                alert("Line Notify ถูกปิดการใช้งานจากผู้พัฒนา")
                                            }
                                        })

                                        setCart([])
                                        localStorage.setItem('cart', [])
                                        localStorage.removeItem('discount')
                                        GoToState(4)
                                        setPaymentLoading(false)
                                    })
                                }, 1500)
                            } else {
                                if (img) {

                                    console.log(img.name)

                                    const KeepSlipToDatabase = () => {
                                        return new Promise(async (resolve, reject) => {
                                            await uploadBytes(ref(storage, `slip_payment/${img.name.split('.')[0] + uuidv1() + img.name.split('.')[1]}}`), img).then(async (snapshot) => {
                                                console.log("SnapShot : ", snapshot)
                                                await getDownloadURL(snapshot.ref).then((url) => {
                                                    resolve(url)
                                                })
                                            })
                                        })
                                    }

                                    setPaymentLoading(true)
                                    setTimeout(async () => {

                                        // Add Order To History, Update User OrderId, Delete Orders

                                        let userStorage = localStorage.getItem('userDetail')
                                        let userJson = JSON.parse(userStorage)

                                        let userDb = await getDoc(doc(db, 'users', userJson.docId)).then((res) => {
                                            let payload = {
                                                ...res.data(),
                                                userId: res.id
                                            }

                                            return payload
                                        })

                                        await updateDoc(doc(db, 'orders', userDb.orderId), {
                                            payment_success: true,
                                            timestampEnd: deleteField(),
                                            qrcode: deleteField()
                                        }).then(async () => {
                                            updateDoc(doc(db, 'users', userDb.userId), {
                                                orderId: deleteField()
                                            }).then(async () => {
                                                StopCountDown()

                                                // Open This to Send Line Notification to group
                                                // LINE

                                                if (line_notify) {
                                                    console.log("Before Line : ", userData)
                                                    let slip_url = await KeepSlipToDatabase()

                                                    try {
                                                        axios.post(`${process.env.REACT_APP_URI}/notify`, {
                                                            name: userData.name,
                                                            lastName: userData.lastName,
                                                            numberPhone: userData.numberPhone,
                                                            slip_img: slip_url,
                                                            total: total,
                                                            // donate: donate,
                                                            way: 'others',
                                                            discount: discount_storage,
                                                            cart: JSON.stringify(cart)
                                                        })
                                                    } catch (err) {
                                                        console.log("Line Notify Api หมดในเดือนนี้")
                                                    }
                                                } else {
                                                    alert("Line Notify ถูกปิดการใช้งานจากผู้พัฒนา")
                                                }
                                            })

                                            setCart([])
                                            localStorage.setItem('cart', [])
                                            localStorage.removeItem('discount')
                                            GoToState(4)
                                            setPaymentLoading(false)
                                        })
                                    }, 1500)


                                } else {
                                    playtwoAlert.fire({ open: true, title: 'กรุณาอัพโหลดสลิปการจ่ายเงิน', dialog: false, confirmText: 'เข้าใจแล้ว' }, () => {
                                        console.log("Ok")
                                    })
                                }
                            }
                        }
                    }}><div className="flex gap-[10px]">
                            {paymentLoading == true ? 'กำลังดำเนินการ...' : 'ดำเนินการต่อ'}
                            {base64Show ? <FaStar className="w-[20px] h-[20px] " color={'gold'} /> : null}
                        </div></button>

                </div>
            </div>
            <div className="h-[20vh]"></div>
        </div>
    )
}

const SuccessState = ({ setState }) => {

    let navigate = useNavigate()
    let { cart, setCart } = useContext(cartContext)

    const GoToState = (toState) => {
        setState(toState)
        localStorage.setItem('state', toState)
        localStorage.setItem('bottom', 'inventory')
    }


    useEffect(() => {
        window.scroll(0, 0)
        setTimeout(() => {
            navigate('/inventory')
            localStorage.setItem('bottom', 'inventory')
            GoToState(0)
        }, 2500)
    }, [])

    return (
        <div className="w-[100%] h-[80vh] flex flex-col items-center min-[1080px]:justify-center">
            <img className="w-[300px] min-[1080px]:w-[500px]" src="/sticker/thank.png"></img>
            <p className="font-medium text-[28px]">รออนุมัติ</p>
            <button onClick={() => {
                navigate('/inventory')
                localStorage.setItem('bottom', 'inventory')
                GoToState(0)
            }} className="p-[10px] w-[150px] mt-[20px] bg-green-500 text-white rounded-[8px] font-light">ตกลง</button>
        </div>
    )
}


const Cart = () => {

    let [state, setState] = useState(1)
    let { cart, setCart } = useContext(cartContext)

    let [userProfile, setUserProfile] = useState([])

    const init = async () => {
        let app_type_storage = await localStorage.getItem('app_type')

        // RememberState

        let stateReturn = await localStorage.getItem('state')
        if (stateReturn) {
            setState(stateReturn)
        }
        console.log(app_type_storage)
        if (app_type_storage == "GOOGLE") {
            console.log("In")
            let userProfileReturn = await GetGoogleUserProfile()
            setUserProfile(userProfileReturn)
        } else if (app_type_storage == "LINE") {
            let userProfileReturn = await getLiff()
            setUserProfile(userProfileReturn)
        }
    }

    useEffect(() => {
        init()

        let bottom = localStorage.getItem('bottom')

        if (!bottom) {
            localStorage.setItem('bottom', 'cart')
        }
    }, [])

    return (
        <div>
            <Alert />
            <LotteryHeader title={"วาฬฟ้า"} />
            <Header state={state} />
            {state == 1 ? <RecapCart setState={setState} state={state} /> : state == 2 ? <InformationState setState={setState} /> : state == 3 ? <PaymentState setState={setState} /> : state == 4 ? <SuccessState setState={setState} /> : null}
            {state == 3 ? null : <LotteryFooter state={state} />}
        </div>
    )
}

export default Cart