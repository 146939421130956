import { useContext, useEffect, useRef, useState } from "react"
import ListLottery from "./ListLottery"
import { menuContext } from "../../App"
import { MdArrowCircleLeft } from "react-icons/md";
import { MdArrowCircleRight } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import Marquee from "react-fast-marquee";
import { FaStar } from "react-icons/fa6";
import { collection, doc, getDocs, onSnapshot, orderBy, query } from "firebase/firestore";
import { db } from "../../firebase";
import { FaUser } from "react-icons/fa";
import { MdSell } from "react-icons/md";
import { FaRegFileAlt } from "react-icons/fa";
import { GiTrophy } from "react-icons/gi";
import { motion } from 'framer-motion'

const FindLottery = ({ open, setOpen, status, setStatus, getLottery, setModalSiemSee }) => {

    let { setSidebarStatus, sidebarStatus } = useContext(menuContext)

    let navigate = useNavigate()

    const [find, setFind] = useState('')

    const [selection, setSelection] = useState('all')

    let [seachinput, setSearchInput] = useState('')

    let [all_viewer, setAllViewer] = useState(null)
    let [all_buy, setAllBuy] = useState(null)
    let [all_lottery, setAllLottery] = useState(null)

    let [users_win, setUsersWin] = useState([])

    const init = async () => {
        let win_streak = await getDocs(query(collection(db, 'users_total'), orderBy('win_streak', 'desc'))).then((snapshot) => {
            let data = snapshot.docs.map((item) => {
                return {
                    ...item.data(),
                    docId: item.id
                }
            })

            return data
        })

        onSnapshot(doc(db, 'statistic', 'kD8uSLN2ns70qWSeP304'), (snapshot) => {
            setAllBuy(snapshot.data().all_buy)
            setAllViewer(snapshot.data().viewer_count)
            setAllLottery(snapshot.data().all_lottery)
        })



        setUsersWin(win_streak)
    }

    let showBanners = [
        {
            id: 0,
            path: 'banner-star.png',
            open: () => {
                let user = localStorage.getItem("userDetail")
                if (user) {
                    navigate('/profile', { state: { info_pass: true } })
                } else {
                    navigate('/inventory', { state: { banner_pass: true } })
                }
            }
        },
        {
            id: 1,
            path: 'banner-siemsee.png',
            open: () => {
                setModalSiemSee(true)
            }
        },
        {
            id: 2,
            path: 'banner-easy.png',
        },
        {
            id: 3,
            path: 'banner_discount.png',
            open: () => {
                setModalDiscount(true)
            }
        }
    ]

    let [count, setCount] = useState(0)
    let [ranking, setRanking] = useState(false)
    let [modalDiscount, setModalDiscount] = useState(false)


    useEffect(() => {
        let intervalId = setInterval(() => {
            if ((showBanners.length - 1) == count) {
                setCount(0)
            } else {
                setCount(count + 1)
            }
        }, 1000 * 5)

        let discount_show = localStorage.getItem("discount_show") == 'true'
    
        if(!discount_show){
            setModalDiscount(true)
        }


        init()


        return () => clearInterval(intervalId)
    }, [count])

    return (
        <div onClick={(e) => {
            if (sidebarStatus) {
                if (e.target != e.currentTarget || e.target == e.currentTarget) {
                    setSidebarStatus(false)
                }
            }

        }} className="p-[20px] sm:flex sm:flex-col sm:justify-center sm:items-center justify-center items-center flex flex-col ">
            {/* <div className="w-[350px] h-[70px] bg-white rounded-[8px] mt-[80px]">
                <img className="w-[100%] h-[100%] object-cover rounded-[8px]" src="./imgs/banner.png"></img>
            </div> */}

            <motion.div onClick={(e) => {
                if (e.target == e.currentTarget) {
                    setRanking(false)
                }
            }} initial={{ opacity: 0 }} animate={ranking ? { opacity: 1 } : { opacity: 0 }} className={`${ranking ? 'pointer-events-auto' : 'pointer-events-none'} fixed top-0 left-0 bg-black/80 w-full h-full z-[20] flex justify-center items-center flex-col gap-[20px]`}>
                <div className="w-[300px] h-[200px] bg-white gap-[10px] rounded-[8px] overflow-y-scroll p-[20px]">
                    <div className="flex justify-center items-center gap-[10px] mb-[10px]">
                        <p className="font-[medium] text-[20px]">จัดอันดับคนถูกรางวัล </p>
                        <GiTrophy size={20} color="gold" />
                    </div>
                    {users_win && users_win.length > 0 ? users_win.map((item, index) => {
                        return (
                            <div className="flex justify-between">
                                {index == 0 ? <img className="w-[20px]" src="/imgs/gold.png"></img> : null}
                                {index == 1 ? <img className="w-[20px]" src="/imgs/silver.png"></img> : null}
                                {index == 2 ? <img className="w-[20px]" src="/imgs/bronze.png"></img> : null}
                                <p className={`font-[medium] text-center ${(index + 1) > 3 ? 'w-[20px]' : ''}`}>{(index + 1) > 3 ? `${index + 1}.` : null}</p>
                                <p className={`font-[medium] ${index + 1 > 3 ? 'w-[160px]' : 'w-[170px]'}`}>คุณ {item.name}</p>
                                <p className="font-[medium]">{item.win_streak} ใบ</p>
                            </div>
                        )
                    }) : null}
                </div>

                <div onClick={() => {
                    setRanking(false)
                }} className="w-[100px] h-[40px] bg-blue-400 rounded-[8px] flex justify-center items-center">
                    <p className="font-[medium] text-[24px] text-white">ปิด</p>
                </div>
            </motion.div>

            <motion.div onClick={()=>{
                localStorage.setItem("discount_show", true)
                setModalDiscount(false)
            }} initial={{ opacity: 0 }} animate={modalDiscount ? { opacity: 1 } : { opacity: 0 }} className={` ${modalDiscount ? 'pointer-events-auto' : 'pointer-events-none'} w-[100%] h-[100%] fixed left-0 top-0 bg-black/60 z-[20] flex justify-center items-center flex-col gap-[20px]`}>
                <div className="w-[300px] h-[150px] rounded-[8px]">
                    <img src={'/banners/banner_discount1.png'} className="w-full h-full rounded-[8px]"></img>
                </div>

                <div onClick={() => {
                    setModalDiscount(false)
                    localStorage.setItem("discount_show", true)
                }} className="w-[100px] h-[40px] bg-blue-400 rounded-[8px] flex justify-center items-center">
                    <p className="font-[medium] text-white text-[20px]">ปิด</p>
                </div>
            </motion.div>

            <div className="w-[100%] h-[50px] min-[1080px]:w-[60%] bg-[#2F9FCC] text-white rounded-[8px] mt-[80px] flex justify-center items-center shadow-[0px_2px_5px_0px_rgba(0,0,0,0.3)]">
                <p className="font-medium text-[30px]">วาฬฟ้า ลอตเตอรี่ ออนไลน์</p>
            </div>

            <div className="w-[100%] min-[1080px]:w-[60%] sm:w-[60%] h-[70px] bg-white rounded-[8px] mt-[10px] shadow-[0px_2px_5px_0px_rgba(0,0,0,0.3)] lg:w-[60%] lg:h-[80px] min-[700px]:h-[100px] relative">
                <img onClick={() => {
                    if (showBanners[count].open) {
                        showBanners[count].open()
                    }
                }} className="w-[100%] h-[100%] object-fill rounded-[8px]" src={`/banners/${showBanners[count].path}`}></img>
                <IoIosArrowBack onClick={() => {
                    if (count == (showBanners.length - 1)) {
                        setCount(count - 1)
                    } else {
                        setCount(showBanners.length - 1)
                    }
                }} className="absolute top-5 left-2 min-[1080px]:top-9" size={30} color="black" />
                <IoIosArrowForward onClick={() => {
                    if (count == (showBanners.length - 1)) {
                        setCount(0)
                    } else {
                        setCount(count + 1)
                    }
                }} className="absolute top-5 right-2 min-[1080px]:top-9" size={30} color="black" />
            </div>

            <div className={`grid grid-cols-4 w-[100%] gap-[10px] mt-[10px] min-[1080px]:w-[60%]`}>
                {showBanners.map((item, index) => {
                    return (
                        <div onClick={() => {
                            setCount(item.id)
                        }} className={`h-[7px] ${index == count ? 'bg-white' : 'bg-gray-400'} w-[100%] rounded-full`}>

                        </div>
                    )
                })}
            </div>

            <div onClick={() => {
                setRanking(true)
            }} className="w-[100%] h-[50px] bg-white mt-[10px] rounded-[12px] min-[1080px]:w-[60%] flex justify-center items-center">
                <Marquee pauseOnHover={true} speed={30} style={{ width: '90%' }}>
                    {users_win && users_win.length > 0 ? users_win.map((item, index) => {
                        return (
                            <div className={`flex gap-[5px] items-center ml-[20px]`}>
                                <p className="font-medium">{item.name} ถูกรางวัล {item.win_streak} ใบ</p>
                                <FaStar color="gold" />
                            </div>
                        )
                    }) : null}
                </Marquee>
            </div>

            <div className="w-[100%] min-[1080px]:w-[60%] sm:w-[60%] bg-white rounded-[12px] shadow-[0px_5px_5px_1px_rgba(0,0,0,0.15)] mt-[10px] items-center flex flex-col p-[20px]">

                <div className="flex gap-[10px] mb-[20px]">
                    <div className="w-[100px] h-[70px] border-[1px] border-gray-400 rounded-[8px] flex justify-center items-center flex-col shadow-[0px_5px_5px_0px_rgba(0,0,0,0.15)]">
                        <p className="font-light text-black text-[16px]">ผู้เข้าชม (คน)</p>


                        <div className="flex gap-[5px] items-center">
                            <p className="font-medium text-black text-[20px]">{all_viewer}</p>
                            <FaUser />
                        </div>
                    </div>

                    <div className="w-[100px] h-[70px] border-[1px] border-gray-400 rounded-[8px] flex justify-center items-center flex-col shadow-[0px_5px_5px_0px_rgba(0,0,0,0.15)]">
                        <p className="font-light text-black text-[16px]">สลากทั้งหมด</p>


                        <div className="flex gap-[5px] items-center">
                            <p className="font-medium text-black text-[20px]">{all_lottery}</p>
                            <FaRegFileAlt />
                        </div>
                    </div>

                    <div className="w-[100px] h-[70px] border-[1px] border-gray-400 rounded-[8px] flex justify-center items-center flex-col shadow-[0px_5px_5px_0px_rgba(0,0,0,0.15)]">
                        <p className="font-light text-black text-[16px]">ซื้อแล้ว (ใบ)</p>

                        <div className="flex gap-[5px] items-center">
                            <p className="font-medium text-black text-[20px]">{all_buy}</p>
                            <MdSell />
                        </div>
                    </div>
                </div>

                <div className="border-b-[1px] border-gray-400 w-[100%] h-[10px] mb-[20px]">

                </div>


                {/* <div className="w-[100%] rounded-[12px] flex justify-center items-center flex-col">
                    <p className="font-[medium] max-[390px]:text-[19px] text-[20px]">วาฬฟ้า สลากกินแบ่งรัฐบาลออนไลน์</p>
                    <div className="flex gap-[5px]">
                        <p className="font-[light] text-[14px] mb-[10px]">สามารถกรอกเลขเพื่อค้นหาลอตเตอรี่</p>
                        <img className="w-[20px]" src="./svgs/search.png"></img>
                    </div>
                </div> */}

                <input type="number" placeholder="กรอกตัวเลขเพื่อค้นหา..." maxLength={6} onChange={(e) => {
                    setFind(e.target.value)
                }} className="w-[80%] h-[50px] text-center border-[1px] rounded-[8px] border-gray-400 shadow-[0px_2px_5px_0px_rgba(0,0,0,0.3)] font-light placeholder:text-gray-500"></input>


                <div className="flex gap-[25px] mt-[20px]">
                    <div onClick={() => {
                        setSelection('all')
                    }} className={`p-[10px] rounded-[8px] font-medium ${selection == 'all' ? 'bg-blue-500 text-white' : 'bg-slate-200 text-blue-700 '}`}>ทั้งหมด</div>
                    <div onClick={() => {
                        setSelection('one')
                    }} className={`p-[10px] rounded-[8px] font-medium ${selection == 'one' ? 'bg-blue-500 text-white' : 'bg-slate-200 text-blue-700 '}`}>ใบเดียว</div>
                    <div onClick={() => {
                        setSelection('duo')
                    }} className={`p-[10px] rounded-[8px] font-medium ${selection == 'duo' ? 'bg-blue-500 text-white' : 'bg-slate-200 text-blue-700 '}`}>ชุด 2 ใบ</div>
                </div>


                {/* <div className="flex flex-col justify-center items-center">
                    <div className="w-[100%] h-[calc(100%-150px)] flex justify-center items-center">
                        <div className="flex gap-[10px]">
                            {array.map((item, index) => {



                                return (
                                    <input key={item} ref={(res) => {
                                        Ref.current.push(res)
                                    }} onKeyUp={(e) => {
                                        // Ref.current.blur()
                                        // Ref[item+1].current.
                                        if (e.key > 0 && e.key < 10) {
                                            if (item != Ref.current.length) {
                                                Ref.current[index + 1].focus()
                                            } else {
                                                Ref.current[item - 1].blur()
                                            }
                                        } else {
                                            if (item != 1) {
                                                Ref.current[index - 1].focus()
                                            } else {
                                                Ref.current[item - 1].blur()
                                            }
                                        }
                                    }} onChange={(e) => {
                                        // console.l
                                    }} name={`test-${item}`} type="number" maxLength="1" minLength="1" className="w-[45px] h-[45px] rounded-[12px] bg-white shadow-[0px_1px_0px_0.5px_rgba(0,0,0,0.15)] text-center font-[apple-medium] text-[18px]"></input>
                                )
                            })}
                        </div>
                    </div>
                </div> */}

            </div>

            <ListLottery status={status} setStatus={setStatus} open={open} setOpen={setOpen} find={find} selection={selection} getLottery={getLottery} seachinput={seachinput} />

        </div>
    )
}

export default FindLottery