import { liff } from '@line/liff'

export const GetGoogleUserProfile = () => {
    return new Promise(async(resolve, reject)=>{
        const user_google = await localStorage.getItem("user")
        const convert_user_google = await JSON.parse(user_google)
    
        resolve(convert_user_google.displayName)
    })
}


export const getLiff = () => {
    return new Promise(async(resolve, reject)=>{
        try {
            await liff.init({ liffId: '2001658868-jzvBnnKX' })
    
            if (liff.isLoggedIn()) {
                let LineUserProfile = await liff.getProfile()
                resolve(LineUserProfile)
            }
        } catch (err) {
            reject({status: 400})
        }
    })

}

export const Logout = () => {
    let app_type_storage = localStorage.getItem('app_type')
    if (app_type_storage == "GOOGLE") {
        localStorage.removeItem('app_type')
        localStorage.removeItem('user')
        window.location.reload()
    } else if (app_type_storage == "LINE") {
        if (liff.isLoggedIn()) {
            localStorage.removeItem('app_type')
            liff.logout()
            window.location.reload()
        }
    }
}