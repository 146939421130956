import { useContext, useEffect, useState } from "react"
import { GetGoogleUserProfile } from "../methods/Authentication"
import { getLiff } from "../methods/Authentication"
import { addDoc, collection, doc, getDoc, getDocs, query, updateDoc, where } from "firebase/firestore"
import { db, storage } from "../firebase"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { motion } from 'framer-motion'
import { getDownloadURL, uploadBytes, ref } from "firebase/storage"
import { v1 as uuidv1 } from 'uuid'
import { context } from "../App"
import Alert from "../Components/Alert"
import axios from 'axios'
import Loading from '../Screens/loading-page.json'
import Lottie from "lottie-react"
import { LuUsers2 } from "react-icons/lu";


const LotteryProfile = () => {

    const {state} = useLocation()

    let [userProfile, setUserProfile] = useState([])
    let [copy, setCopy] = useState(false)
    let [profileImg, setProfileImg] = useState('')
    let [edited, setEdited] = useState(false)



    let [modalBanking, setModalBanking] = useState(false)
    let [bankingNameModal, setBankingNameModal] = useState('')
    let [bankingNumberModal, setBankingNumberModal] = useState(false)
    let [profileImgFile, setProfileImgFile] = useState(null)
    let [banking_name, setBanking_name] = useState('')
    let [banking_username, setBanking_username] = useState('')
    let [banking_number, setBanking_number] = useState('')


    let [modalName, setModalName] = useState(false)
    let [modalLastName, setModalLastname] = useState(false)
    let [nameEdit, setNameEdit] = useState('')
    let [lastNameEdit, setLastNameEdit] = useState('')
    let [pointModal, setPointModal] = useState(false)

    let [info, setInfo] = useState(false)
    let [loading, setLoading] = useState(false)

    let [usersRef, setUsersRef] = useState(null)
    let [refModal, setRefModal] = useState(false)

    let [modalCopy, setModalCopy] = useState(false)

    let navigate = useNavigate()

    let { playtwoAlert } = useContext(context)

    let allBanking = [
        {
            name: 'ธนาคารกสิกร',
            img: './imgs/logo_banking/kbank.svg'
        },
        {
            name: 'ธนาคารกรุงไทย',
            img: './imgs/logo_banking/ktb.svg'
        },
        {
            name: 'ธนาคารไทยพาณิชย์',
            img: './imgs/logo_banking/scb.svg'
        },
        {
            name: 'ธนาคารกรุงศรีอยุธยา',
            img: './imgs/logo_banking/bay.svg'
        },
        {
            name: 'ธนาคารกรุงเทพ',
            img: './imgs/logo_banking/bbl.svg'
        },
        {
            name: 'พร้อมเพย์',
            img: './imgs/logo_banking/promptpay.svg'
        },
    ]

    let { refURL } = useParams()

    const init = async () => {

        let userDetail = await JSON.parse(localStorage.getItem('userDetail'))

        console.log(userDetail)

        if (userDetail) {

            let user = await getDoc(doc(db, 'users', userDetail.docId)).then((res) => {

                let user = {
                    ...res.data(),
                    userId: userDetail.docId
                }

                return user

            })

            setUserProfile({ ...user, userId: user.userId })

            setBanking_name(user.banking_name)
            setBanking_username(user.banking_username)
            setBanking_number(user.banking_number)
            setNameEdit(user.name)
            setLastNameEdit(user.lastName)

            if (refURL && !user.owner_ref && refURL != user.userId) {
                console.log("Reference")
                updateDoc(doc(db, 'users', user.userId), {
                    owner_ref: refURL
                }).then((res) => {
                    console.log("Update Reference Success")
                    window.location.href = "/profile"
                })
            }

            let token = await getDocs(query(collection(db, 'users'), where('owner_ref', '==', userDetail.docId))).then((snapshot) => {
                let data = snapshot.docs.map((item) => {
                    return {
                        ...item.data(),
                        userId: item.id
                    }
                })

                return data

            })

            let phone = await getDocs(query(collection(db, 'users'), where('owner_ref', '==', user.numberPhone))).then((snapshot) => {
                let data = snapshot.docs.map((item) => {
                    return {
                        ...item.data(),
                        userId: item.id
                    }
                })

                return data

            })

            let resultRefTotal = token.concat(phone)
            
            setUsersRef(resultRefTotal)
        }
    }

    console.log(userProfile)

    const UpdateUserData = async () => {


        let userJSON = JSON.parse(localStorage.getItem('userDetail'))

        let payload = {
            banking_name: {
                name: banking_name && banking_name.name ? banking_name.name : '',
                img: banking_name && banking_name.img ? banking_name.img : ''
            },
            banking_username: banking_username ? banking_username : '',
            banking_number: banking_number ? banking_number : '',
            name: nameEdit ? nameEdit : '',
            lastName: lastNameEdit ? lastNameEdit : ''
        }

        if (profileImgFile) {
            console.log("Test")
            await uploadBytes(ref(storage, `profile_img/${profileImgFile.name}${uuidv1()}`), profileImgFile).then(async (refer) => {
                await getDownloadURL(refer.ref).then((url) => {
                    console.log(url)
                    payload.userProfileImg = url
                })
            })
        }

        await updateDoc(doc(db, 'users', userJSON.docId), payload).then(() => {
            console.log("Update Users Success")
            ResetEditAll()
            window.location.reload(true)
        })

    }

    let points = [100, 150, 200]

    const PointsToMoney = async (amount) => {
        playtwoAlert.fire({ open: true, title: "ต้องการแลกเงิน", confirmText: "ยืนยัน", cancelText: 'ยกเลิก', dialog: true }, () => {
            setLoading(true)

            if (userProfile.point && userProfile.point >= amount) {

                console.log("ยิง")

                axios.post('https://wanfah-server-production.up.railway.app/api/points_to_money', {
                    userProfile: userProfile,
                    amount: amount
                }).then((res) => {
                    console.log(res.data)
                    if (res.data.status == 200) {

                        setTimeout(() => {
                            window.location.reload(true)
                        }, 1500)

                        updateDoc(doc(db, 'users', userProfile.userId), {
                            point: Number(userProfile.point) - amount
                        }).then(() => {
                            setLoading(false)
                            playtwoAlert.fire({ open: true, title: 'แลกพอยต์สำเร็จ', confirmText: 'เข้าใจแล้ว', dialog: false }, () => {
                                console.log("ok")
                            })
                        })
                    }
                })

            } else {
                setTimeout(() => {
                    setLoading(false)
                    playtwoAlert.fire({ open: true, title: 'พอยต์ของท่านไม่เพียงพอ', confirmText: 'เข้าใจแล้ว', dialog: false }, () => {
                        console.log("ok")
                    })
                }, 1000)
            }
        })
    }

    const ResetEditAll = () => {
        setEdited(false)
        setProfileImg('')
        setBanking_name(userProfile.banking_name)
        setBanking_username(userProfile.banking_username)
        setBanking_number(userProfile.banking_number)
        setNameEdit(userProfile.name)
        setLastNameEdit(userProfile.lastName)
        setModalBanking(false)
        setBankingNameModal(false)
        setBankingNumberModal(false)
    }

    console.log(profileImg)

    useEffect(() => {

        window.scrollTo(0, 0)

        init()
        if(state && state.info_pass){
            setInfo(true)
        }
    }, [])

    return (
        <div>

            {loading ? <div className="w-[100%] h-[100%] fixed flex justify-center items-center z-[2]">
                <Lottie animationData={Loading}></Lottie>
            </div> : null}

            <Alert />

            <motion.div initial={{ opacity: 0 }} animate={info ? { opacity: 1 } : { opacity: 0 }} className={`fixed w-[100%] h-[100%] bg-[rgba(0,0,0,0.8)] z-[1] flex justify-center items-center ${info ? 'pointer-events-auto' : 'pointer-events-none'}`}>
                <div className="w-[350px] bg-white rounded-[8px] p-[20px]">
                    <div className="flex gap-[10px] justify-center items-center">
                        <p className="font-medium text-[24px] text-center">ระบบแนะนำเพื่อน</p>
                        <img className="w-[30px]" src="./imgs/friends.png"></img>
                    </div>
                    <p className="font-light text-[18px]">ระบบแนะนำเพื่อน เมื่อคุณแนะนำเพื่อน 1 คน และถ้าเกิดว่าคนที่คุณเชิญ ซื้อหวย 1 ใบ คุณจะได้รับแต้ม 2 แต้ม ต่อ 1 ใบ ที่เขาซื้อ</p>
                    <p className="font-medium text-[18px] mt-[20px]">เช่น คนถูกเชิญ ซื้อ 1 ใบ = ได้ +2 แต้ม</p>
                    <p className="font-light text-[18px] mt-[10px]">**วิธีแนะนำเพื่อน : เพียงแค่กดคัดลอกรหัสแนะนำและส่งให้เพื่อน</p>
                    <div onClick={() => {
                        setInfo(false)
                    }} className="p-[10px] bg-blue-500 rounded-[8px] mt-[20px]"><p className="text-center font-medium text-white text-[18px]">เข้าใจแล้ว</p></div>
                </div>
            </motion.div>

            <motion.div onClick={(e) => {
                if (e.target == e.currentTarget) {
                    setPointModal(false)
                }
            }} initial={{ opacity: 0 }} animate={pointModal ? { opacity: 1 } : { opacity: 0 }} className={`w-[100%] h-[100%] flex justify-center items-center fixed bg-[rgba(0,0,0,0.7)] z-[1] ${pointModal ? 'pointer-events-auto' : 'pointer-events-none'}`}>
                <div className="w-[350px] rounded-[8px] bg-white flex justify-center items-center flex-col gap-[20px] p-[20px]">
                    <div className="flex flex-col justify-center items-center">
                        <p className="font-medium text-[24px]">แลกพอยต์เป็นเงิน</p>
                        <p className="font-medium">พอยต์ของคุณ : {userProfile && userProfile.point ? userProfile.point : null} วาฬฟ้า</p>
                        <p className="font-light text-[14px] text-red-500">หากพอยต์ไม่อัพเดทกรุณารีเฟรชหน้าจอใหม่</p>
                    </div>

                    {points && points.length > 0 ? points.map((item) => {
                        return (
                            <div className="flex gap-[10px] justify-around w-[100%] items-center">
                                <div className="flex gap-[10px]">
                                    <div className="flex gap-[5px] items-center">
                                        <p className="font-medium text-[20px] text-green-600">{item}</p>
                                        <p className="font-light text-[16px] text-green-600">฿</p>
                                    </div>
                                    <p className="font-medium">=</p>
                                    <div className="flex gap-[5px] items-center">
                                        <p className="font-medium text-[20px] text-blue-500">{item}</p>
                                        <p className="font-light text-[14px] text-blue-500">วาฬฟ้า</p>
                                    </div>
                                </div>
                                <div onClick={() => {
                                    PointsToMoney(item)
                                }} className="p-[10px] bg-blue-400 text-white font-medium rounded-[8px] active:bg-blue-500 hover:bg-blue-500 cursor-pointer">แลกเงิน</div>
                            </div>
                        )
                    }) : null}
                </div>
            </motion.div>

            <img onClick={() => {
                navigate('/')
            }} className="rotate-[180deg] w-[50px] absolute top-[20px] left-[20px]" src="./svgs/arrow_right.svg"></img>
            <div className="w-[100%] flex justify-center flex-col items-center gap-[20px]">
                <div >
                    <p className="font-medium text-[23px] mt-[20px]">โปรไฟล์</p>
                </div>
                <div className="flex justify-center flex-col items-center">
                    <input onChange={(e) => {
                        let file = e.target.files[0]
                        setProfileImgFile(file)
                        let reader = new FileReader()
                        reader.onload = (e) => {
                            setProfileImg(e.target.result)
                            setEdited(true)
                        }
                        reader.readAsDataURL(file)
                        e.target.value = ''
                    }} type="file" id="profile" className="hidden"></input>
                    <label for="profile" className="relative">
                        <img id="profile" className="w-[150px] h-[150px] object-cover rounded-full" src={profileImg ? profileImg : userProfile.linePicture ? userProfile.linePicture : userProfile.userProfileImg ? userProfile.userProfileImg : './svgs/avatar.svg'}></img>
                        <img className="right-[-20px] absolute bottom-[0px]" src="./svgs/edit.svg"></img>
                    </label>
                    <p className="font-medium mt-[10px] text-[18px]">{userProfile && userProfile.lineName ? userProfile.lineName : userProfile.name}</p>
                </div>

                <div className="mr-[215px] flex">
                    <p className="font-medium text-[20px]">ข้อมูลส่วนตัว</p>
                    <img className="w-[20px]" src={refURL ? "../svgs/arrow_right.svg" : "./svgs/arrow_right.svg"}></img>
                </div>

                <div className="w-[350px] bg-white rounded-[12px] p-[10px] flex flex-col gap-[10px]">

                    <div className="w-[100%] h-[50px] bg-gray-200 flex justify-between items-center p-[10px] rounded-[5px]">
                        <p className="font-light">พ้อยต์</p>
                        <div className="flex gap-[10px] items-center justify-center">
                            <div onClick={() => {
                                console.log("click")
                                setPointModal(true)
                            }} className="p-[5px] bg-blue-400 rounded-[8px] text-white font-light">
                                <p>แลกพอยต์</p>
                            </div>
                            <p className="font-light">{userProfile ? userProfile.point ? Number(userProfile.point) + ' วาฬฟ้า' : '0 วาฬฟ้า' : null}</p>
                        </div>
                    </div>

                    <div onClick={() => {
                        setModalName(!modalName)
                    }} className="w-[100%] h-[50px] bg-gray-200 flex justify-between items-center p-[10px] rounded-[5px]">
                        <p className="font-light">ชื่อ</p>
                        <div className="flex gap-[10px] items-center justify-center">
                            <p className="font-light">{userProfile ? userProfile.name : null}</p>
                            <img className="w-[20px]" src={refURL ? "../svgs/arrow_right.svg" : "./svgs/arrow_right.svg"}></img>
                        </div>
                    </div>

                    {modalName ? <div className="w-[100%] h-[50px]">
                        <input value={nameEdit} onChange={(e) => {

                            setNameEdit(e.target.value)

                            setEdited(true)
                        }} className="text-center font-light w-[100%] h-[100%] border-[2px] border-gray-300 rounded-[8px]" placeholder="กรอกชื่อของคุณ"></input>
                    </div> : null}

                    <div onClick={() => {
                        setModalLastname(!modalLastName)
                    }} className="w-[100%] h-[50px] bg-gray-200 flex justify-between items-center p-[10px] rounded-[5px]">
                        <p className="font-light">นามสกุล</p>
                        <div className="flex gap-[10px] items-center justify-center">
                            <p className="font-light">{userProfile ? userProfile.lastName : null}</p>
                            <img className="w-[20px]" src={refURL ? "../svgs/arrow_right.svg" : "./svgs/arrow_right.svg"}></img>
                        </div>
                    </div>

                    {modalLastName ? <div className="w-[100%] h-[50px]">
                        <input value={lastNameEdit} onChange={(e) => {

                            setLastNameEdit(e.target.value)

                            setEdited(true)
                        }} className="text-center font-light w-[100%] h-[100%] border-[2px] border-gray-300 rounded-[8px]" placeholder="กรอกนามสกุล"></input>
                    </div> : null}

                    <div className="w-[100%] h-[50px] bg-gray-200 flex justify-between items-center p-[10px] rounded-[5px]">
                        <p className="font-light">เบอร์โทรศัพท์</p>
                        <div className="flex gap-[10px] items-center justify-center">
                            <p className="font-light">{userProfile && userProfile.numberPhone ? `${userProfile.numberPhone.slice(0, 3)}-${userProfile.numberPhone.slice(3, 6)}-${userProfile.numberPhone.slice(6, 10)}` : null}</p>
                            {/* <img className="w-[20px]" src={refURL ? "../svgs/arrow_right.svg" : "./svgs/arrow_right.svg"}></img> */}
                        </div>
                    </div>

                    <div className="w-[100%] h-[50px] bg-gray-200 flex justify-between items-center p-[10px] rounded-[5px] relative">
                        <p className="font-light">รหัสแนะนำเพื่อน</p>
                        <img onClick={() => {
                            setInfo(true)
                        }} className="w-[20px]" src={"./svgs/info.svg"}></img>
                        <p className="font-light w-[100px] overflow-hidden text-ellipsis">{userProfile ? userProfile.userId : null}</p>
                        <div className="relative">
                            <img onClick={() => {
                                setModalCopy(true)
                            }} className="w-[20px]" src={`${refURL ? '.' : ''}./svgs/copy.svg`}></img>
                        </div>
                        {modalCopy ? <div className="w-[150px] p-[10px] rounded-[8px] bg-white border-b-4 border-b-blue-400 absolute right-0 bottom-[-100px] flex flex-col items-center justify-center gap-[10px] z-[2]">
                            <div className="w-[100%] flex justify-center flex-col items-center">
                                <p className="font-light">ลิ้งเชิญเพื่อน</p>
                                <p className="w-[100%] overflow-hidden text-ellipsis font-light">{`https://wanfah.online/inventory/${userProfile.userId}`}</p>
                                <div onClick={() => {
                                    setCopy(true)
                                    setTimeout(() => {
                                        setCopy(false)
                                    }, 1000)
                                    setModalCopy(false)
                                    playtwoAlert.fire({ open: true, title: 'คัดลอกลิ้งแล้ว', confirmText: 'เข้าใจแล้ว', dialog: false }, () => {
                                        console.log("ok")
                                    })
                                    navigator.clipboard.writeText(`https://wanfah.online/inventory/${userProfile.userId}`)
                                }} className="w-[100%] p-[5px] bg-blue-400 text-white rounded-[8px] text-center mt-[5px]">
                                    <p className="font-medium">คัดลอกลิ้ง</p>
                                </div>
                            </div>
                            <div className="w-[100%] flex justify-center flex-col items-center">
                                <p className="font-light">โค้ด</p>
                                <p className="w-[100%] overflow-hidden text-ellipsis font-light">{`${userProfile.userId}`}</p>
                                <div onClick={() => {
                                    setCopy(true)
                                    setTimeout(() => {
                                        setCopy(false)
                                    }, 1000)
                                    setModalCopy(false)
                                    playtwoAlert.fire({ open: true, title: 'คัดลอกโค้ดแล้ว', confirmText: 'เข้าใจแล้ว', dialog: false }, () => {
                                        console.log("ok")
                                    })
                                    navigator.clipboard.writeText(`${userProfile.userId}`)
                                }} className="w-[100%] p-[5px] bg-blue-400 text-white rounded-[8px] text-center mt-[5px]">
                                    <p className="font-medium">คัดลอกโค้ด</p>
                                </div>
                            </div>
                        </div> : null}
                    </div>

                    <div onClick={() => {
                        setRefModal(!refModal)
                    }} className="w-[100%] h-[50px] bg-gray-200 flex justify-between items-center p-[10px] rounded-[5px]">
                        <p className="font-light">การเชิญ</p>
                        <div className="flex gap-[10px] items-center justify-center">
                            <p className="font-light text-[16px]">{usersRef && usersRef.length > 0 ? usersRef.length : '0'} คน</p>
                            <img className="w-[20px]" src={refURL ? "../svgs/arrow_right.svg" : "./svgs/arrow_right.svg"}></img>
                        </div>
                    </div>

                    {modalCopy ? <div onClick={(e) => {
                        if (e.target == e.currentTarget) {
                            console.log(e.target)
                            console.log(e.currentTarget)
                            setModalCopy(false)
                        }
                    }} className="w-[100%] h-[100%] fixed top-0 left-0"></div> : null}


                    {refModal ? <div className="w-[100%] bg-gray-300 p-[10px] rounded-[5px] flex-col gap">
                        {usersRef && usersRef.length > 0 ? <p className="font-medium text-green-600 text-[18px] text-center">ชวนเพื่อนสำเร็จ <LuUsers2 color="black" /></p> : <p className="font-medium text-black text-[18px] text-center">ไม่พบคนที่ชักชวน</p>}
                        {usersRef && usersRef.length > 0 ? usersRef.map((item) => {
                            return (
                                <div className="flex justify-between">
                                    <p className="font-light">{item.name ? item.name : 'ผู้ใช้ไม่มีชื่อ'}</p>
                                    <p className="font-light">{item.numberPhone}</p>
                                </div>
                            )
                        }) : null}
                    </div> : null}

                    {/* <div className="flex items-center gap-[10px]">
                        <img className="w-[40px]" src="./svgs/line_logo.svg"></img>
                        <div>
                            <p className="font-light" onClick={async () => {

                                try {
                                    await liff.init({ liffId: '2002602584-R2g131Z9' })

                                    if (liff.isLoggedIn()) {
                                        await liff.shareTargetPicker([
                                            {
                                                type: 'text',
                                                text: 'https://wanfah.online/inventory/' + userProfile.userId
                                            }
                                        ], { isMultiple: true })
                                    } else {
                                        liff.login()
                                    }
                                } catch (err) {
                                    playtwoAlert.fire({ open: true, title: 'ยังไม่สามารถใช้งานได้ในขณะนี้', confirmText: 'เข้าใจแล้ว', dialog: false }, () => {
                                        console.log("ok")
                                    })
                                }
                            }}>แชร์ลิ้งให้เพื่อนใน LINE</p>
                            <p className="font-light text-[14px]">ด้วยรหัสแนะนำเพื่อนแบบรวดเร็ว</p>
                        </div>
                    </div> */}
                </div>

                {/* Banking */}

                <div className="mr-[0px] w-[350px] flex items-center">
                    <p className="font-medium text-[20px]">ธนาคาร</p>
                    <img className="w-[20px]" src={refURL ? "../svgs/arrow_right.svg" : "./svgs/arrow_right.svg"}></img>
                    <p className="font-light text-red-600">(เลขบัญชีโอนเงินเข้าเมื่อถูกรางวัล)</p>
                </div>

                {userProfile ? userProfile.win_lottery && userProfile.win_lottery.length > 0 ? <div className="w-[330px] ml-[-10px]">
                    <p className="font-light text-red-500">* กรุณาเช็คความเรียบร้อย เลขที่บัญชีธนาคาร และ ชื่อบัญชีธนาคาร *</p>
                    <p className="font-light text-blue-500 mt-[20px]">หากมีปัญหาให้ติดต่อ facebook : วาฬฟ้า พารวย</p>

                    <p className="font-light">เลขรางวัลที่ถูกรางวัล</p>
                    <div className="flex gap-[10px] flex-wrap">
                        {userProfile.win_lottery.map((item) => {
                            return (
                                <div>
                                    <p className="font-light">- {item}</p>
                                </div>
                            )
                        })}
                    </div>
                    <p className="font-medium text-[20px] text-center mt-[10px] mb-[10px]">จำนวนเงินรางวัล : {Number(userProfile.amountWin).toLocaleString('th-TH')} บาท</p>
                    <p className={`font-light ${userProfile.transaction ? 'text-green-600' : ''}`}>{userProfile.transaction ? 'เสร็จสิ้น' : 'โปรดรอ 5 - 15 นาที'}</p>
                </div> : null : null}

                <div className="w-[350px] bg-white rounded-[12px] p-[20px] mb-[20px]">
                    <div className={`flex flex-col ${modalBanking ? 'gap-[20px]' : 'gap-[10px]'}`}>
                        <div onClick={() => {
                            setModalBanking(!modalBanking)
                        }} className="flex justify-between items-center ">
                            <p className="font-medium text-gray-500">ธนาคาร</p>
                            <div className="flex items-center gap-[10px]">
                                <div className="flex items-center gap-[10px]">
                                    <img className="rounded-[12px] w-[50px]" src={banking_name && banking_name.img ? banking_name.img : ''}></img>
                                    <p className="font-medium text-[16px]">{banking_name && banking_name.name ? banking_name.name : null}</p>
                                    <img className="w-[20px]" src={refURL ? "../svgs/arrow_right.svg" : "./svgs/arrow_right.svg"}></img>
                                </div>
                            </div>
                        </div>

                        {/* DropDown */}
                        <motion.div initial={{ padding: 0 }} animate={modalBanking ? { opacity: 1, padding: 20 } : { opacity: 0, height: 0 }} className={`w-[100%] bg-slate-200 rounded-[8px] overflow-y-scroll flex flex-col gap-[10px] ${modalBanking ? 'pointer-events-auto' : 'pointer-events-none'} `}>
                            {allBanking.map((item) => {
                                return (
                                    <motion.div onClick={() => {
                                        setBanking_name(item)
                                        setModalBanking(false)
                                        setEdited(true)
                                    }} initial={{ backgroundColor: 'white' }} animate={banking_name && banking_name.name == item.name ? { backgroundColor: '#cbd5e1' } : { backgroundColor: '#FFFFFF' }} className={`flex items-center gap-[10px] p-[10px] rounded-[12px]`}>
                                        <img className="rounded-[12px] w-[50px]" src={item.img}></img>
                                        <p className="font-medium">{item.name}</p>
                                    </motion.div>
                                )
                            })}
                        </motion.div>

                        <div className="flex justify-between items-center " onClick={() => {
                            setBankingNameModal(!bankingNameModal)
                            // setEdited(true)
                        }}>
                            <p className="font-medium text-gray-500">ชื่อบัญชี</p>
                            <div className="flex items-center gap-[10px]">
                                <p className="font-medium text-[16px]">{banking_username}</p>
                                <img className="w-[20px]" src={refURL ? "../svgs/arrow_right.svg" : "./svgs/arrow_right.svg"}></img>
                            </div>
                        </div>


                        {bankingNameModal ? <div className="w-[100%] h-[50px]">
                            <input value={banking_username} onChange={(e) => {
                                setBanking_username(e.target.value)
                                setEdited(true)
                            }} className="text-center font-light w-[100%] h-[100%] border-[2px] border-gray-300 rounded-[8px]" placeholder="ชื่อบัญชีธนาคาร"></input>
                        </div> : null}

                        <div onClick={() => {
                            setBankingNumberModal(!bankingNumberModal)
                            // setEdited(true)
                        }} className="flex justify-between items-center ">
                            <p className="font-medium text-gray-500">เลขที่บัญชี</p>
                            <div className="flex items-center gap-[10px]">
                                <p className="font-medium text-[16px]">{banking_number}</p>
                                <img className="w-[20px]" src={refURL ? "../svgs/arrow_right.svg" : "./svgs/arrow_right.svg"}></img>
                            </div>
                        </div>

                        {bankingNumberModal ? <div className="w-[100%] h-[50px]">
                            <input value={banking_number} onChange={(e) => {

                                setBanking_number(e.target.value)

                                setEdited(true)
                            }} className="text-center font-light w-[100%] h-[100%] border-[2px] border-gray-300 rounded-[8px]" placeholder="เลขที่บัญชี"></input>
                        </div> : null}

                    </div>
                </div>

                <motion.div initial={{ opacity: 0 }} animate={edited ? { opacity: 1 } : { opacity: 0 }} className={`fixed bottom-[20px] flex gap-[10px] ${edited ? 'pointer-events-auto' : 'pointer-events-none'}`}>
                    <div onClick={() => {
                        UpdateUserData()
                    }} className="w-[150px] h-[50px] bg-blue-500 rounded-[8px] flex justify-center items-center">
                        <p className="font-light text-white">บันทึกข้อมูล</p>
                    </div>
                    <div onClick={() => {
                        ResetEditAll()
                    }} className="w-[150px] h-[50px] bg-red-500 rounded-[8px] flex justify-center items-center">
                        <p className="font-light text-white">ยกเลิก</p>
                    </div>
                </motion.div>


                <div className="h-[100px]">

                </div>

            </div>
        </div>
    )
}


export default LotteryProfile