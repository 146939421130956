import { useContext, useState } from "react"
import { cartContext, expireCart } from "../../App"
import { motion } from 'framer-motion'
import { useNavigate } from "react-router-dom"
import Lottie from "lottie-react"
import loading from '../../Screens/loading.json'
import { FaStar } from "react-icons/fa6";

const LotteryFooter = ({ status, state }) => {

    let navigate = useNavigate()

    let { cart, setCart } = useContext(cartContext)
    let { expire, ResetCartTime, min, sec, diff, showAnimLoad } = useContext(expireCart)
    let totalItem
    let bottom = localStorage.getItem('bottom')

    // console.log("DIFF : ", diff)
    // console.log("ShowANimLoad" , showAnimLoad)

    let tu = localStorage.getItem('tu') == 'true'

    const variant = {
        adding: {
            backgroundColor: 'white',
            height: tu ? 350 : 110,
            borderRadius: 20,
            elevation: 20,
            shadowColor: '#52006A',
            position: 'relative',
            boxShadow: '0px 0px 5px 3px rgba(0, 0, 0, 0.15)'
        },
        added: {
            scale: 1
        },
        cartGoTop: {
            position: 'absolute',
            top: 10
        }
    }

    if (cart && cart.length > 0) {
        totalItem = cart.reduce((total, item) => {
            return total = total + item.quantity
        }, 0)
    }

    return (
        <div className={`w-[100%] ${tu ? 'h-[100px]' : 'h-[80px]'} shadow-[0px_0px_5px_1px_rgba(0,0,0,0.5)] fixed bottom-[0px] flex justify-around items-center z-[4]`}>

            <motion.div className={` w-[100%] h-[100%] flex justify-center items-center ${bottom == 'home' ? 'bg-blue-500' : 'bg-blue-400'}`} onClick={() => {
                navigate('/')
                localStorage.setItem('bottom', 'home')
            }}>
                <div className="flex justify-center items-center flex-col gap-[0px]">
                    <img src="/imgs/homewhite.png" className="w-[50px] h-[50px]"></img>
                    <p className="font-medium text-white">หน้าหลัก</p>
                </div>

            </motion.div>

            <motion.div variants={variant} animate={cart.length > 0 ? 'adding' : ''} className={`${bottom == 'cart' ? 'bg-blue-500' : 'bg-blue-400'} w-[100%] h-[100%] flex justify-center items-center relative`} onClick={(e) => {
                navigate('/cart')
                localStorage.setItem('state', 1)
                localStorage.setItem('bottom', 'cart')
            }}>
                
                {!!cart && cart.length > 0 && state != 1 && state != 2 ? <FaStar className="w-[25px] h-[25px] absolute right-2 top-0" color={'gold'}/> : null}

                <div className="flex flex-col gap-[5px] justify-center items-center">
                    <motion.img variants={variant} animate={cart.length > 0 ? 'cartGoTop' : ''} src={cart.length > 0 ? '/imgs/basketskyblue.png' : '/imgs/basketwhite1.png'} className={`${tu ? 'w-[60px] h-[60px]' : 'w-[44px] h-[44px]'}`}></motion.img>
                    {cart && cart.length > 0 ? <div className={`w-[30px] h-[30px] bg-red-800 text-white flex justify-center items-center rounded-full absolute left-[65px] top-[13px]`}>
                        <p className="font-medium text-[20px]">{totalItem}</p>
                    </div> : null}
                    {cart.length <= 0 ? <p className="font-medium text-white">ตะกร้า</p> : null}
                </div>

                {tu ? <p className="absolute top-[90px] font-medium text-[30px] text-blue-400">ตะกร้าสินค้า</p> : null}

                {diff >= 0 ? showAnimLoad == false ? <p className={diff == 0 ? 'hidden' : `absolute ${!tu ? 'top-[56px]' : 'top-[150px]'} text-[18px] font-medium w-[80px] h-[30px] flex justify-center items-center rounded-[8px] text-center bg-[#36AEE0] text-white`}>{min.toString().length == 1 ? '0' + min : min}:{sec.toString().length == 1 ? '0' + sec : sec}</p> : <Lottie className="absolute" animationData={loading} /> : null}


            </motion.div>

            <motion.div className={`${bottom == 'inventory' ? 'bg-blue-500' : 'bg-blue-400'} w-[100%] h-[100%] flex justify-center items-center`} onClick={() => {
                navigate('/inventory')
                localStorage.setItem('bottom', 'inventory')
            }}>
                <div className="flex justify-center items-center flex-col gap-[5px]">
                    <img src="/imgs/lockerwhitecustom.png" className="w-[40px] h-[40px]"></img>
                    <p className="font-medium text-white">ตู้เซฟ</p>
                </div>
            </motion.div>
        </div>
    )
}

export default LotteryFooter