import { cartContext, context, menuContext } from "../App"
import Alert from "../Components/Alert"
import FindLottery from "./LotteryComponents/FindLottery"
import ListLottery from "./LotteryComponents/ListLottery"
import LotteryHeader from "./LotteryComponents/LotteryHeader"
import { useContext, useEffect, useState } from "react"
import Resizer from 'react-image-file-resizer'
import { storage, db } from "../firebase"
import { ref, uploadBytes, getDownloadURL } from "firebase/storage"
import { v1 as uuidv1 } from 'uuid'
import { addDoc, collection, doc, onSnapshot, updateDoc, where, query, getDoc, deleteDoc, deleteField, getDocs } from "firebase/firestore"
import LotteryFooter from "./LotteryComponents/LotteryFooter"
import axios from "axios"
import { Html5Qrcode } from "html5-qrcode";
import LoadingPage from '../Screens/loading-page.json'
import load from '../Screens/loading.json'
import Lottie from "lottie-react"
import { motion } from 'framer-motion'
import { useNavigate } from "react-router-dom"
import { RxExit } from "react-icons/rx";
import { ImCross } from "react-icons/im"

const LotteryHome = () => {

    let [uploadFile, setUploadFile] = useState(null)
    let [lotteryNumber, setLotteryNumber] = useState(null)
    let { playtwoAlert } = useContext(context)
    let { cart, setCart } = useContext(cartContext)

    let [progress, setProgress] = useState(0)
    let [name_progress, setNameProgress] = useState('')

    let [src, setSrc] = useState([])
    let [img, setImg] = useState(null)
    let [crop, setCrop] = useState(null)
    let [setting, setSetting] = useState({ x: 0, y: 0, width: 1200, height: 350 })
    let [numberLottery, setNumberLottery] = useState([])
    let [errorLottery, setErrorLottery] = useState([])
    let [loadingLottery, setLoaddingLottery] = useState(false)

    let [selectShow, setSelectShow] = useState(true)

    let [array_url, setArray_url] = useState([])

    let [sec, setSec] = useState(0)
    let [min, setMin] = useState(0)
    let [hours, setHours] = useState(0)
    let [day, setDay] = useState(0)

    const handleBase64ToImageFile = (base64String, contentType, fileName) => {
        return new Promise((resolve, reject) => {
            try {
                // Extract the actual Base64 data from a data URL
                const dataPortion = base64String.split(',')[1];

                // Decode the Base64 string
                const binaryString = atob(dataPortion);

                // Create a typed array from the binary string
                const byteArray = new Uint8Array(binaryString.length);
                for (let i = 0; i < binaryString.length; i++) {
                    byteArray[i] = binaryString.charCodeAt(i);
                }

                // Create a Blob from the typed array
                const blob = new Blob([byteArray], { type: contentType });

                // Create a File object from the Blob
                const file = new File([blob], fileName, { type: contentType });

                // Do something with the File (e.g., upload it)
                resolve(file)
            } catch (error) {
                reject("Error HandleBase64ToImageFIle")
            }
        })
    };

    const findSameAndChange = (keepNumberLottery) => {
        return new Promise(async (resolve, reject) => {
            for (let i = 0; i < keepNumberLottery.length; i++) {
                for (let k = i + 1; k < keepNumberLottery.length; k++) {
                    if (keepNumberLottery[i].number === keepNumberLottery[k].number) {
                        keepNumberLottery[i].img = [keepNumberLottery[i].img]
                        keepNumberLottery[i].img.push(keepNumberLottery[k].img)
                        keepNumberLottery[i].quantity = keepNumberLottery[i].quantity + 1
                        keepNumberLottery[k] = {}
                    }
                }
            }
            resolve()
        })
    }

    const WaitUrl = (item) => {
        return new Promise((resolve, reject) => {
            let arr = []
            item.img.map((img_element) => {
                // console.log(img_element)
                const uploadRef = ref(storage, `lottery/${img_element.name}`)


                uploadBytes(uploadRef, img_element).then((snapshot) => {
                    // console.log("Snap Shot Ref : ", snapshot.ref)
                    getDownloadURL(snapshot.ref).then((url) => {
                        if (url) {
                            // console.log(url)

                            arr.push(url)


                            // console.log(arr.length, item.img.length)

                            if (Number(arr.length) == Number(item.img.length)) {
                                resolve(arr)
                            }



                        }
                    })
                })

            })
        })
    }

    const UploadImgToFirebase = async (item) => {

        return new Promise(async (resolve, reject) => {
            if (item.number) {
                if (item.quantity >= 2) {

                    let arr_img = await WaitUrl(item)

                    // console.log(arr_img)

                    let payload = {
                        number: item.number,
                        img: arr_img,
                        price: 80,
                        lock: false,
                        quantity: item.quantity
                    }

                    // console.log("Payload : ", payload)

                    addDoc(collection(db, 'lottery'), payload).then((res) => {
                        // console.log("Added Multiple Lottery to Database")
                        resolve()
                    })
                    // When for arrray in image finished it will return Url And I will keep in array_url then when loop finished I will add property to payload by array_url

                } else {

                    // console.log("Only One : ", item)

                    const uploadRef = ref(storage, `lottery/${item.number}${uuidv1()}`)


                    uploadBytes(uploadRef, item.img).then((snapshot) => {
                        // console.log("Snap Shot Ref : ", snapshot.ref)
                        getDownloadURL(snapshot.ref).then((url) => {
                            if (url) {

                                let payload = {
                                    number: item.number,
                                    img: url,
                                    price: 80,
                                    lock: false,
                                    quantity: item.quantity
                                }

                                addDoc(collection(db, 'lottery'), payload).then((res) => {
                                    // console.log("Added One Lottery to Database")
                                    resolve()
                                })



                            }
                        })
                    })

                }
            }
        })

        // uploadBytes(uploadRef, item.img).then((snapshot) => {
        //     // console.log("Snap Shot Ref : ", snapshot.ref)
        //     getDownloadURL(snapshot.ref).then((url) => {
        //         if (url) {
        //             let payload = {
        //                 number: item.number,
        //                 img: item.quantity >= 2 ? [...url, url] : url,
        //                 price: 80,
        //                 lock: false,
        //                 quantity: item.quantity
        //             }

        //             addDoc(collection(db, 'lottery'), payload).then((res) => {
        //                 // console.log(res)
        //                 playtwoAlert.fire({ open: true, confirmText: 'ตกลง', dialog: false, title: 'อัพโหลดลอตเตอรี่เสร็จสิ้น' }, () => {
        //                     console.log("success")
        //                     setOpen(false)
        //                     ResetAll()
        //                 })
        //             })
        //         }
        //     })
        // })
    }


    const StoreTofirebase = async () => {
        if (numberLottery) {

            let keepNumberLottery = []

            keepNumberLottery = await numberLottery.map((item) => {
                return {
                    img: item.img_base64,
                    number: item.text.split('-')[3],
                    quantity: 1
                }
            })

            await findSameAndChange(keepNumberLottery)


            // Convert Base64 Img to File Img All 
            await keepNumberLottery.map(async (item) => {
                // console.log(item)
                if (item.quantity >= 2) {
                    item.img.map(async (img) => {
                        let imgFile = await handleBase64ToImageFile(img, 'image/jpeg', item.number + uuidv1())
                        // console.log(img)
                        item.img.push(imgFile)
                    })

                    for (let i = 0; i < item.quantity; i++) {
                        item.img.splice(0, 1)
                    }
                } else {
                    if (item.number) {
                        let imgFile = await handleBase64ToImageFile(item.img, 'image/jpeg', item.number + uuidv1())
                        item.img = imgFile
                    }
                }
            })

            // console.log(keepNumberLottery)

            keepNumberLottery.map((item) => {
                UploadImgToFirebase(item).then(() => {
                    playtwoAlert.fire({ open: true, confirmText: 'ตกลง', dialog: false, title: 'อัพโหลดลอตเตอรี่เสร็จสิ้น' }, () => {
                        // console.log("success")
                        setOpen(false)
                        ResetAll()
                    })
                })
            })
            // keepNumberLottery.map(async(item)=>{
            //     if(item.number){
            //         if(item.quantity > 1){

            //         }
            //         let imgFile = await handleBase64ToImageFile(item.img, 'image/jpeg', item.number+uuidv1())
            //         item.img = imgFile
            //         console.log(item)
            //     }
            // })

            getDoc(doc(db, 'admin_panel', 'tbW2GFM9UV0UKUCA6StW')).then((res) => {
                updateDoc(doc(db, 'admin_panel', 'tbW2GFM9UV0UKUCA6StW'), {
                    count_all_lottery: res.data().count_all_lottery + numberLottery.length
                })
            })


        } else {
            playtwoAlert.fire({ open: true, dialog: false, confirmText: 'ยืนยัน', title: 'ไม่พบรูปภาพ', }, () => {
                console.log("Success")
            })
        }
    }

    const ResetAll = () => {
        setUploadFile(null)
        setLotteryNumber(null)
    }


    let [open, setOpen] = useState(false)
    let [status, setStatus] = useState(false)

    let [user, setUser] = useState([])
    let [panel, setPanel] = useState(null)

    let [timeModal, setTimeModal] = useState(false)

    let navigate = useNavigate()

    const GetDataInit = () => {

        return new Promise(async (resolve, reject) => {
            let userStorage = localStorage.getItem('userDetail')
            let userJson = JSON.parse(userStorage)

            await onSnapshot(doc(db, 'admin_panel', 'tbW2GFM9UV0UKUCA6StW'), ((snapshot) => {
                setPanel(snapshot.data())
                setTimeModal(snapshot.data().time_status_show)
                let payload = {
                    end_date: snapshot.data().date_end,
                    time_status_show: snapshot.data().time_status_show
                }
                resolve(payload)
            }))


            if (userJson) {
                await getDoc(doc(db, 'users', userJson.docId)).then((res) => {

                    if (res.data().orderId) {
                        navigate('/cart')
                        localStorage.setItem('state', 3)
                    } else {
                        setUser(res.data())
                    }
                })
            }
        })
    }

    const init = async () => {
        let data = await GetDataInit()

        return data
    }

    let [dayEnd, setDayEnd] = useState(null)
    let [month, setMonth] = useState(null)
    let [year, setYear] = useState(null)
    let [openTime, setOpenTime] = useState(null)

    let months = ["มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน", "พฤษภาคม", "มิถุนายน", "กรกฎาคม", "สิงหาคม", "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม"]

    let [intervalId, setIntervalId] = useState(null)
    let [loading, setLoading] = useState(true)


    useEffect(() => {

        const fetchData = async () => {
            let result = await init()

            if (result.time_status_show) {

                let End = await new Date(result.end_date)
                let EndThai = End.toLocaleString('th-TH')
                setMonth(End.getMonth())
                setYear(EndThai.split(' ')[0].split('/')[2])
                setDayEnd(EndThai)
                setOpenTime(EndThai.split(' ')[1])

                let count = setInterval(() => {

                    let now = new Date()

                    console.log(now)

                    let diff = End - now

                    console.log(diff)

                    if (diff < 0) {
                        setDay(Math.ceil(diff / (1000 * 60 * 60 * 24)))
                        clearInterval(count)
                    } else {
                        let date = new Date(diff)

                        setSec(date.getSeconds())
                        setMin(date.getMinutes())
                        setHours(Math.floor((diff / (1000 * 3600))))
                        setDay(Math.round(diff / (1000 * 60 * 60 * 24)))
                    }

                    setLoading(false)
                }, 1000)

                console.log(count)
                setIntervalId(count)
            } else {
                setLoading(false)
            }

        }

        fetchData()

    }, [])

    let [completeAnim, setCompleteAnim] = useState(false)

    let [modalSiemSee, setModalSiemSee] = useState(false)

    let [luck, setLuck] = useState(false)
    let [receipt, setReceipt] = useState(false)
    let [random, setRandom] = useState(null)

    let [born, setBorn] = useState(null)

    let [lottery, getLottery] = useState(null)

    let showed_whale = localStorage.getItem('showed_whale') == 'true'

    let regex = /^\d{8}$/
    let regex_phone = /^\d{10}$/

    let success = regex.test(born)
    let success_phone = regex_phone.test(born)


    const keepSiemSee = (rand) => {
        addDoc(collection(db, 'siemsee'), {
            user_format: born,
            random: rand
        })
    }

    const Toy = () => {
        return new Promise((resolve) => {
            let rand = Math.floor((Math.random() * lottery.length))
            if (rand != null || rand != '') {
                resolve(rand)
            }
        })
    }

    const StartShake = () => {
        let success = regex.test(born)
        console.log(success)
        if (success || success_phone) {
            setLuck(true)
            setTimeout(async () => {
                setLuck(false)
                setReceipt(true)

                let siemsee = await getDocs(query(collection(db, 'siemsee'), where('user_format', '==', born))).then((res) => {
                    let data = res.docs.map((item) => {
                        return {
                            ...item.data(),
                            siemId: item.id
                        }
                    })

                    return data
                })

                console.log(siemsee)



                if (siemsee && siemsee.length > 0) {
                    setRandom(siemsee[0].random)
                } else {
                    let rand = await Toy()

                    let last2 = lottery[rand].number.slice(4, 6)
                    setRandom(last2)
                    keepSiemSee(last2)
                }

            }, 1500)
        } else {
            playtwoAlert.fire({ title: 'ตรวจสอบและกรอกใหม่อีกครั้ง', open: true, dialog: false, confirmText: 'เข้าใจแล้ว' }, () => {

            })
        }
    }

    return (
        <div>



            {/* <div className={`w-[100%] h-[100vh] fixed flex justify-center items-center ${!showed_whale ? 'pointer-events-none' : 'pointer-events-none'}`}>
                <motion.img initial={{ right: -300, position: 'absolute' }} animate={completeAnim ? { opacity: 0, right: 300 } : { right: 0 }} transition={{ duration: 3 }} onAnimationStart={() => {

                }} onAnimationComplete={() => {
                    setCompleteAnim(true)
                }} src="./gifs/morning.gif"></motion.img>
            </div> */}


            {loading ? <div className="fixed w-[100%] h-[100%] flex justify-center items-center z-[11]">
                <Lottie className="w-[200px]" animationData={LoadingPage} loop={true}></Lottie>
            </div> : null}


            {modalSiemSee ? <div className="fixed flex justify-center items-center w-[100%] h-[100%] bg-[rgba(0,0,0,0.8)] top-0 z-[20] flex-col gap-[40px]">

                <div onClick={(e) => {
                    setModalSiemSee(false)
                    setBorn('')
                }} className="p-[10px] rounded-full bg-white fixed right-[20px] top-[30px]">
                    <ImCross />
                </div>

                <motion.div className={`${receipt ? 'opacity-1 pointer-events-auto' : 'opacity-0 pointer-events-none'} duration-[1s] w-[300px] h-[400px] bg-white border-b-4 border-b-blue-400 z-[1] absolute rounded-[12px] flex justify-center items-center flex-col gap-[30px]`}>
                    <p className="font-light text-[24px]">น้องวาฬฟ้านำพาเลข</p>
                    <p className="font-medium text-[50px] text-green-600">{random}</p>
                    <p className="font-light text-[24px]">ให้คุณ</p>
                    <img className="w-[150px] bottom-[150px] right-[-30px] absolute" src="./sticker/7.png"></img>
                    <p onClick={() => {
                        setModalSiemSee(false)
                        setRandom(null)
                        setBorn('')
                        setReceipt(false)
                    }} className="p-[5px] bg-blue-400 rounded-[8px] w-[150px] h-[50px] font-medium text-white flex justify-center items-center text-[20px]">ปิด</p>
                </motion.div>

                <motion.img onClick={() => {
                    StartShake()
                }} animate={!luck ? { y: [-20, 20, -20] } : { x: [0, -20, 20, -20, 0], y: 0 }} transition={!luck ? { duration: 3, repeat: Infinity } : { duration: 0.2, repeat: Infinity }} src="./imgs/siemsee_wanfah2.png" className="min-[1080px]:w-[150px]"></motion.img>
                <div>
                    <div className="flex justify-center flex-col items-center">
                        <input type="number" onChange={(e) => {
                            setBorn(e.target.value)
                        }} className="font-light text-center p-[5px] outline-none w-[200px] h-[50px] rounded-full bg-white" placeholder="01012024"></input>
                        <p className={`font-medium ${success || success_phone ? 'text-green-500' : 'text-white'} text-[20px] mt-[5px]`}>{success || success_phone ? 'แตะที่เซียมซีเพื่อเขย่า' : 'กรอกวันเกิดของ หรือ เบอร์โทร'}</p>
                        <div className="flex gap-[10px]">
                            <p className="font-light text-white">(01012024) วันเกิด</p>
                            <p className="font-light text-white">(0987654321) เบอร์โทร</p>
                        </div>
                        <motion.div onClick={() => {
                            StartShake()
                        }} className={`p-[0px] w-[120px] bg-blue-400 text-white rounded-[8px] text-center mt-[10px] duration-[0.2s] active:bg-blue-500 ${success || success_phone ? 'opacity-1 pointer-events-auto' : 'opacity-0 pointer-events-none'}`}>
                            <p className="font-medium text-[30px]">เขย่า</p>
                        </motion.div>
                    </div>
                </div>
            </div> : null}


            <motion.div initial={{ opacity: 0 }} animate={timeModal ? { opacity: 1 } : { opacity: 0 }} className={`w-[100%] h-[100%] fixed bg-[rgba(0,0,0,0.8)] z-[10] flex justify-center items-center ${timeModal ? 'pointer-events-auto' : 'pointer-events-none'}`}>
                <div className="w-[350px] p-[10px] bg-white rounded-[8px] flex justify-center items-center flex-col gap-[20px]">
                    <div className="flex justify-center flex-col items-center mb-[5px]">
                        <p className="font-medium text-[30px] mb-[10px] text-blue-800">เปิดจำหน่ายลอตเตอรี่</p>
                        <p className="font-medium text-[20px] text-blue-500">{panel ? `งวดวันที่ ${panel.announce.split('-')[0]} ${months[Number(panel.announce.split('-')[1] - 1)]} ${panel.announce.split('-')[2]}` : null}</p>
                        <p className="font-light text-[18px]">{panel ? `วันเปิดแผง ${Number(panel.date_end.split(' ')[0].split('-')[2])} ${months[Number(panel.date_end.split(' ')[0].split('-')[1] - 1)]} ${year}` : null}</p>
                        <p className="font-light text-[18px]">{dayEnd ? `เวลา ${openTime} น.` : null}</p>
                    </div>
                    <div className="flex gap-[20px]">
                        <div className="relative p-[10px] w-[55px] flex justify-center items-center bg-blue-500 text-white rounded-[8px] flex-col ro">
                            <p className="font-medium text-[24px]">{day ? day : 0}</p>
                            <p className="text-[16px] font-light">วัน</p>
                            <div className="w-[100%] h-[2px] bg-blue-600 absolute top-[43px]"></div>
                        </div>
                        <div className="relative p-[10px] w-[55px] flex justify-center items-center bg-blue-500 text-white rounded-[8px] flex-col">
                            <p className="font-medium text-[24px]">{hours ? hours : 0}</p>
                            <p className="text-[16px] font-light">ชั่วโมง</p>
                            <div className="w-[100%] h-[2px] bg-blue-600 absolute top-[43px]"></div>
                        </div>
                        <div className="relative p-[10px] w-[55px] flex justify-center items-center bg-blue-500 text-white rounded-[8px] flex-col">
                            <p className="font-medium text-[24px]">{min ? min : 0}</p>
                            <p className="text-[16px] font-light">นาที</p>
                            <div className="w-[100%] h-[2px] bg-blue-600 absolute top-[43px]"></div>
                        </div>
                        <div className="relative p-[10px] w-[55px] flex justify-center items-center bg-blue-500 text-white rounded-[8px] flex-col">
                            <p className="font-medium text-[24px]">{sec ? sec : 0}</p>
                            <p className="text-[16px] font-light">วินาที</p>
                            <div className="w-[100%] h-[2px] bg-blue-600 absolute top-[43px]"></div>
                        </div>
                    </div>


                    <div className="flex flex-col justify-center items-center">
                        <p className="font-medium">วาฬฟ้าลอตเตอรี่ออนไลน์ 🐋</p>
                        <p className="font-light">วาฬฟ้ามาโปรดให้รวยแบบก้าวกระโดด</p>
                    </div>

                    <div onClick={() => {
                        setTimeModal(false)
                        clearInterval(intervalId)
                    }} className="p-[10px] bg-blue-500 text-white rounded-[8px] w-[100px] text-center">
                        <p className="font-medium text-[18px]">ตกลง</p>
                    </div>
                </div>
            </motion.div>

            {status ? <div className="fixed w-[100%] h-[100%] flex justify-center items-center z-[3]">
                <Lottie className="w-[200px]" animationData={LoadingPage} loop={true}></Lottie>
            </div> : null}

            {/* Admin Function */}

            <Alert />

            {open ? <div onClick={(e) => {
                if (e.target == e.currentTarget) {
                    setOpen(false)
                    ResetAll()
                }
            }} className="w-[100%] h-[100%] fixed bg-[rgba(0,0,0,0.5)] flex justify-center items-center z-[2] flex-col overflow-y-scroll">
                <div className="w-[300px] h-[200px] bg-white flex justify-center flex-col items-center gap-[20px] rounded-[20px] p-[20px]">
                    <input multiple onChange={async (e) => {

                        setNumberLottery([])
                        setErrorLottery([])
                        setLoaddingLottery(true)
                        let lotteryFiles = []
                        lotteryFiles = e.target.files

                        // console.log(lotteryFiles.length)

                        if (lotteryFiles && lotteryFiles.length > 0) {

                            for (let i = 0; i < lotteryFiles.length; i++) {
                                const reader = new FileReader();
                                reader.onload = (e) => {
                                    // console.log(i, ": ", e.target.result)
                                    setSrc((prevSrc) => {
                                        return [...prevSrc, e.target.result]
                                    })

                                    let img = new Image()
                                    img.src = e.target.result

                                    // console.log(img)
                                    img.onload = async (event) => {
                                        try {
                                            // console.log("Event : ", event.target)
                                            let canvas = await document.createElement('canvas')
                                            let ctx = await canvas.getContext('2d')

                                            canvas.width = setting.width
                                            canvas.height = setting.height

                                            ctx.drawImage(event.target, setting.x, setting.y, setting.width, setting.height, 0, 0, setting.width, setting.height)

                                            const ctxCrop = await canvas.toDataURL('image/jpeg');

                                            // console.log(ctxCrop)

                                            let lotteryCrop = await handleBase64ToImageFile(ctxCrop, 'image/jpeg', 'lottery.png')

                                            let scanner = await new Html5Qrcode('img').scanFileV2(lotteryCrop)

                                            // console.log(scanner)

                                            let lotteryDetail = {
                                                img_base64: e.target.result,
                                                text: scanner.decodedText
                                            }

                                            setNumberLottery((prevNumberLottery) => {
                                                return [...prevNumberLottery, lotteryDetail]
                                            })

                                            setLoaddingLottery(false)

                                            // console.log(numberLottery)
                                        } catch (err) {

                                            let errorPayload = {
                                                img_base64: e.target.result
                                            }

                                            setErrorLottery((prevErrorLottery) => {
                                                return [...prevErrorLottery, errorPayload]
                                            })
                                        }

                                        // console.log(scanner)

                                        // setNumberLottery([...numberLottery, lotteryDetail])
                                    }


                                }
                                reader.readAsDataURL(e.target.files[i])
                            }
                        }

                        // console.log(code)



                        // for(let i=0;i<e.target.files.length;i++){
                        //     await uploadBytes(ref(storage, `lottery/${e.target.files[i].name}${uuidv1()}`), e.target.files[i]).then(async(ref)=>{
                        //         await getDownloadURL(ref).then((firebase_url)=>{

                        //         })
                        //     })
                        // }
                    }} id="img" accept=".png, .jpg" className="w-[70%] hidden" type="file"></input>
                    <label for="img" className="w-[100%] h-[100px]">
                        <div className="w-[100%] h-[100%] flex justify-center items-center flex-col">
                            <img className="w-[80px]" src="./svgs/cloud.svg"></img>
                            {!uploadFile ? <div className="flex flex-col justify-center items-center">
                                <p className="font-[medium]">เเตะเพื่ออัพโหลดรูปภาพ</p>
                                <p className="font-[light] text-[13px]">PNG, JPG</p>
                            </div> : <p>{uploadFile ? uploadFile.name : null}</p>}
                        </div>
                    </label>

                    {/* <input onChange={(e) => {
                        setLotteryNumber(e.target.value)
                    }} className="border-[1px] border-black rounded-[30px] w-[70%] h-[50px] text-center text-[20px] font-[medium] "></input> */}

                    {/* <div className="w-[100%] h-[30px] bg-gray-300 rounded-full">
                        <div className={`w-[${progress}%] h-[100%] bg-green-600 rounded-full flex justify-center`}>
                            <p className="text-white font-medium">{progress.toFixed(2)}%</p>
                        </div>
                    </div> */}

                    {/* <p>{name_progress}</p> */}
                    {/* 
                    <button onClick={(e) => {
                        StoreTofirebase()
                    }} className="w-[100px] h-[40px] bg-[#55BCE5] text-white cursor-pointer rounded-[8px] font-[medium]">เพิ่มข้อมูล</button> */}
                </div>


                {numberLottery && numberLottery.length > 0 || errorLottery && errorLottery.length > 0 ? <div className="text-center">
                    <p className="mt-[10px] font-light text-white">ผลการอัพโหลด</p>

                    <p className={`text-white font-light ${selectShow ? 'bg-green-600' : 'bg-red-600'} rounded-[20px]`}>{selectShow ? 'สำเร็จ' : 'ไม่สำเร็จ'}</p>
                </div> : null}

                {numberLottery && numberLottery.length > 0 ? <div className="w-[300px] h-[300px] overflow-y-scroll bg-white rounded-[12px] mt-[10px] shadow-[0px_5px_5px_0px_rgba(0,0,0,0.15)]">
                    <div className="flex">
                        <div onClick={() => {
                            setSelectShow(true)
                        }} className="w-[100%] h-[60px] bg-green-500 text-white font-light flex justify-center items-center rounded-tl-[8px]">
                            <p>สำเร็จ {numberLottery.length}</p>
                        </div>
                        <div onClick={() => {
                            setSelectShow(false)
                        }} className="w-[100%] h-[60px] bg-red-500 text-white font-light flex justify-center items-center rounded-tr-[8px]">
                            <p>ไม่สำเร็จ</p>
                        </div>
                    </div>

                    <div className="flex justify-center items-center flex-wrap mt-[20px]">
                        {numberLottery && numberLottery.length ? selectShow ? numberLottery.map((item) => {
                            return (
                                <div className="w-[200px] flex justify-center items-center flex-col mb-[20px]">
                                    <img src={item.img_base64}></img>
                                    <p className="font-light text-[15px]">{item.text}</p>
                                </div>
                            )
                        }) : errorLottery && errorLottery.length > 0 ? errorLottery.map((err_item) => {
                            return (
                                <div className="w-[200px] flex justify-center items-center flex-col mb-[20px]">
                                    <img src={err_item.img_base64}></img>
                                </div>
                            )
                        }) : null : null}
                    </div>


                </div> : null}

                <button onClick={(e) => {
                    StoreTofirebase()
                }} className="w-[100px] mt-[10px] h-[40px] bg-[#55BCE5] text-white cursor-pointer rounded-[8px] font-[medium]">เพิ่มข้อมูล</button>
            </div> : null}


            {user ? user.role === 'superadmin' ? <div onClick={() => {
                setOpen(true)
            }} className="fixed bg-white p-[20px] flex justify-center items-center bottom-[250px] right-[20px] z-[1] flex-col rounded-full gap-[10px]">
                {/* <p className="font-[apple-medium] text-white">เพิ่มข้อมูล +</p> */}
                <img className="w-[40px]" src="./imgs/add.png"></img>
                {/* <p className="font-medium">เพิ่มหวย</p> */}
            </div> : null : null}

            <motion.div onClick={() => {
                setModalSiemSee(true)
            }} animate={{ rotateZ: [0, 10, -10, 0] }} transition={{ duration: 2, ease: "anticipate" }} className="fixed bottom-[110px] z-[3] w-[50px] right-[35px] min-[1080px]:w-[100px] ">
                <img className="w-[100%]" src="./imgs/siemsee_wanfah2.png"></img>
            </motion.div>


            <LotteryHeader title={"วาฬฟ้า"} />
            <FindLottery getLottery={getLottery} status={status} setStatus={setStatus} setModalSiemSee={setModalSiemSee} />
            <LotteryFooter status={status} />
        </div>
    )
}

export default LotteryHome