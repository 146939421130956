import './App.css';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useRef, useState } from 'react';
import { createContext, Suspense, lazy } from 'react';
import Practice from './Screens/Practice';
import LotteryHome from './lottery/LotteryHome'
import usePlaytwoAlert from './methods/usePlaytwoAlert';
import Cart from './Screens/Cart';
import LotteryProfile from './lottery/LotteryProfile';
import Inventory from './Screens/Inventory';
import Lottie from 'lottie-react';
import loading from './Screens/loading.json'
import AdminConsole from './Screens/AdminConsole';
import ConfirmOrder from './Screens/ConfirmOrder';
import History from './Screens/History';
import Notfound from './Screens/Notfound';
import Conclusion from './Screens/Conclusion';
export let context = createContext()
export let cartContext = createContext()
export let expireCart = createContext()
export let menuContext = createContext()

const InventoryPage = lazy(() => import('./Screens/Inventory'))
const Home = lazy(() => import('./lottery/LotteryHome'))
const Profile = lazy(() => import('./lottery/LotteryProfile'))


function App() {

  let [sidebarStatus, setSidebarStatus] = useState(false)

  let [cart, setCart] = useState([])
  let [min, setMin] = useState(0)
  let [sec, setSec] = useState(0)
  let [diff, setDiff] = useState(0)
  let [intervalId, setIntervalId] = useState(null)
  let [showAnimLoad, setShowAnimLoad] = useState(false)
  // let [expireStorage, setExpireStorage] = useState(null)

  let playtwoAlert = usePlaytwoAlert()

  let init = async () => {
    let cartStorage = await localStorage.getItem('cart')
    let expireCartStorage = await localStorage.getItem('expireCart')
    if (cartStorage) {
      let CovertToJson = JSON.parse(cartStorage)
      setCart(CovertToJson)
    }

    // console.log("expire : ", expireCartStorage)

    if (expireCartStorage) {
      ResetCartTime(Number(expireCartStorage))
    }

  }

  const clearTimeCart = () => {
    return new Promise((resolve, reject) => {
      // console.log("Before Deleted : ", intervalId)
      for (let i = 0; i < 1000; i++) {
        clearInterval(i)
      }
      setIntervalId(null)
      setMin(0)
      setSec(0)
      setDiff(0)
      localStorage.removeItem('expireCart')
      resolve()
    })
  }

  // Means Start Cart Expire
  const ResetCartTime = (expire) => {

    setShowAnimLoad(true)

    setTimeout(() => {
      setShowAnimLoad(false)
    }, 1000)

    // console.log("Before Clear : ", intervalId)
    clearInterval(intervalId)
    // console.log("After Clear : ", intervalId)

    let newInterval = setInterval(() => {
      let currentTime = new Date().getTime()
      let diff = expire - currentTime
      // console.log(diff)
      setDiff(diff)
      // console.log(diff)
      if (diff > 0) {
        let minReturn = new Date(diff).getMinutes()
        let secReturn = new Date(diff).getSeconds()
        setMin(minReturn)
        setSec(secReturn)
      } else {
        console.log("clear cart")
        clearInterval(newInterval)
        setCart([])
        localStorage.setItem('cart', [])
        localStorage.removeItem('expireCart')
        window.location.href = '/'
      }
    }, 1000)

    // console.log("NewIntervalId", newInterval)

    setIntervalId(newInterval)
  }

  const HandleExit = () => {
    localStorage.removeItem('bottom')
  }

  useEffect(() => {
    init()

    window.addEventListener('beforeunload', HandleExit)

    return () => {
      window.removeEventListener('beforeunload', HandleExit)
    }
  }, [])

  return (
    <Suspense fallback={<div className='w-[100%] h-[100vh] flex justify-center items-center'>
      <Lottie animationData={loading} loop={true} />
    </div>}>
      <context.Provider value={{ playtwoAlert }}>
        <cartContext.Provider value={{ cart, setCart }}>
          <expireCart.Provider value={{ ResetCartTime, min, sec, setMin, setSec, clearTimeCart, diff, showAnimLoad }}>
            <menuContext.Provider value={{ setSidebarStatus, sidebarStatus }}>
              <Routes>
                <Route path='*' exact insecure element={<Notfound />}></Route>
                {/* <Route path='/' element={<LandingPage />}></Route>
              <Route path='/login' element={<Login />}></Route> */}
                {/* <Route path='/practice' element={<Practice />}></Route> */}
                <Route path='/' element={<Home />}></Route>
                {/* <Route path='/otp' element={<OTP />}></Route> */}
                {/* <Route path='/phone-login' element={<PhoneLogin />}></Route> */}
                <Route path='/admin_console' element={<AdminConsole />}></Route>
                <Route path='/cart' element={<Cart />}></Route>
                <Route path='/profile' element={<Profile />}></Route>
                <Route path='/inventory' element={<InventoryPage />}>
                  <Route path=':refURL' element={<InventoryPage />}></Route>
                </Route>
                <Route path='/confirm_order' element={<ConfirmOrder />}></Route>
                <Route path='/history' element={<History />}></Route>
                <Route path='/conclusion' element={<Conclusion/>}></Route>
              </Routes>
            </menuContext.Provider>
          </expireCart.Provider>
        </cartContext.Provider>
      </context.Provider>
    </Suspense>
  );
}

export default App;
