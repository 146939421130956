import { collection, doc, getDocs, query, where, getDoc, addDoc, updateDoc, onSnapshot } from "firebase/firestore"
import { useContext, useEffect, useState } from "react"
import { db } from "../firebase"
import LotteryFooter from "../lottery/LotteryComponents/LotteryFooter"
import LotteryHeader from "../lottery/LotteryComponents/LotteryHeader"
import { motion } from 'framer-motion'
import { context } from "../App"
import Alert from "../Components/Alert"
import { liff } from '@line/liff'
import { useLocation, useNavigate, useParams } from "react-router-dom"
import LoadingPage from './loading-page.json'
import Lottie from "lottie-react"
import { ref } from "firebase/storage"
import axios from "axios"
import { FacebookIcon, FacebookShareButton } from 'react-share'
import { LineLiffId } from "../config"
import Numpad from "../Components/Numpad"
import { FaLock } from "react-icons/fa6";

/**
 * 
 * @param {string} docPath
 */


const Inventory = () => {

    let navigate = useNavigate()
    let {state} = useLocation()

    let [order, setOrder] = useState([])
    let [detailStatus, setDetailStatus] = useState(false)
    let [orderDetailId, setOrderDetailId] = useState(null)
    let totalLottery
    let [modal, setModal] = useState(false)
    let [name, setName] = useState('')
    let [lastName, setLastName] = useState('')
    let [numberPhone, setNumberPhone] = useState('')
    let totalQuantity
    let [lineUser, setLineUser] = useState([])
    let [loadingLogin, setLoadingLogin] = useState(false)
    let [line, setLine] = useState(false)
    let [announce, setAnnounce] = useState('')
    let [referral, setReferral] = useState(null)


    let [resultReward, setResultReward] = useState([])
    let [showLotteryResult, setShowLotteryResult] = useState(false)

    let [userDetail, setUserDetail] = useState([])

    let userStorage = JSON.parse(localStorage.getItem('userDetail'))
    let [userCheck, setUserCheck] = useState(false)

    let maintenance = 20

    let { refURL } = useParams()


    let { playtwoAlert } = useContext(context)



    let [statusModal, setStatusModal] = useState('login')

    const variants = {
        openDetail: {
            display: 'block',
            opacity: 1,
        },
        closeDetail: {
            opacity: 0,
            transitionEnd: {
                display: 'none',
            }
        }
    }

    const ResetInput = () => {
        setName('')
        setLastName('')
        setNumberPhone('')
    }

    const Login = () => {


        getDocs(query(collection(db, 'users'), where('numberPhone', '==', numberPhone))).then((snapshot) => {
            let data = snapshot.docs.map((item) => {
                return {
                    ...item.data(),
                    id: item.id
                }
            })


            if (data && data.length > 0) {
                localStorage.setItem('userDetail', JSON.stringify({ docId: data[0].id, name: data[0].name }))
                setLoadingLogin(true)
                setTimeout(() => {
                    window.location.href = '/'
                    setLoadingLogin(false)
                }, 1000)
            } else {
                playtwoAlert.fire({ open: true, title: `เบอร์โทรศัพท์ไม่ถูกต้อง`, confirmText: 'เข้าใจแล้ว' }, () => {
                    console.log("Hide")
                    ResetInput()
                })
            }
        })
    }

    const Register = () => {
        getDocs(query(collection(db, 'users'), where('numberPhone', '==', numberPhone))).then((snapshot) => {
            let data = snapshot.docs.map((item) => {
                return {
                    ...item.data(),
                    id: item.id
                }
            })

            console.log(data)

            if (data && data.length > 0) {

                playtwoAlert.fire({ open: true, title: `ขออภัยเบอร์โทรนี้เป็นสมาชิกแล้ว`, confirmText: 'เข้าใจแล้ว' }, () => {
                    console.log("Hide")
                    ResetInput()
                })

            } else {

                let payloadUserData


                if (lineUser && lineUser.length > 0) {
                    payloadUserData = {
                        name: name,
                        lastName: lastName,
                        numberPhone: numberPhone,
                        lineId: lineUser[0].userId,
                        lineName: lineUser[0].displayName,
                        linePicture: lineUser[0].pictureUrl
                    }
                } else {
                    if (refURL) {

                        console.log("RefURL : ", refURL)
                        setReferral(refURL)

                        let refUser = getDoc(doc(db, 'users', refURL)).then((res) => {
                            return {
                                ...res.data(),
                                id: res.id
                            }
                        })

                        if (refUser) {
                            payloadUserData = {
                                name: name,
                                lastName: lastName,
                                numberPhone: numberPhone,
                                owner_ref: refURL
                            }
                        }
                    } else {
                        payloadUserData = {
                            name: name,
                            lastName: lastName,
                            numberPhone: numberPhone,
                            owner_ref: referral
                        }
                    }
                }

                console.log(lineUser)
                console.log(payloadUserData)

                addDoc(collection(db, 'users'), payloadUserData).then((res) => {
                    localStorage.setItem('userDetail', JSON.stringify({ docId: res.id, name: name }))
                    localStorage.setItem('line_logged', 1)

                    playtwoAlert.fire({ open: true, title: `สมัครสมาชิกเสร็จสิ้น`, confirmText: 'เข้าใจแล้ว' }, () => {
                        ResetInput()
                    })

                    // Change to Wanfah

                    setTimeout(() => {
                        window.location.href = '/inventory'
                    }, 1000)
                })

            }
        })
    }


    const init = async () => {

        const getStorage = await localStorage.getItem("userDetail")
        const userJson = JSON.parse(getStorage)

        if (refURL) {

            if (!userJson) {
                setModal(true)
                setStatusModal('register')
            } else {

                const user = await getDoc(doc(db, 'users', userJson.docId)).then((res) => {
                    let payload = {
                        ...res.data(),
                        userId: res.id
                    }

                    console.log("Check Inventory : ", payload)

                    return payload
                })

                

                if (!user.owner_ref) {
                    if (userJson.docId == refURL) {
                        navigate('/profile')
                        localStorage.setItem('bottom', 'home')
                        playtwoAlert.fire({ open: true, title: 'ไม่สามารถเพิ่มลิ้งแนะนำของตัวเองได้', dialog: false, confirmText: 'เข้าใจแล้ว' }, () => {
                            console.log("Ok")
                        })
                    } else {
                        updateDoc(doc(db, 'users', userJson.docId), {
                            owner_ref: refURL
                        }).then(() => {
                            navigate('/profile')
                            localStorage.setItem('bottom', 'home')
                            playtwoAlert.fire({ open: true, title: 'อัพเดทผู้แนะนำสำเร็จ', dialog: false, confirmText: 'เข้าใจแล้ว' }, () => {
                                console.log("Ok")
                            })
                        })
                    }
                } else {
                    navigate('/profile')
                    localStorage.setItem('bottom', 'home')
                    playtwoAlert.fire({ open: true, title: 'มีคนแนะนำอยู่แล้ว', dialog: false, confirmText: 'เข้าใจแล้ว' }, () => {
                        console.log("Ok")
                    })
                }
            }

        } else {


            onSnapshot(doc(db, 'admin_panel', 'tbW2GFM9UV0UKUCA6StW'), (snapshot) => {
                setUserCheck(snapshot.data().turnon_check_lottery)
                setAnnounce(snapshot.data().announce)
            })

            if (userJson) {
                const user = await getDoc(doc(db, 'users', userJson.docId)).then((res) => {
                    let payload = {
                        ...res.data(),
                        userId: res.id
                    }

                    return payload
                })

                const userOrder = await getDocs(query(collection(db, 'orders'), where("userId", '==', user.userId))).then((res) => {
                    let payload = res.docs.map((item) => {
                        return {
                            ...item.data(),
                            orderId: item.id
                        }
                    })

                    return payload
                })

                console.log(userOrder)

                setUserDetail(user)
                setOrder(userOrder)
            }
        }

    }

    const initLiff = async () => {
        return new Promise(async (resolve, reject) => {
            try {
                await liff.init({ liffId: LineLiffId, withLoginOnExternalBrowser: false })

                console.log("liff")

                if (liff.isLoggedIn()) {
                    resolve(liff.isLoggedIn())
                }
            } catch (err) {
                console.log(err)
            }
        })
    }

    const initLine = async () => {
        try {
            if (!userStorage) {

                let profileLine = await liff.getProfile()

                getDocs(query(collection(db, 'users'), where('lineId', '==', profileLine.userId))).then((res) => {
                    let data = res.docs.map((item) => {
                        return {
                            ...item.data(),
                            userId: item.id
                        }
                    })

                    console.log(data)

                    if (data && data.length > 0) {
                        localStorage.setItem('line_logged', 1)
                        localStorage.setItem('userDetail', JSON.stringify({ docId: data[0].userId, name: data[0].name }))
                        window.location.reload(true)
                        setLoadingLogin(false)

                    } else {
                        console.log(profileLine)
                        setLineUser([profileLine])
                        setLine(true)
                        setStatusModal('register')
                        setModal(true)
                        setLoadingLogin(false)
                    }

                })
            }
        } catch (err) {
            console.log(err)
        }
    }

    if (order && order.length > 0) {

        let array = []

        order.map((item) => {
            item.cart.map((items) => {
                array.push(items)
            })
        })

        let data = array.reduce((total, current) => {
            return total = total + current.quantity
        }, 0)

        totalQuantity = data
    }


    const allLineLogin = async () => {

        let logged = await initLiff()

        if (logged) {
            await initLine()
        } else {
            liff.login()
        }
    }

    console.log("ResultReward ; ", resultReward)

    let totalwin

    if (resultReward.length > 0) {
        totalwin = resultReward.reduce((total, current) => {
            return total = total + (Number(current.price) * current.quantity)
        }, 0)
    }

    let [loading, setLoading] = useState(false)
    let [showDetail, setShowDetail] = useState(false)
    let [winModal, setWinModal] = useState(false)
    let [loadWin, setLoadWin] = useState(false)
    let [check, setCheck] = useState(false)
    let [modalNumpad, setModalNumpad] = useState(false)

    let tu = localStorage.getItem('tu') == 'true'


    console.log("UserDetail : ", userDetail)


    let months_th_mini = ["ม.ค.", "ก.พ.", "มี.ค.", "เม.ย.", "พ.ค.", "มิ.ย.", "ก.ค.", "ส.ค.", "ก.ย.", "ต.ค.", "พ.ย.", "ธ.ค.",];

    let months_th = ["มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน", "พฤษภาคม", "มิถุนายน", "กรกฎาคม", "สิงหาคม", "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม",];


    useEffect(() => {

        if (refURL) {
            setReferral(refURL)
        }

        if(state && state.banner_pass){
            setModal(true)
        }

        init()
        allLineLogin()

        let bottom = localStorage.getItem('bottom')

        if (!bottom) {
            localStorage.setItem('bottom', 'inventory')
        }
    }, [])

    return (
        <div>

            <Alert />


            {loading || loadWin ? <div className="w-[100%] h-[100%] fixed flex justify-center items-center z-[10]">
                <Lottie animationData={LoadingPage} loop={true} />
            </div> : null}

            <motion.div onClick={(e) => {
                if (e.target == e.currentTarget) {
                    setShowLotteryResult(false)
                }
            }} initial={{ opacity: 0 }} animate={showLotteryResult && loading == false ? { opacity: 1 } : { opacity: 0 }} className={`w-[100%] h-[100%] fixed flex justify-center items-center bg-[rgba(0,0,0,0.8)] z-[9] ${showLotteryResult && loading == false ? 'pointer-events-auto' : 'pointer-events-none'}`}>
                {/* <Lottie animationData={fireworks} loop={5} className="absolute" /> */}
                {/* <Lottie animationData={snow} loop={} className="absolute right-[-50px]"></Lottie> */}

                <motion.div initial={{ opacity: 0 }} animate={winModal && !loadWin ? { opacity: 1 } : { opacity: 0 }} className={`w-[350px] h-[250px] bg-white fixed flex justify-center items-center z-[10] rounded-[12px] ${winModal && !loadWin ? 'pointer-events-auto' : 'pointer-events-none'} shadow-[0px_0px_10px_10px_rgba(255,255,255,0.15)] `}>
                    <div className="flex flex-col items-center">
                        <p className="font-bold text-green-600 text-[20px]">สำเร็จ</p>
                        <p className="font-light text-orange-400">กรุณารอ 5 - 15 นาที</p>
                        <p className="font-light">เจ้าหน้าทีจะทำการติดต่อไป</p>
                        <div className="text-center mt-[20px]">
                            <p className="font-light">กรุณาตรวจสอบเลขที่บัญชีของท่าน</p>
                            <p className="font-light">เพื่อรับเงินรางวัล</p>
                        </div>
                        <div onClick={() => {
                            window.location.href = '/profile'
                            setShowLotteryResult(false)
                            setWinModal(false)
                        }} className="p-[7px] w-[100px] rounded-[12px] bg-blue-500 flex justify-center items-center mt-[20px]">
                            <p className="font-medium text-white">ตกลง</p>
                        </div>
                    </div>
                </motion.div>

                {resultReward.length > 0 ? <div>
                    <img className="absolute" src="./gifs/firework.gif"></img>
                    <div className="flex flex-col items-center">
                        <p className="font-medium text-[25px] text-white">ผลสลาก</p>
                        <p className="font-light text-[20px] text-white">วันที่ {announce.split('-')[0]} {months_th[announce.split('-')[1] - 1]} {announce.split('-')[2]}</p>
                        <div className="w-[390px] relative">
                            <img src="./imgs/win.png"></img>
                            <p className="absolute top-[170px] font-medium left-[145px]">คุณถูกรางวัล {resultReward.length} ใบ</p>
                            <div className="w-[165px] h-[50px] absolute left-[120px] top-[210px] rounded-[12px] flex justify-center items-center">
                                <p className="top-[215px] font-bold left-[165px] text-[32px] text-[#fab600]">{Number(totalwin).toLocaleString('th-TH')}</p>
                            </div>
                            <p className="absolute top-[260px] font-medium left-[190px] text-[20px] text-[#fab600]">บาท</p>
                        </div>

                        <button onClick={() => {

                            // User Send Line

                            // console.log("Click")
                            // setLoadWin(true)
                            // setTimeout(() => {
                            //     setLoadWin(false)

                            //     let payload = {
                            //         name: userDetail.name,
                            //         lastName: userDetail.lastName,
                            //         numberPhone: userDetail.numberPhone,
                            //         amountWin: totalwin,
                            //         lotteryWin: JSON.stringify(resultReward),
                            //         userId: userDetail.userId
                            //     }

                            //     // console.log("Payload : ", payload)

                            //     axios.post("https://wanfah-server-production.up.railway.app/api/winLottery", payload).then((res) => {
                            //         if (res.data) {
                            //             console.log({ status: 200 })
                            //         }
                            //     })

                            //     // updateDoc()

                            // }, 2000)

                            setWinModal(true)
                        }} className="font-medium text-white p-[10px] bg-green-600 rounded-[12px] mb-[40px] mt-[-30px] text-[20px] z-[9]">กดเพื่อรับเงิน</button>

                        <div className="overflow-y-scroll">
                            {resultReward.length > 0 ? resultReward.map((item) => {
                                console.log("Check : ", item)
                                return (
                                    <div className="w-[300px]">
                                        <p className="font-medium text-white text-[24px]">{item.userNumber} {item.text}</p>
                                        <p className="font-light text-white">จำนวนเงิน {Number(item.price).toLocaleString('th')} บาท ({item.quantity} ใบ)</p>
                                    </div>
                                )
                            }) : null}
                        </div>
                    </div>
                </div> : <div className="flex justify-center items-center flex-col">
                    <p className="font-medium text-white text-[30px]">เสียใจด้วยคุณไม่ถูกรางวัล</p>
                    <p className="font-light text-white text-[25px]">งวดหน้าลองใหม่</p>
                    <img src="./gifs/cry.gif"></img>
                    <div className="bg-blue-400 p-[5px] text-white rounded-[100px] text-[25px] font-medium w-[150px] text-center" onClick={() => {
                        setShowLotteryResult(false)
                    }}>ตกลง</div>

                </div>}
            </motion.div>


            {loadingLogin ? <div className="fixed w-[100%] h-[100%] flex justify-center items-center">
                <Lottie className="w-[50%] z-20" animationData={LoadingPage} />
            </div> : null}

            {modal ? <div onClick={(e) => {
                if (!refURL) {
                    if (e.target == e.currentTarget) {
                        setModal(false)
                        setModalNumpad(false)
                        setNumberPhone('')
                    }
                }
            }} className="bg-[rgba(0,0,0,0.6)] w-[100%] h-[100%] fixed z-[999] flex justify-center items-center">
                {statusModal == 'login' ? <div className="w-[350px] h-[350px] bg-white p-[20px] rounded-[20px] flex justify-center flex-col border-b-4 border-blue-400">

                    <div className="flex">
                        <div>
                            <p className="font-medium text-[25px]">เข้าสู่ระบบ</p>
                            <p className="font-light text-[16px] whitespace-nowrap">วาฬฟ้ายินดีต้อนรับ สลาก 80 บาท</p>
                        </div>
                        <img className="w-[100px]" src={"./sticker/1.png"}></img>
                    </div>
                    {/* <div className="flex gap-[20px] mt-[20px]">
                        <div className="flex gap-[10px] flex-col">
                            <p className="font-light">ชื่อจริง (ไม่มีคำนำหน้า)</p>
                            <input value={name} onChange={(e) => {
                                setName(e.target.value)
                            }} required className="w-[100%] h-[50px] rounded-[8px] border-[1.5px] border-[gray] indent-[4px] font-light" placeholder="xxxxx"></input>
                        </div>
                        <div className="flex gap-[10px] flex-col">
                            <p className="font-light">นามสกุล</p>
                            <input value={lastName} onChange={(e) => {
                                setLastName(e.target.value)
                            }} required className="w-[100%] h-[50px] rounded-[8px] border-[1.5px] border-[gray] indent-[4px] font-light" placeholder="xxxxx"></input>
                        </div>
                    </div> */}
                    <div className="flex gap-[10px] flex-col mt-[0px]">
                        <div className="flex items-center gap-[10px]">
                            <p className="font-medium">เบอร์โทรศัพท์</p>
                            {numberPhone.length > 0 ? <p className={`font-light ${numberPhone.length == 10 ? 'text-green-600' : 'text-red-600'}  text-[14px]`}>{numberPhone.length == 10 ? '(เบอร์โทรศัพท์ ถูกต้อง)' : '(เบอร์โทรศัพท์ ไม่ถูกต้อง)'}</p> : null}
                        </div>
                        <input onClick={() => {
                            setModalNumpad(true)
                        }} type="number" value={numberPhone} onChange={(e) => {
                            setNumberPhone(e.target.value.substring(0, 10))
                        }} className="w-[100%] h-[50px] rounded-[8px] border-b-[2px] border-blue-400 font-medium text-center tracking-widest outline-none focus:ring-4 text-[24px]" placeholder="เบอร์โทรศัพท์"></input>
                    </div>

                    <div className="w-[100%] flex justify-center flex-col items-center gap-[10px] mt-[10px]">
                        <button disabled={numberPhone.length != 10 ? true : false} onClick={() => {
                            Login()
                        }} className={`p-[10px] w-[150px] ${numberPhone.length == 10 ? 'bg-blue-400' : 'bg-blue-200'} rounded-[8px] text-white font-medium text-[16px] mt-[20px]`}>เข้าสู่ระบบ</button>

                        <p onClick={() => {
                            setStatusModal('register')
                            setNumberPhone('')
                        }} className="font-light underline">สมัครสมาชิก</p>
                    </div>
                </div> : <div className={`${refURL || line ? 'fixed w-[100%] h-[100%] rounded-[0px]' : 'w-[350px] rounded-[20px]'} bg-white p-[20px] flex justify-center flex-col border-b-[4px] border-b-blue-400`}>
                    <p className="font-medium text-[20px]">สมัครบัญชี</p>
                    <div className="flex items-center gap-[20px]">
                        <p className="font-light text-[14px]">วาฬฟ้ายินดีต้อนรับ หวย 80 บาท</p>
                        <img className="w-[100px]" src={refURL ? "../logonew1.png" : "./logonew1.png"}></img>
                    </div>
                    <div className="flex gap-[20px] mt-[20px]">
                        <div className="flex gap-[10px] flex-col">
                            <div className="flex w-[150px] relative">
                                <p className="font-light">ชื่อจริง</p>
                                <p className="font-light absolute right-[5px] text-red-600">(ไม่มีคำนำหน้า)</p>
                            </div>
                            <input value={name} onChange={(e) => {
                                setName(e.target.value)
                            }} required className="w-[100%] h-[50px] rounded-[8px] border-b-[1.5px] border-b-blue-400 outline-none indent-[4px] font-light" placeholder="กรอกชื่อ"></input>
                        </div>
                        <div className="flex gap-[10px] flex-col">
                            <p className="font-light">นามสกุล</p>
                            <input value={lastName} onChange={(e) => {
                                setLastName(e.target.value)
                            }} required className="w-[100%] h-[50px] rounded-[8px] border-b-[1.5px] border-b-blue-400 outline-none indent-[4px] font-light" placeholder="กรอกนามสกุล"></input>
                        </div>
                    </div>
                    <div className="flex gap-[10px] flex-col mt-[20px]">
                        <div>
                            <p className="font-light">เบอร์โทรศัพท์ (ไม่ต้องใส่ขีด)</p>
                            <p className={`font-light ${numberPhone.length == 10 ? 'text-green-600' : 'text-red-600'}  text-[14px]`}>{numberPhone.length == 10 ? 'เบอร์โทรศัพท์ ถูกต้อง' : 'เบอร์โทรศัพท์ ไม่ถูกต้อง'}</p>
                        </div>
                        <input value={numberPhone} type="number" onChange={(e) => {
                            setNumberPhone(e.target.value.substring(0, 10))
                        }} className="w-[100%] h-[50px] rounded-[8px] border-b-[1.5px] border-b-blue-400 outline-none indent-[4px] font-light text-center" placeholder="เบอร์โทรศัพท์"></input>
                    </div>

                    <div className="flex gap-[10px] flex-col mt-[20px]">
                        <div className="flex items-center gap-[10px]">
                            <p className="font-light">รหัสแนะนำเพื่อน</p>
                            <p className="font-light text-[14px]">(ไม่จำเป็นต้องใส่)</p>
                        </div>
                        <div className="relative">
                            <input disabled={refURL ? true : false} value={referral} onChange={(e) => {
                                setReferral(e.target.value)
                            }} className={`w-[100%] h-[50px] rounded-[8px] border-b-[1.5px] border-b-blue-400 outline-none indent-[4px] font-light text-center ${refURL ? 'text-gray-400' : ''}`} placeholder="กรอกรหัสแนะนำเพื่อน"></input>
                            {refURL ? <FaLock color="gray" className="absolute right-[10px] top-1/2 translate-y-[-50%]" /> : null}
                        </div>
                    </div>

                    <div className="w-[100%] flex justify-center flex-col items-center gap-[10px] mt-[10px]">
                        <button disabled={!numberPhone ? true : false} onClick={async () => {

                            // Just Check The referral code is it real referral
                            if (referral) {
                                let numberRef = /^\d{10}$/
                                if (numberRef.test(referral)) {
                                    // Find Numberphone document
                                    let ReturnUser = await getDocs(query(collection(db, 'users'), where('numberPhone', '==', referral))).then((res) => {
                                        let data = res.docs.map((item) => {
                                            return {
                                                ...item.data(),
                                                userId: item.id
                                            }
                                        })


                                        return data
                                    })


                                    if (ReturnUser && ReturnUser.length > 0) {
                                        if (numberPhone.length == 10) {
                                            Register()
                                        } else {
                                            playtwoAlert.fire({ open: true, title: 'กรุณาตรวจสอบเบอร์โทร', dialog: false, confirmText: 'เข้าใจแล้ว' }, () => {
                                                console.log("Ok")
                                            })
                                        }
                                    } else {
                                        playtwoAlert.fire({ open: true, title: 'ไม่พบเบอร์ที่แนะนำ', dialog: false, confirmText: 'เข้าใจแล้ว' }, () => {
                                            console.log("Ok")
                                        })
                                    }
                                } else {
                                    // Find with userId document


                                    let userReferral = await getDoc(doc(db, 'users', referral)).then((snapshot) => {
                                        let data = {
                                            ...snapshot.data(),
                                            userId: snapshot.id
                                        }

                                        return data
                                    })

                                    if (userReferral && userReferral.numberPhone) {
                                        Register()
                                    } else {
                                        playtwoAlert.fire({ open: true, title: 'ไม่พบโทเคนที่แนะนำ', dialog: false, confirmText: 'เข้าใจแล้ว' }, () => {
                                            console.log("Ok")
                                        })
                                    }
                                }
                            } else {
                                Register()
                            }
                        }} className={`p-[10px] w-[150px] ${numberPhone ? 'bg-blue-400 ' : 'bg-blue-200 '} rounded-[8px] text-white font-medium text-[16px] mt-[20px]`}>สมัครสมาชิก</button>



                        <p onClick={() => {
                            setStatusModal('login')
                            setNumberPhone('')
                            navigate('/inventory')
                        }} className="font-light underline">เข้าสู่ระบบ</p>
                    </div>
                </div>}

                {/* {modalNumpad && tu ? <div className="fixed bottom-[50px]">
                    <Numpad setNumberPhone={setNumberPhone} numberPhone={numberPhone} />
                </div> : null} */}

            </div> : null}

            <LotteryHeader />
            <div className="p-[10px] flex justify-center flex-col items-center pt-[100px]">
                <h1 className="font-medium text-[25px]">กระเป๋าของฉัน</h1>
                <p className="font-medium text-[20px] mb-[20px]">สั่งซื้อสำเร็จ ออเดอร์ ({totalQuantity} ใบ)</p>


                {userStorage && userDetail ? !userDetail.transaction ?
                    <div onClick={async () => {
                        console.log("1")

                        if (userCheck) {

                            console.log("2")

                            setLoading(true)

                            setTimeout(() => {
                                setLoading(false)
                            }, 5000)

                            setShowLotteryResult(true)

                            let userDetail = JSON.parse(localStorage.getItem('userDetail'))

                            let arrayLottery = []
                            let sortLotteryReward = []
                            let arrayUserLottery = []
                            let result = []
                            setResultReward([])


                            let lottery_result = await axios.get('https://wanfah-server-production.up.railway.app/api/getLotteryResult').then((res) => {
                                return res.data
                            })

                            let reward = Object.entries(lottery_result.data.response.data)
                            let data = Object.entries(lottery_result.data.response.data)

                            // arrayLottery = lottery_result.data.response.data

                            reward.map((item) => {
                                item[1].number.map((items) => {
                                    sortLotteryReward.push({ ...items, reward: item[0], price: item[1].price })
                                })
                            })

                            // let orderUser = await getDocs(query(collection(db, 'orders'), where('userId', '==', userDetail.docId))).then((res) => {
                            //     let data = res.docs.map((item) => {
                            //         return {
                            //             ...item.data(),
                            //             orderId: item.id
                            //         }
                            //     })

                            //     return data
                            // })

                            // This order is pulling when page start

                            order.map((item) => {
                                item.cart.map((items) => {
                                    arrayUserLottery.push(items)
                                })
                            })


                            // console.log(arrayUserLottery)
                            // console.log(sortLotteryReward)

                            let find = arrayUserLottery.map((item) => {

                                console.log("User Cart : ", item)

                                let data = sortLotteryReward.filter((itemGov) => {
                                    return item.number.includes(itemGov.value)
                                })

                                let payload = {
                                    ...data.shift(),
                                    userNumber: item.number,
                                    quantity: item.quantity //ซื้อหวยกี่ใบต่อ 1 order
                                }

                                return payload
                            })

                            console.log("Find Something : ", find)



                            find.map((item) => {

                                if (item.reward == "first") {
                                    if (item.userNumber == item.value) {
                                        let payload = {
                                            ...item,
                                            text: 'ถูกรางวัลที่ 1'
                                        }
                                        console.log('ถูกรางวัลที่ 1', item.userNumber)
                                        setResultReward((prev) => [...prev, payload])
                                    }
                                }

                                if (item.reward == "second") {
                                    if (item.userNumber == item.value) {
                                        let payload = {
                                            ...item,
                                            text: 'ถูกรางวัลที่ 2'
                                        }
                                        console.log('ถูกรางวัลที่ 2', item.userNumber)
                                        setResultReward((prev) => [...prev, payload])
                                    }
                                }

                                if (item.reward == 'third') {
                                    if (item.userNumber == item.value) {
                                        let payload = {
                                            ...item,
                                            text: 'ถูกรางวัลที่ 3'
                                        }
                                        console.log('ถูกรางวัลที่ 3', item.userNumber)
                                        setResultReward((prev) => [...prev, payload])
                                    }
                                }

                                if (item.reward == 'fourth') {
                                    if (item.userNumber == item.value) {
                                        let payload = {
                                            ...item,
                                            text: 'ถูกรางวัลที่ 4'
                                        }
                                        console.log('ถูกรางวัลที่ 4', item.userNumber)
                                        setResultReward((prev) => [...prev, payload])
                                    }
                                }

                                if (item.reward == 'fifth') {
                                    if (item.userNumber == item.value) {
                                        let payload = {
                                            ...item,
                                            text: 'ถูกรางวัลที่ 5'
                                        }
                                        console.log('ถูกรางวัลที่ 5', item.userNumber)
                                        setResultReward((prev) => [...prev, payload])
                                    }
                                }

                                if (item.reward == 'last2') {

                                    let userNum1 = item.userNumber[4]
                                    let userNum2 = item.userNumber[5]
                                    let valueNum1 = item.value[0]
                                    let valueNum2 = item.value[1]

                                    let combine = userNum1.toString() + userNum2.toString()

                                    if (combine == item.value) {

                                        let payload = {
                                            ...item,
                                            text: 'ถูกรางวัล 2 ตัวท้าย'
                                        }

                                        console.log('ถูกรางวัล 2 ตัวท้าย', item.userNumber)
                                        setResultReward((prev) => [...prev, payload])
                                    }
                                }

                                if (item.reward == 'last3f') {
                                    let userNum1 = item.userNumber[0]
                                    let userNum2 = item.userNumber[1]
                                    let userNum3 = item.userNumber[2]

                                    let combine = userNum1.toString() + userNum2.toString() + userNum3.toString()


                                    if (combine == item.value) {
                                        let payload = {
                                            ...item,
                                            text: 'ถูกรางวัลเลขหน้า 3 ตัว'
                                        }

                                        console.log('ถูกรางวัลหน้า 3 ตัว', item.userNumber)
                                        setResultReward((prev) => [...prev, payload])
                                    }

                                }

                                if (item.reward == 'last3b') {
                                    let userNum1 = item.userNumber[3]
                                    let userNum2 = item.userNumber[4]
                                    let userNum3 = item.userNumber[5]

                                    let combine = userNum1.toString() + userNum2.toString() + userNum3.toString()


                                    if (combine == item.value) {

                                        let payload = {
                                            ...item,
                                            text: 'ถูกรางวัลเลขท้าย 3 ตัว'
                                        }

                                        console.log('ถูกรางวัลหลัง 3 ตัว', item.userNumber)
                                        setResultReward((prev) => [...prev, payload])
                                    }

                                }

                                if (item.reward == 'near1') {
                                    if (item.userNumber == item.value) {

                                        let payload = {
                                            ...item,
                                            text: 'ถูกรางวัลใกล้เคียงรางวัลที่ 1'
                                        }

                                        console.log('ถูกรางวัลใกล้เคียง', item.userNumber)
                                        setResultReward((prev) => [...prev, payload])
                                    }
                                }
                            })
                        } else {
                            playtwoAlert.fire({ open: true, title: `รอตรวจสลาก ${Number(announce.split('-')[0])} ${months_th_mini[Number(announce.split('-')[1] - 1)]} ${announce.split('-')[2]} ${announce.split('-')[3]} น`, dialog: false, confirmText: 'เข้าใจแล้ว' }, () => {
                                console.log("Ok")
                            })
                        }

                    }} className={`bg-blue-500 pointer-events-auto text-white font-medium rounded-[8px] flex items-center gap-[20px] mb-[20px] p-[10px]`}>
                        <div className="flex justify-center flex-col items-center">
                            <p className="text-[20px]">{userDetail && !userDetail.transaction ? 'ตรวจ สลาก และ รับเงิน' : null}</p>
                            <p>( กดที่นี่ )</p>
                        </div>
                        <div className="p-[10px] w-[50px] bg-white rounded-full">
                            <img className="w-[100%] h-[100%]" src="/svgs/money.svg"></img>
                        </div>
                    </div> :

                    <div className={`p-[10px] ${userDetail && userDetail.transaction ? 'bg-green-500 pointer-events-none' : 'bg-blue-400 pointer-events-auto'} text-white font-medium rounded-[8px] flex items-center gap-[20px] mb-[20px]`}>
                        <div className="flex-col flex text-center">
                            <p className="font-medium text-[20px]">โอนเงินเข้าสำเร็จ</p>
                            <p className="font-light text-[14px]">🏮ขอให้เฮงๆรวยๆตลอดไป🏮</p>
                        </div>
                        <div className="p-[10px] w-[50px] bg-white rounded-full">
                            <img className="w-[100%] h-[100%]" src="/svgs/money.svg"></img>
                        </div>
                    </div> : null}
                {/* Lottery Inventory */}

                <div className="w-[100%] flex gap-[30px] flex-col justify-center items-center">
                    {userStorage ? order && order.length > 0 ? order.map((item, index) => {
                        console.log("Find : ", orderDetailId, item.lotteryId)
                        let totalPrice = item.cart.reduce((keep, current) => {
                            return keep = (keep + (current.price * current.quantity))
                        }, 0)


                        let totalQuantity = item.cart.reduce((total, cartItem) => {
                            return total = total + cartItem.quantity
                        }, 0)

                        return (
                            <div key={index} className="w-[350px] sm:w-[30%] bg-white rounded-[12px] relative flex flex-col gap-[10px] p-[10px] shadow-[0px_5px_5px_0px_rgba(0,0,0,0.15)] min-[500px]:w-[400px] min-[800px]:w-[500px]">
                                {item.cart && item.cart.length > 0 ? item.cart.map((items) => {
                                    // console.log(typeof (items.img))
                                    return (
                                        <div>
                                            {typeof (items.img) == 'object' ? items.img.map((img, index) => {
                                                return (
                                                    <img className={`w-[100%] h-[200px] rounded-[12px] shadow-[0px_0px_5px_0px_rgba(0,0,0,0.4)] mt-[${index > 0 ? -130 : 0}px]`} src={img}></img>
                                                )
                                            }) : <img className="w-[100%] h-[200px] rounded-[12px] shadow-[0px_0px_5px_0px_rgba(0,0,0,0.4)]" src={items.img}></img>}
                                        </div>
                                    )
                                }) : null}
                                <div className="mt-[10px] pl-[20px]">
                                    <div className="flex items-center gap-[10px] justify-between">
                                        <p className="font-light">สถานะการสั่งซื้อ : </p>
                                        <div className="flex items-center justify-center gap-[10px]">
                                            <div className="relative">
                                                <p className="font-medium">{item.confirm_slip ? 'ยืนยันการสั่งซื้อสำเร็จ' : 'รอยืนยันการสั่งซื้อ'}</p>
                                                <p className="font-light text-gray-400 absolute text-[12px] right-0">{item.confirm_slip ? null : '1-2 นาที'}</p>
                                            </div>
                                            <div className={`w-[15px] h-[15px] ${item.confirm_slip ? 'bg-green-500' : 'bg-orange-500'} rounded-full`}></div>
                                        </div>
                                    </div>
                                    <div className="flex justify-between">
                                        <p onClick={() => {
                                            setDetailStatus(!detailStatus)
                                            setOrderDetailId(item.orderId)
                                        }} className="font-medium">ดูรายละเอียด</p>
                                        {/* <div onClick={() => {

                                        }} className="flex gap-[10px]">
                                            <FacebookShareButton url="https://wanfah.online" hashtag="#วาฬฟ้าลอตเตอรี่"><p className="font-light">แชร์ไปยัง facebook</p></FacebookShareButton>
                                            <FacebookIcon round={false} size={25} />
                                        </div> */}
                                    </div>
                                    <motion.div initial={{ display: 'none' }} variants={variants} animate={detailStatus && item.orderId == orderDetailId ? 'openDetail' : 'closeDetail'}>
                                        <p className="font-light">ราคาที่ซื้อ {totalPrice} บาท</p>
                                        <p className="font-light">{item.way == 'ems' ? 'ค่าส่ง 50 บาท' : null}</p>
                                        {item.discount ? <p className="font-[light]">ใช้โค้ดส่วนลด {item.discount.reduce} บาท</p> : null}
                                        <div className="flex justify-between mb-[10px]">
                                            <p className="font-light">ค่าตู้เซฟ {maintenance} บาท (ต่อ 1 ใบ)</p>
                                            <p className="font-medium mr-[20px]">รวม {item.way == 'ems' ? (totalPrice + (maintenance * totalQuantity)) + 50 : item.discount ? totalPrice + (maintenance * totalQuantity) - item.discount.reduce : totalPrice + (maintenance * totalQuantity)} บาท</p>
                                        </div>
                                    </motion.div>
                                </div>
                            </div>
                        )
                    }) : <p className="font-light">ยังไม่มีออเดอร์ที่สั่งซื้อ</p> : null}

                    {!userStorage ? <button onClick={() => {
                        setStatusModal('login')
                        setModal(true)
                    }} className="p-[10px] w-[150px] bg-blue-400 rounded-[8px] text-white font-medium text-[20px]">เข้าสู่ระบบ</button> : null}
                </div>


            </div>
            <div className="h-[100px]"></div>
            <LotteryFooter />
        </div>
    )
}


export default Inventory