import { useNavigate } from "react-router-dom"
import { useContext, useEffect, useState } from "react"
import { motion } from 'framer-motion'
import { context, menuContext } from "../../App"
import Alert from "../../Components/Alert"
import { liff } from "@line/liff"
import { addDoc, doc, getDoc } from "firebase/firestore"
import { analytics, db } from "../../firebase"
import { LineCallbackToEndpointConfig, LineLiffId } from "../../config"
import { getAnalytics, logEvent } from "firebase/analytics"

const LotteryHeader = ({ userProfile, Logout, title }) => {

    let { playtwoAlert } = useContext(context)
    let {setSidebarStatus, sidebarStatus} = useContext(menuContext)

    let user = JSON.parse(localStorage.getItem('userDetail'))

    let [userDetail, setUserDetail] = useState(null)


    let navigate = useNavigate()

    // console.log(userProfile)

    const variant = {
        open: {
            opacity: 1,
            height: 400
        },
        close: {
            opacity: 0,
            height: 0
        }
    }

    let [status_tabDropDown, setStatus_TabDropDown] = useState(false)


    const GetUser = async () => {

        let userJson = await JSON.parse(localStorage.getItem('userDetail'))

        if (userJson) {
            getDoc(doc(db, 'users', userJson.docId)).then((res) => {

                let payload = {
                    ...res.data(),
                    userId: res.id
                }

                setUserDetail(payload)
            })
        }
    }

    const LoginLine = async () => {

        await liff.init({ liffId: LineLiffId })

        liff.login({ redirectUri: LineCallbackToEndpointConfig })
    }


    useEffect(() => {
        GetUser()
    }, [])

    // console.log(userDetail)

    return (
        <div className="w-[100%] h-[80px] bg-gradient-to-r from-[#58C2F0] to-[#C9DFE9] flex justify-between items-center pl-[20px] pr-[20px] fixed z-[5] shadow-[0px_3px_5px_0px_rgba(0,0,0,0.2)]">


            <div className="flex justify-between w-[100%]">
                {/* <h2 className="font-[apple-bold] sm:text-[30px] text-[30px] text-white"></h2> */}
                <img onClick={() => {
                    window.location.href = '/'
                }} className="w-[100px] cursor-pointer" src="/imgs/logonew1.png"></img>


                <div className="flex items-center justify-center gap-[20px]">
                    {userDetail ? <div onClick={() => {
                        navigate('/profile')

                    }} className="flex justify-center items-center flex-col gap-[5px]">
                        {/* <p>{userDetail.userProfileImg ? userDetail.userProfileImg : userDetail.linePicture ? userDetail.linePicture : './svgs/avatar.svg'}</p> */}
                        {/* userDetail.linePicture ? userDetail.linePicture
                        userDetail.userProfileImg ? userDetail.userProfileImg */}
                        <img className="w-[40px] h-[40px] rounded-full" src={user && userDetail ? userDetail.linePicture ? userDetail.linePicture : userDetail.userProfileImg ? userDetail.userProfileImg : './svgs/avatar.svg' : null}></img>
                        <p className="font-medium text-[10px]">{user && userDetail ? userDetail.lineName ? userDetail.lineName : userDetail.name ? userDetail.name : 'ผู้ใช้ใหม่' : null}</p>
                    </div> : null}

                    {/* <button onClick={async() => {
                        await liff.init({ liffId: '2002359403-GyAyBA6l', withLoginOnExternalBrowser: false })

                        if (liff.isLoggedIn()) {
                            localStorage.removeItem('line_logged')
                            localStorage.removeItem('userDetail')
                            liff.logout()
                            setTimeout(() => {
                                window.location.reload(true)
                            }, 1000)
                        }
                    }}>Logout Line</button> */}


                    {!user ? <div onClick={async () => {
                        LoginLine()
                    }} className="w-[90px] h-[45px] border-2 border-transparent p-[0px] bg-[#16a34a] flex gap-[10px] shadow-[0px_0px_25px_5px_rgba(0,0,0,0.15)] rounded-[8px] justify-center items-center hover:bg-green-400">
                        <img className="w-[30px]" src={'./svgs/logo_line.svg'}></img>
                        <p className="font-light text-white text-[14px]">LINE</p>
                    </div> : null}

                    <img onClick={() => {
                        setSidebarStatus(!sidebarStatus)
                    }} className="w-[40px]" src="/svgs/hamburger.svg"></img>
                </div>
            </div>


            {/* <div className="sm:flex gap-[10px] hidden">
                {userProfile && userProfile.length < 1 ? <h4 onClick={() => {
                    navigate('/login')
                }} className="font-[apple-regular] text-white cursor-pointer">เข้าสู่ระบบ</h4> : <h4 className="font-[apple-regular] text-white cursor-pointer">บัญชีผู้ใช้ : {userProfile}</h4>}
                {userProfile && userProfile.length < 1 ? <h4 className="font-[apple-regular] text-white cursor-pointer">สมัครสมาชิก</h4> : null}
            </div> */}

            <motion.div initial={'close'} variants={variant} animate={sidebarStatus ? 'open' : 'close'} className={`w-[200px] fixed bg-white top-[75px] right-[10px] shadow-[0px_5px_5px_0px_rgba(0,0,0,0.15)] rounded-[12px] flex flex-col gap-[30px] p-[20px] ${sidebarStatus ? 'pointer-events-auto' : 'pointer-events-none'}`}>
                {/* <div className="w-[100%] flex items-center h-[100px] justify-between">
                    <img src="./imgs/logonew1.png" className="w-[100px] ml-[20px]"></img>
                    <p onClick={() => {
                        setSidebarStatus(false)
                    }} className="font-medium text-[24px] text-gray-500 mr-[20px]">X</p>
                </div> */}


                {!user ? <div onClick={() => {
                    navigate('/inventory')
                    setSidebarStatus(false)
                }} className="w-[100%] flex justify-center items-center">
                    <p className="font-medium text-[20px] text-gray-500">เข้าสู่ระบบ</p>
                </div> : null}

                {user ? <div onClick={() => {
                    navigate('/profile')
                    setSidebarStatus(false)
                }} className="w-[100%]flex justify-center items-center">
                    <p className="font-medium text-[20px] text-gray-500 text-center">{user.name ? user.name : 'โปรไฟล์'}</p>
                </div> : null}

                {userDetail ? userDetail.role == 'superadmin' ? <div onClick={() => {
                    navigate('/admin_console')
                    setSidebarStatus(false)
                }} className="w-[100%] flex justify-center items-center">
                    <p className="font-medium text-[20px] text-gray-500">แอดมิน</p>
                </div> : null : null}

                {userDetail ? userDetail.role == 'superadmin' ? <div onClick={() => {
                    navigate('/confirm_order')
                    setSidebarStatus(false)
                }} className="w-[100%] flex justify-center items-center">
                    <p className="font-medium text-[20px] text-gray-500">ยืนยันคำสั่งซื้อ</p>
                </div> : null : null}

                {user ? <div onClick={() => {
                    navigate('/history')
                    setSidebarStatus(false)
                }} className="w-[100%]flex justify-center items-center">
                    <p className="font-medium text-[20px] text-gray-500 text-center">ประวัติการสั่งซื้อ</p>
                </div> : null}

                {userDetail ? userDetail.role == 'superadmin' ? <div onClick={() => {
                    navigate('/conclusion')
                    setSidebarStatus(false)
                }} className="w-[100%] flex justify-center items-center">
                    <p className="font-medium text-[20px] text-gray-500">สรุปยอดขาย</p>
                </div> : null : null}

                {user && userDetail ? !userDetail.orderId ? <div disabled={sidebarStatus ? false : true} onClick={() => {
                    playtwoAlert.fire({ open: true, dialog: true, confirmText: 'ออกจากระบบ', cancelText: 'ยกเลิก', title: 'ต้องการออกจากระบบ?' }, () => {
                        // localStorage.clear()
                        localStorage.removeItem('logged')
                        localStorage.removeItem('state')
                        localStorage.removeItem('cart')
                        localStorage.removeItem('userDetail')
                        localStorage.removeItem('way')
                        localStorage.removeItem('bottom')
                        localStorage.removeItem('showed_whale')
                        localStorage.removeItem('expireCart')
                        localStorage.removeItem('discount')
                        window.location.reload(true)
                    })
                }} className="w-[100%] h-[30px] flex justify-center items-center">
                    <p className="font-medium text-[20px] text-gray-500">ออกจากระบบ</p>
                </div> : null : null}
            </motion.div>

            {/* Hamburger Bar */}

            {/* <div onClick={(e) => {
                if (e.target != e.currentTarget) {
                    // setAlert(false)
                }
            }} className="sm:hidden text-[60px] block">
                <h4 onClick={() => {
                    setStatus_TabDropDown(!status_tabDropDown)
                }} className="font-[apple-regular] text-white cursor-pointer">=</h4>
            </div> */}

            {/* <motion.div initial={{ display: 'none' }} animate={status_tabDropDown ? "open" : "close"} variants={variant} className="w-[200px] h-[300px] bg-[white] rounded-[8px] absolute right-[10px] top-[70px] shadow-[_0px_0px_15px_10px_rgba(0,0,0,0.15) z-[1]">
                {userProfile && userProfile.length < 1 ? <div onClick={() => {
                    navigate('/login')
                }} className="w-[100%] h-[50px] flex justify-center items-center cursor-pointer">
                    <p className="font-[apple-regular]">เข้าสู่ระบบ</p>
                </div> : <div className="w-[100%] h-[50px] flex justify-center items-center">
                    <p className="font-[apple-regular]">{userProfile}</p>
                </div>}
                <div className="w-[100%] h-[50px] flex justify-center items-center">
                    <p className="font-[apple-regular]">อื่นๆ</p>
                </div>
                <div onClick={()=>{
                    navigate('/admin')
                }} className="w-[100%] h-[50px] flex justify-center items-center">
                    <p className="font-[apple-regular]">แอดมิน</p>
                </div>
                {userProfile && userProfile.length > 0 ? <div onClick={() => {
                    // setAlert(true)
                    playtwoAlert.fire({
                        open: true,
                        title: "คุณต้องการออกจากระบบ?",
                        confirmText: 'ออกจากระบบ',
                        cancelText: 'ยกเลิก'
                    }, () => {
                        Logout()
                    })
                }} className="w-[100%] h-[50px] flex justify-center items-center cursor-pointer">
                    <p className="font-[apple-regular]">ออกจากระบบ</p>
                </div> : null}
            </motion.div> */}

            {/* Hamburger Bar */}
        </div>
    )
}

export default LotteryHeader