import { LineChart, Line, AreaChart, Legend, Tooltip, XAxis, YAxis, CartesianGrid, ResponsiveContainer } from 'recharts'

const Conclusion = () => {

    let data = [
        {
            round: 'งวด 1',
            date: '31/ธค/2566',
            total: 80
        },
        {
            round: 'งวด 2',
            date: '17/มค/2567',
            total: 125
        },
        {
            round: 'งวด 3',
            date: '1/กพ/2567',
            total: 180
        },
        {
            round: 'งวด 4',
            date: '16/กพ/2567',
            total: 270
        },
        {
            round: 'งวด 5',
            date: '01/มค/2567',
            total: 200
        }
    ]

    return (
        <div className='p-[10px]'>
            <p className='font-light text-center text-[24px] mb-[20px] mt-[20px]'>สรุปยอดขาย</p>
            <ResponsiveContainer width={'100%'} height={200} className={'p-[0px] absolute left-[-15px]'}>
                <LineChart width={'100%'} height={200} data={data}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis className='font-medium text-[15px]' dataKey='date'/>
                    <YAxis></YAxis>
                    {/* <Legend/> */}
                    <Tooltip/>
                    <Line type="monotone" dataKey="total" stroke="#8884d8" />
                </LineChart>
            </ResponsiveContainer>
        </div>
    )
}

export default Conclusion