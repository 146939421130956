// กำหนดเวลาตะกร้า
// set Expire time cart

export const expireCartTimeConfig = 10

// กำหนดเวลาหน้าจ่ายเงิน
// set Expire time payment
export const expirePaymentTimeConfig = 15

// เปิดปิดแจ้งเตือน Line
// Notification Line
export const LineNotifyConfig = true
export const LineCallbackToEndpointConfig = 'https://wanfah.online/inventory'
export const LineLiffId = '2002602584-R2g131Z9'

// ปรับค่าบำรุง
// maintenance baht
export const maintenanceConfig = 20